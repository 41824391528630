import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
  Alert,
  Row,
  Col,
} from 'react-bootstrap'
import { useSystemAdminContext } from 'providers/SystemAdminProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as yup from 'yup'
import YupPassword from 'yup-password'
import { PasswordField } from 'components/common/FormHelper'
import { Formik, Field, Form } from 'formik'

YupPassword(yup)

const UserPasswordChangeModal = ({ show, hide, hasLabel = true, type = 'new', user }) => {
  const { changeUserPassword } = useSystemAdminContext()

  const defaultData = {
    password: '',
    id: '',
  }

  const handleSubmit = async (values, bag) => {
    await changeUserPassword(values)
    hide()
  }

  const schema = yup.object().shape({
    password: yup.string()
      .required('This is a required field.')
      .password()
      .min(8, 'Passwords must be at least 8 characters long.')
      .minLowercase(1, 'Please use mixed case letteres')
      .minUppercase(1, 'Please use mixed case letteres')
      .minNumbers(1, 'At least 1 number is reqired')
      .minSymbols(1, 'At least 1 symbol is required'),
    confirm_password: yup.string()
      .required('This is a required field.')
      .oneOf([yup.ref('password'), null], 'Passwords must match')
  })

  return (
    <Modal centered show={show} onHide={hide} size={'lg'}>
      <Modal.Header closeButton>
        <Modal.Title>Password Reset</Modal.Title>
      </Modal.Header>

      <Formik
        initialValues={user !== undefined ? user : defaultData}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ isValid, isSubmitting, dirty }) => {
          return (
            <Form>
              <Modal.Body>
                <Row>
                  <Col>
                    <p>Complete the form to reset the password for <strong>{user.email}</strong></p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <PasswordField
                      hasLabel
                      title='Password'
                      name='password'
                    />
                    <PasswordField
                      hasLabel
                      title='Confirm Password'
                      name='confirm_password'
                    />
                  </Col>
                  <Col xs={7}>
                    <Alert variant='info' className='fs-10'>Passwords must be at least 8 characters long, contain mixed-case letters, numbers, special characters and must not contain spaces, or emoji.</Alert>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={hide}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type='submit'
                  disabled={!isValid || !dirty}
                >
                  {isSubmitting
                    ? <FontAwesomeIcon
                      icon='circle-notch'
                      className='me-1 fa-spinner fa-spin'
                    />
                    : ''
                  }
                  {' '}
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default UserPasswordChangeModal

UserPasswordChangeModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  hasLabel: PropTypes.bool,
  type: PropTypes.string,
  user: PropTypes.object,
}
