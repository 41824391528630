import React from 'react'
import PageHeader from 'components/common/PageHeader'
import { Card } from 'react-bootstrap'
// import { getConfluenceSlugContent } from 'helpers/confluenceContent'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

const PrivacyPolicyPage = () => {
  const content = `
  ##### Introduction
Please read these policies carefully before using the Partner Portal ("Product") available at partner-portal.dfs.cx, operated by SKR3L, LLC DBA Roady's Truck Stops, MISS LLC DBA Professional Transportation Partners, LLC (PTP)  (“We”,”Our”).
This Privacy Policy is designed to help you understand how SKR3L, LLC; Miss LLC. collects, uses, and safeguards the information you provide when using our product. By using the product, you agree to the terms of the below Privacy Policy.
This latest draft is effective 1 November 2024.
##### Types of Information Collected
SKR3L, LLC ; MISS, LLC collects three types of information:
* Personal Information: This includes information that can be used to identify you, such as your name, email address, phone number, and company details.
* Usage Information: This includes information about your use of our product, including the services you access and how you interact with it.
* Non-Personal Information: This includes information that cannot be used to identify you personally, such as anonymous usage data, preferences, and aggregated statistics.
##### How Information Is Collected
SKR3L, LLC ; MISS, LLC collects information through:
* Direct Interaction: When you create or verify an account, update your profile, or communicate with our product.
* Automated Collection: Through cookies, log files, and other tracking technologies when you use the product.
##### How Information Is Used
SKR3L, LLC ; MISS, LLC uses your information to:
* Provide Services: We use the personal information we collect to provide you with the services offered through our product, including processing payments for gallons and services.
* Communicate with You: We may use your contact information to communicate with you about your account and the services provided to you on our product.
*  Improve the product: We use usage information to analyze trends and improve the product and services we offer.
##### Data Security
SKR3L, LLC ; MISS, LLC takes reasonable measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.  Here are some examples of how this is done:
By encrypting your data during transmission (utilizing HTTPS).
By hiring 3rd party program auditors to test for vulnerabilities.
##### User Rights
You as the user have the right to:
* Access your personal information.
* Update your information.
* Request the deletion of your information.

You can reach out to SKR3L, LLC ; MISS, LLC to make these changes by contacting us at (888) 540-2909.
##### Sharing of Information
SKR3L, LLC ; MISS, LLC does not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as set forth in this Privacy Policy.

* Service Providers: We may share your personal information with third-party service providers who assist us in providing the services offered through our product.
* Legal Requirements: We may disclose your personal information if required to do so by law or in response to a valid legal request.
##### Children's Privacy
The App is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under the age of 13.
##### Changes to This Privacy Policy
SKR3L, LLC ; MISS, LLC reserves the right to update this Privacy Policy at any time. We will notify you of any changes by posting the new Privacy Policy on partner-portal.dfs.cx.
##### Contacting Us
If you have any questions or concerns regarding this Privacy Policy, you can contact us at (888) 540-2909.
` // getConfluenceSlugContent('privacy-policy')

  return (
    <>
      <PageHeader
        title='Privacy Policy'
        description=""
        className='mb-3'
      />
      <Card>
        <Card.Body>
          <Markdown rehypePlugins={[rehypeRaw]}>
            {content}
          </Markdown>
        </Card.Body>
      </Card>
    </>
  )
}

export default PrivacyPolicyPage
