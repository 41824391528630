export const initialState = {
  users: [
  ],
  subscription: {
    plan: 1,
    plan_total: 25,
    terms: 'm',
    start: '2024-07-30T16:44:35.834Z',
    lastUsed: 'fail',
  },
  payment: {
    card_number: 9876,
    exp_date: '12/26',
    name: 'Aaron Todd',
    type: 'v',
  },
  payment_history: [
    {
      invoice: 'FA613145',
      amount: 350,
      date: 'July 29, 2024',
      status: 'Pending',
      color: 'warning'
    },
    {
      invoice: 'LC014357',
      amount: 350,
      date: 'June 29, 2024',
      status: 'Successful',
      color: 'success'
    },
    {
      invoice: 'FC657916',
      amount: 350,
      date: 'May 29, 2024',
      status: 'Successful',
      color: 'success'
    },
    {
      invoice: 'TN654236',
      amount: 350,
      date: 'April 29, 2024',
      status: 'Successful',
      color: 'success'
    },
    {
      invoice: 'ON820965',
      amount: 350,
      date: 'March 29, 2024',
      status: 'Successful',
      color: 'success'
    },
    {
      invoice: 'TN755429',
      amount: 350,
      date: 'February 29, 2024',
      status: 'Successful',
      color: 'success'
    },
    {
      invoice: 'TN789426',
      amount: 350,
      date: 'January 29, 2024',
      status: 'Successful',
      color: 'success'
    }
  ],
  partnerName: ''
}

export const SystemAdminReducer = (initialState, action) => {
  const { type, payload } = action
  // Leaving for future Debug
  // console.log(type)
  // console.log(payload)
  let updated

  switch (type) {
    case 'INIT_USERS':
      return {
        ...initialState,
        users: payload.users.sort(sortCreatedAtDesc),
      }
    case 'UPDATE_USER':
      // console.log(payload.user)
      updated = initialState.users.map(u => {
        if (u.id === payload.user.id) {
          return payload.user
        }
        return u
      }).sort(sortCreatedAtDesc)

      return {
        ...initialState,
        users: updated,
      }
    case 'DELETE_USER':
      updated = initialState.users.filter(u => {
        if (u.id !== payload.user.id) {
          return true
        }
        return false
      }).sort(sortCreatedAtDesc)

      return {
        ...initialState,
        users: updated,
      }
    case 'ADD_USER':
      if (!Object.prototype.hasOwnProperty.call(payload.user, 'companies')) {
        payload.user = {
          ...payload.user,
          companies: {
            items: []
          }
        }
      }
      if (!Object.prototype.hasOwnProperty.call(payload.user, 'locations')) {
        payload.user = {
          ...payload.user,
          locations: {
            items: []
          }
        }
      }
      return {
        ...initialState,
        users: initialState.users.concat([payload.user]).sort(sortCreatedAtDesc),
      }
    case 'SET_PARTNER_NAME':
      return {
        ...initialState,
        partnerName: payload.partnerName,
      }

    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const sortCreatedAtDesc = (a, b) => {
  if (a.createdAt > b.createdAt) {
    return -1
  }
  if (a.createdAt < b.createdAt) {
    return 1
  }

  // names must be equal
  return 0
}

const filterUnread = n => {
  if (!n.read) {
    return true
  }
  return false
}
