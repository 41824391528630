import React from 'react'
import PropTypes from 'prop-types'
import { Image } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Flex from 'components/common/Flex'
import classNames from 'classnames'
import FalconLightBox from 'components/common/FalconLightBox'
import { disputeMessageDateTimeOptions, disputeMessageTimeOptions } from 'helpers/formater'
import { isToday } from 'date-fns'
import parse from 'html-react-parser'

const getIcon = type => {
  const icon = ['far']
  if (type.includes('image')) {
    icon.push('file-image')
  }
  if (type.includes('video')) {
    icon.push('file-video')
  }
  if (type.includes('audio')) {
    icon.push('file-audio')
  }
  if (type.includes('zip')) {
    icon.push('file-archive')
  }
  if (type.includes('pdf')) {
    icon.push('file-pdf')
  }
  if (
    type.includes('html') ||
    type.includes('css') ||
    type.includes('json') ||
    type.includes('javascript')
  ) {
    icon.push('file-code')
  }
  if (icon.length === 1) {
    icon.push('file')
  }
  return icon
}

const getName = file => {
  const [prefix, trxId, fileName] = file.split('/')
  const [name, extension] = fileName.split('.')
  return {
    name: `${name}.${extension}`,
    extension,
    prefix
  }
}

const FileAttachment = ({ file }) => {
  const nameParts = getName(file)
  return (
    <Flex
      alignItems="center"
      className="border px-2 rounded-3 bg-white dark__bg-1000 my-1 fs-10"
    >
      <FontAwesomeIcon icon={getIcon(nameParts.extension)} />
      <span className="mx-2 flex-1 fs--1">
        {nameParts.name}
      </span>
    </Flex>
  )
}

FileAttachment.propTypes = {
  file: PropTypes.string.isRequired
}

const Message = ({ message, status = '' }) => {
  // const user = users.find(({ id }) => id === senderUserId);
  // const name = user?.name.split(' ')[0];
  const isLeft = message.senderType === 1

  const date = new Intl.DateTimeFormat('en-US', isToday(new Date(message.date)) ? disputeMessageTimeOptions : disputeMessageDateTimeOptions).format(Date.parse(message.date))

  return (
    <Flex className={classNames('p-3', { 'd-block': !isLeft })}>
      {/* {isLeft && <Avatar size="l" className="me-2" src={user.avatarSrc} />} */}
      <div
        className={classNames('flex-1', {
          'd-flex justify-content-end': !isLeft
        })}
      >
        <div
          className={classNames('w-xxl-75', {
            'w-100': !isLeft
          })}
        >
          <div
            className={classNames('fs--2', {
              'text-end': !isLeft
            })}
          >
            {/* {isLeft && ( */}
            <span className="fw-bold me-2">{message.sender}</span>
            {/* )} */}
            <span className="text-400 me-2">{date}</span>
          </div>
          <Flex
            alignItems="center"
            className={classNames('hover-actions-trigger', {
              'flex-end-center': !isLeft,
              'align-items-center': isLeft
            })}
          >
            <>
              <div
                className={classNames('p-2 rounded-2 chat-message', {
                  'bg-200': isLeft,
                  'bg-primary text-white': !isLeft
                })}
              >
                {(message || message.text) && (
                  <p className="mb-0">{parse(message.text ? message.text : message)}</p>
                )}
                {
                  message.files.map((file, i) => {
                    return <FileAttachment
                      file={file}
                      key={i}
                    />
                  })
                }
                {message.attachment && (
                  <FalconLightBox image={message.attachment}>
                    <Image
                      fluid
                      rounded
                      src={message.attachment}
                      width={150}
                      alt=""
                    />
                  </FalconLightBox>
                )}
              </div>
            </>
            {/* )} */}
            {/* {isLeft && <ChatMessageOptions />} */}
          </Flex>
        </div>
      </div>
    </Flex>
  )
}

Message.propTypes = {
  message: PropTypes.object,
  senderUserId: PropTypes.number,
  status: PropTypes.string,
  time: PropTypes.object,
  isGroup: PropTypes.bool
}

export default Message
