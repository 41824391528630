import React, { useEffect, useState, useContext } from 'react'
import PageHeader from 'components/common/PageHeader'
import { useNotificationContext } from 'providers/NotificationProvider'
import { useAppContext } from 'providers/AppProvider';
import { dateTimeOptions } from 'helpers/formater'
import { Form, Button, Nav, Container, Row, Col, Card } from 'react-bootstrap'
import createMarkup from 'helpers/createMarkup'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter'
import useAdvanceTable from 'hooks/useAdvanceTable'
import AdvanceTableProvider from 'providers/AdvanceTableProvider'
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox'
import DatePicker from 'react-datepicker'
import CustomDateInput from 'components/common/CustomDateInput'
import IconButton from 'components/common/IconButton'

const Notifications = () => {
  const {
    config: { isDark }
  } = useAppContext()

  const { notificationState, updateNotificationToRead } = useNotificationContext()
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedUnreadRows, setSelectedUnreadRows] = useState([])
  const [notifications, setNotifications] = useState([])

  const today = new Date()
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1)
  const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0)
  const defaultStartDate = new Date(firstDay)
  const defaultEndDate = new Date(lastDay)
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)

  useEffect(() => {
    if (notificationState.notifications !== null) {
      setNotifications(notificationState.notifications)
    }
  }, [notificationState.notifications])

  const getNotePath = (n) => {
    switch (n.type) {
      case 'retail':
        return `/pricing?nid=${n.id}`

      default:
        return `/notifications/${n.id}`
    }
  }

  const columns = [
    {
      header: 'Title',
      accessorKey: 'title',
      // selector: row => row.title,
      sortable: true,
      cell: (rowData) => {
        const row = rowData.row.original
        return <Link
          className={classNames(
            { 'fw-bold': !row.read },
          )}
          to={getNotePath(row)}
        >
          {row.title}
        </Link>
      }
    },
    {
      header: 'Date/Time',
      accessorKey: 'createdAt',
      // selector: row => row.createdAt,
      cell: (rowData) => {
        const row = rowData.row.original
        // if (row.createdAt === null || row.createdAt === '') {
        //   return '';
        // }
        return new Intl.DateTimeFormat('en-US', dateTimeOptions).format(new Date(row.createdAt))
      },
      sortable: true,
      width: '13rem',
    }
  ]

  const handleSelectedRows = ({ selectedRows }) => {
    setSelectedRows(selectedRows)
    setSelectedUnreadRows(selectedRows.filter(r => {
      if (!r.read) {
        return true
      }
      return false
    }))
  }

  const handleMarkAsRead = e => {
    if (selectedRows !== null) {
      selectedUnreadRows.forEach(notification => {
        updateNotificationToRead(notification.id)
      })
    }
  }
  const [retailPriceRaw, setRetailPriceRaw] = useState(0)
  const [showRetailPriceModal, setShowRetailPriceModal] = useState(false)

  function GetInVisibleColumn (finalColumnDef) {
    const inVisibleColumns = finalColumnDef.filter(
      (col) => col.visible === false
    );

    const removedColumn = {}
    for (const item of inVisibleColumns) {
      removedColumn[item.accessorKey] = false;
    }

    return removedColumn;
  }

  const table = useAdvanceTable({
    data: notifications,
    columns,
    sortable: true,
    pagination: true,
    perPage: 10,
    initialState: {
      columnVisibility: GetInVisibleColumn(columns),
    },
    selection: true,
    selectionColumnWidth: 30
  })

  return (
    <>
      <PageHeader
        title='Notifications'
        description=""
        className='mb-3'
      />

      <AdvanceTableProvider {...table}>
        <Card className="mb-3">
          <Nav className='m-2'>
            <Nav.Item style={{ marginRight: '.4rem' }}>
              <Form.Group controlId="startDate" style={{ display: 'flex' }}>
                <Form.Label style={{ whiteSpace: 'nowrap', marginTop: '.3rem', marginRight: '0.3rem' }}>Start Date</Form.Label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  customInput={
                    <CustomDateInput
                      formControlProps={{
                        placeholder: 'd/m/y',
                      }}
                    />
                  }
                />
              </Form.Group>
            </Nav.Item>
            <Nav.Item style={{ marginRight: '.4rem' }}>
              <Form.Group controlId="endDate" style={{ display: 'flex' }}>
                <Form.Label style={{ whiteSpace: 'nowrap', marginTop: '.3rem', marginRight: '0.3rem' }}>End Date</Form.Label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  customInput={
                    <CustomDateInput
                      formControlProps={{
                        placeholder: 'd/m/y',
                      }}
                    />
                  }
                />
              </Form.Group>
            </Nav.Item>
            <Nav.Item style={{ flexGrow: 1 }}>
              <AdvanceTableSearchBox table />
            </Nav.Item>
            <Nav.Item className='text-start'>
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-3"
                onClick={() => handleMarkAsRead()}
              >
                <span className="d-none d-sm-inline-block ms-1">
                  New Contact
                </span>
              </IconButton>
            </Nav.Item>
          </Nav>
          <Card.Body className="p-0">
            <AdvanceTable
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs-10 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </Card.Footer>
        </Card>
      </AdvanceTableProvider>
    </>
  )
}

export default Notifications
