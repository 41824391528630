import React from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { Button, Form as bFrom } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccountContext } from 'providers/AccountProvider'
import { Formik, Field, Form } from 'formik'
import * as yup from 'yup'
import { TextField } from 'components/common/FormHelper'

const ForgotForm = ({ hasLabel = true, success = null }) => {
  const { forgot } = useAccountContext()

  const defaultData = {
    email: '',
  }

  const handleSubmit = async (values, bag) => {
    // console.log(values)

    try {
      // eslint-disable-next-line
      const data = await forgot(values.email)
      success(values.email)
    } catch (error) {
      toast.error(
        'We\'re having troubles processing your request! Please try again later.',
        {
          position: 'top-left',
          autoClose: false,
          theme: 'colored',
        }
      )
    }
  }

  const schema = yup.object().shape({
    email: yup.string().email('Please provide a valid email address.').required('This is a required field.'),
  })

  return (
    <Formik
      initialValues={defaultData}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ isValid, isSubmitting, dirty }) => {
        return (
          <Form>
            <TextField
              hasLabel
              title='Email Address'
              name='email'
            />

            <bFrom.Group>
              <Button
                type='submit'
                color='primary'
                className='mt-3 w-100'
                disabled={!isValid || !dirty}
              >
                {isSubmitting
                  ? <FontAwesomeIcon
                    icon='circle-notch'
                    className='me-1 fa-spinner fa-spin'
                  />
                  : ''
                }
                {' '}
                Submit
              </Button>
            </bFrom.Group>
          </Form>
        )
      }}
    </Formik>
  )
}

ForgotForm.propTypes = {
  success: PropTypes.func,
  hasLabel: PropTypes.bool
}

export default ForgotForm
