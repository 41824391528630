import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  ListGroup,
  ButtonGroup,
  Button,
} from 'react-bootstrap'
import PageHeader from 'components/common/PageHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import PaymentMethod from './PaymentMethod'
import Subscription from './Subscription'
import PaymentHistory from './PaymentHistory'

const BillingManagement = () => {
  const [activeData, setActiveData] = useState(null)

  return (
    <>
      <PageHeader
        title='Billing'
        description=""
        className='mb-3'
        // icon={['regular', 'key']}
      />

      {/* <Row className="g-3 mb-3">
        <Col xs={7}>
          <Subscription />
        </Col>
        <Col xs={5}>
          <PaymentMethod />
        </Col>
      </Row> */}

      <Row className="g-3 mb-3">
        <Col>
          <PaymentHistory />
        </Col>
      </Row>

    </>
  )
}

export default BillingManagement
