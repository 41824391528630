import gql from 'graphql-tag'

export const onCreateNotification = gql(`
  subscription {
    onCreateNotification {
      id
      type
      message
      read
      title
      ttl
      createdAt
      updatedAt
      userNotificationsId
    }
  }`
)

export const onUpdateNotification = gql(`
  subscription {
    onUpdateNotification {
      id
      type
      message
      read
      title
      ttl
      createdAt
      updatedAt
    }
  }`
)

export const onDeleteNotification = gql(`
  subscription {
    onDeleteNotification {
      id
    }
  }`
)

export const onUpdateUser = gql(`
  subscription {
    onUpdateUser {
      id
      name
      email
      phone_number
      phone_number_opt_in
      updatedAt
      createdAt
    }
  }`
)

export const onCreateUserPref = gql(`
  subscription {
    onCreatePreference {
      value
      createdAt
      updatedAt
      id
      name
    }
  }`
)

export const onUpdateUserPref = gql(`
  subscription {
    onUpdatePreference {
      value
      user
      createdAt
      updatedAt
      id
      name
    }
  }`
)
