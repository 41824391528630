import React, { useContext, useEffect, useState } from 'react'
import PageHeader from 'components/common/PageHeader'
import {
  Button,
  Nav,
  Card,
} from 'react-bootstrap'
import { AppContext } from 'providers/AppProvider'
import { useProfileContext } from 'providers/ProfileProvider'
import { usePartnerContext } from 'providers/PartnerProvider'
import { useAccountContext } from 'providers/AccountProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ContactModal from './ContactModal'
import ContactDeleteModal from './ContactDeleteModal'
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import { minCellFormater } from 'components/common/advance-table/AdvanceTableHelper'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter'
import useAdvanceTable from 'hooks/useAdvanceTable'
import AdvanceTableProvider from 'providers/AdvanceTableProvider'
import IconButton from 'components/common/IconButton'
import parsePhoneNumber from 'libphonenumber-js'
import { useNavigate } from 'react-router-dom'

const ContactsPage = () => {
  const navigate = useNavigate()
  const {
    config: { isDark }
  } = useContext(AppContext)
  const { profileState, updateUser } = useProfileContext()
  const { partnerState } = usePartnerContext()
  const { checkAccess } = useAccountContext()
  const [contacts, setContacts] = useState([])
  const [noDataComponent, setNoDataComponent] = useState(<div className='p-4'>There are no records to display</div>)
  const [showNewContactModal, setShowNewContactModal] = useState(false)
  const [showEditContactModal, setshowEditContactModal] = useState(false)
  const [showDeleteContactModal, setshowDeleteContactModal] = useState(false)
  const [currentContact, setCurrentContact] = useState({})

  useEffect(() => {
    if (!checkAccess(2)) {
      navigate('/')
    }
  }, [])

  useEffect(() => {
    // console.log(profileState)
    if (Object.prototype.hasOwnProperty.call(profileState, 'user_profile')) {
      // setUserProfileAttribs(profileState.user_profile)
    }
  }, [profileState])

  useEffect(() => {
    setContacts(partnerState.partnerContacts)
  }, [partnerState.partnerContacts])

  useEffect(() => {
    if (!showEditContactModal) {
      setCurrentContact({})
    }
  }, [showEditContactModal])

  const handleEdit = row => {
    // console.log(row)
    setCurrentContact(row)
    setshowEditContactModal(true)
  }

  const handleDelete = row => {
    // console.log(row.id)
    setCurrentContact(row)
    setshowDeleteContactModal(true)
  }

  const columns = [
    {
      header: 'First',
      accessorKey: 'first_name',
      cell: minCellFormater,
    },
    {
      header: 'Last',
      accessorKey: 'last_name',
      cell: minCellFormater,
    },
    {
      header: 'Type',
      accessorKey: 'type',
      cell: (rowData) => {
        const { type } = rowData.row.original
        if (type) {
          return <h6 className="mb-0">{type.label}</h6>
        }
        return ''
      }
    },
    {
      header: 'Title',
      accessorKey: 'title',
      cell: minCellFormater,
    },
    {
      header: 'Email',
      accessorKey: 'email',
      cell: minCellFormater,
    },
    {
      header: 'Phone',
      accessorKey: 'phone_number',
      cell: (rowData) => {
        let { phone_number: phoneNumber } = rowData.row.original
        if (phoneNumber) {
          phoneNumber = parsePhoneNumber(phoneNumber ?? '', 'US')
          return <h6 className="mb-0">{phoneNumber.formatNational()}</h6>
        } else {
          return ''
        }
      }
    },
    {
      header: 'Extension',
      accessorKey: 'extension',
      cell: minCellFormater,
    },
    {
      header: 'Mobile Phone',
      accessorKey: 'mobile_number',
      cell: (rowData) => {
        let { mobile_number: mobileNumber } = rowData.row.original
        if (mobileNumber) {
          mobileNumber = parsePhoneNumber(mobileNumber ?? '', 'US')
          return <h6 className="mb-0">{mobileNumber.formatNational()}</h6>
        } else {
          return ''
        }
      }
    },
    {
      header: 'Subscriptions',
      accessorKey: 'subscriptions',
      enableSorting: false,
      cell: (rowData) => {
        const {
          dfs_billing_report_sub: dfsBillingReportSub,
          daily_discount_sub: dailyDiscountSub
        } = rowData.row.original
        const returnable = []
        if (dfsBillingReportSub) {
          returnable.push('DFS Billing Report')
        }

        if (dailyDiscountSub) {
          if (returnable.length > 0) {
            returnable.push(<br />)
          }
          returnable.push('Daily Discount Report')
        }
        return <h6 className="mb-0">{returnable}</h6>
      }
    },
    {
      header: '',
      accessorKey: 'actions',
      enableSorting: false,
      meta: {
        cellProps: {
          className: 'text-end',
          width: '105px',
        }
      },
      cell: (rowData) => {
        const row = rowData.row.original
        return (
          <>
            <Button
              type='button'
              variant='warning'
              className='me-1 px-2'
              size='sm'
              onClick={() => handleEdit(row)}
              title='Edit Contact'
            >
              <FontAwesomeIcon icon='edit' />
            </Button>

            <Button
              id={row.id}
              type='button'
              variant='danger'
              className='me-1 px-2'
              size='sm'
              onClick={() => handleDelete(row)}
              title='Delete Contact'
            >
              <FontAwesomeIcon icon='times-circle' />
            </Button>
          </>
        )
      }
    },
  ]

  const table = useAdvanceTable({
    data: contacts,
    columns,
    sortable: true,
    pagination: true,
    perPage: 10
  })

  return (
    <>
      <ContactModal show={showNewContactModal} hide={() => setShowNewContactModal(false)} />
      <ContactModal type='edit' contact={currentContact} show={showEditContactModal} hide={() => setshowEditContactModal(false)} />
      <ContactDeleteModal contact={currentContact} show={showDeleteContactModal} hide={() => setshowDeleteContactModal(false)} />

      <PageHeader
        title='Contacts'
        description=""
        className='mb-3'
      />

      <AdvanceTableProvider {...table}>
        <Card className="mb-3">
          <Nav className='m-2' fill>

            <Nav.Item className='text-start'>
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-3"
                onClick={() => setShowNewContactModal(true)}
              >
                <span className="d-none d-sm-inline-block ms-1">
                  New Contact
                </span>
              </IconButton>
            </Nav.Item>

            <Nav.Item>
              <AdvanceTableSearchBox table />
            </Nav.Item>

          </Nav>
          <Card.Body className="p-0">
            <AdvanceTable
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs-10 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </Card.Footer>
        </Card>
      </AdvanceTableProvider>
    </>
  )
}

export default ContactsPage
