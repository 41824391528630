import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import Avatar from 'components/common/Avatar'
import { toast } from 'react-toastify'

import { useAccountContext } from 'providers/AccountProvider'

const ProfileDropdown = () => {
  const navigate = useNavigate()
  const { logout, appState } = useAccountContext()
  const [avatarSrc, setAvatarSrc] = useState(null)

  const [isConfigured, setIsConfigured] = useState(false)

  useEffect(() => {
    if (appState.ready && appState.attributes !== null) {
      const baseDomain = 'partner-portal.dfs.cx'
      const subdomain = process.env.REACT_APP_ENV !== 'local' ? `${process.env.REACT_APP_ENV}.` : 'dev.'
      setAvatarSrc(`https://${subdomain}${baseDomain}/avatar/${appState.attributes.sub}`)

      setIsConfigured(appState.ready)
    }
  }, [appState])

  const handleSignout = async () => {
    try {
      await logout()
      navigate('/')
    } catch (error) {
      toast.error(
        error.message,
        {
          autoClose: false,
        }
      )
    }
  }

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={avatarSrc} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as={Link} to='/user/profile'>
            Profile &amp; account
          </Dropdown.Item>
          <Dropdown.Item as='button' onClick={handleSignout}>
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ProfileDropdown
