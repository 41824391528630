import gql from 'graphql-tag'

export const updateNotificationToRead = gql(`
  mutation ($id: ID!) {
    updateNotification(input: {id: $id, read: true}) {
      read
      message
      id
      createdAt
      title
      type
      updatedAt
    }
  }`
)

export const updateUser = gql(`
  mutation ($id: ID!, $name: String, $email: String, $phone_number: String, $permissions: Int) {
    updateUser(input: {id: $id, name: $name, email: $email, phone_number: $phone_number, permissions: $permissions}) {
      id
      phone_number
      name
      updatedAt
      email
      createdAt
      permissions
    }
  }`
)

export const deleteUser = gql(`
  mutation ($id: ID!) {
    deleteUser(input: {id: $id}) {
      id
    }
  }`
)

export const createUser = gql(`
  mutation ($name: String, $email: String, $phone_number: String, $permissions: Int, $partnerId: Int, $partnerName: String) {
    createCompleteUser(input: {name: $name, email: $email, phone_number: $phone_number, permissions: $permissions, partnerId: $partnerId, partnerName: $partnerName}) {
      id
      phone_number
      name
      updatedAt
      email
      createdAt
      permissions
      partnerId
      partnerName
    }
  }`
)

export const updateUserPassword = gql(`
  mutation ($id: ID!, $password: String!) {
    updateUserPassword(input: {id: $id, password: $password}) {
      id
    }
  }`
)

export const createPref = gql(`
  mutation ($name: String, $value: String, $user: String!, $userPreferencesId: ID) {
    createPreference(input: {name: $name, value: $value, user: $user, userPreferencesId: $userPreferencesId}) {
      id
      name
      value
      createdAt
      updatedAt
    }
  }`
)

export const updatePref = gql(`
  mutation ($id: ID!, $value: String!) {
    updatePreference(input: {id: $id, value: $value}) {
      id
      name
      value
      createdAt
      updatedAt
    }
  }`
)
