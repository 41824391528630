import Divider from 'components/common/Divider'
import PropTypes from 'prop-types'
import React from 'react'
import {
  Button,
  Col,
  Form as bForm,
  Row
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import SocialAuthButtons from './SocialAuthButtons'
import paths from 'routes/paths'
import { useCookies } from 'react-cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccountContext } from 'providers/AccountProvider'
import { Formik, Field, Form } from 'formik'
import * as yup from 'yup'
import YupPassword from 'yup-password'
import { PasswordField, TextField, ToggleField } from 'components/common/FormHelper'

YupPassword(yup)

const LoginForm = ({ hasLabel = false, layout = 'simple', chgPass }) => {
  const { authenticate } = useAccountContext()
  const [cookies, setCookie] = useCookies(['username'])

  const defaultData = {
    username: cookies.username ?? '',
    password: '',
    remember:
      !!(Object.getOwnPropertyDescriptor(cookies, 'username') &&
        cookies.username !== '')
  }

  const handleSubmit = async (values, bag) => {
    // console.log(values)

    if (values.remember) {
      setCookie('username', values.username, { path: '/' })
    } else {
      setCookie('username', '')
    }

    try {
      const user = await authenticate(values.username, values.password)
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        chgPass(user)
      }
    } catch (error) {
      toast.error(
        error.message,
        {
          position: 'top-left',
          autoClose: false,
          theme: 'colored',
        }
      )
    }
  }

  const schema = yup.object().shape({
    username: yup.string().email('Please provide a valid email address.').required('This is a required field.'),
    password: yup.string()
      .required('This is a required field.')
      .password()
      .min(8, 'Passwords must be at least 8 characters long.')
      .minLowercase(1, 'Please use mixed case letteres')
      .minUppercase(1, 'Please use mixed case letteres')
      .minNumbers(1, 'At least 1 number is reqired')
      .minSymbols(1, 'At least 1 symbol is required'),
  })

  return (
    <Formik
      initialValues={defaultData}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ isValid, isSubmitting, dirty }) => {
        return (
          <Form>
            <TextField
              hasLabel
              title='Email Address'
              name='username'
            />
            <PasswordField
              hasLabel
              title='Password'
              name='password'
            />

            <Row className="justify-content-between align-items-center">
              <Col xs="auto">
                <ToggleField
                  hasLabel
                  title='Remember Me'
                  name='remember' />
              </Col>
              <Col xs="auto">
                <Link className="fs-10 mb-0" to='/forgot'>
                  Forgot Password?
                </Link>
              </Col>
            </Row>

            <bForm.Group>
              <Button
                type="submit"
                color="primary"
                className="mt-3 w-100"
                disabled={!isValid || !dirty}
              >
                {
                  isSubmitting
                    ? <FontAwesomeIcon
                      icon='circle-notch'
                      className='me-1 fa-spinner fa-spin'
                    />
                    :
                    ''
                }
                Log in
              </Button>
            </bForm.Group>

            <Divider className="mt-4">or log in with</Divider>

            <SocialAuthButtons />

          </Form>
        )
      }}
    </Formik>
  )
}

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool,
  chgPass: PropTypes.func,
}

export default LoginForm
