import axios from 'axios'
import { Auth } from 'aws-amplify'

axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.baseURL = 'https://' + process.env.REACT_APP_API_BASE + '/'

axios.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((session) => {
        const idTokenExpire = session.getIdToken().getExpiration()
        const refreshToken = session.getRefreshToken()
        const currentTimeSeconds = Math.round(+new Date() / 1000)
        /**
         * Leaving for possible future DEBUG needs
         * console.log(idTokenExpire < currentTimeSeconds ? 'EXPIRED' : `SESSION OK (TIME LEFT: ${idTokenExpire - currentTimeSeconds} seconds)`)
         */

        if (idTokenExpire < currentTimeSeconds) {
          Auth.currentAuthenticatedUser()
            .then((res) => {
              res.refreshSession(refreshToken, (err, data) => {
                if (err) {
                  Auth.signOut()
                } else {
                  config.headers.Authorization = data.getIdToken().getJwtToken()
                  resolve(config)
                }
              })
            })
        } else {
          config.headers.Authorization = session.getIdToken().getJwtToken()
          resolve(config)
        }
      })
      .catch(() => {
        // No logged-in user: don't set auth header
        resolve(config)
      })
  })
})

export const getDataFromAPI = async (path, headers = {}) => {
  const response = await axios
    .get(path, {
      headers: headers
    })
    .then(response => {
      return response
    })
    .catch(function (error) {
      return error.response
    })

  if (
    response.status === 200 && response.data !== null &&
    // !response.data.hasOwnProperty('errorMessage')
    !Object.getOwnPropertyDescriptor(response.data, 'errorMessage')
  ) {
    return {
      status: response.status,
      data: response.data,
    }
  }
}

export const postDataToAPI = async (path, data, headers = {}) => {
  const response = await axios
    .post(path, data, {
      headers: headers
    })
    .then(response => {
      return response
    })
    .catch(function (error) {
      return error.response
    })

  if (
    response.status === 200 && response.data !== null &&
    !Object.getOwnPropertyDescriptor(response.data, 'errorMessage')
  ) {
    return {
      status: response.status,
      data: response.data,
    }
  } else {
    console.log(response)
  }
}

export const patchDataToAPI = async (path, data) => {
  const response = await axios
    .patch(path, data)
    .then(response => {
      return response
    })
    .catch(function (error) {
      return error.response
    })

  if (
    response.status === 200 && response.data !== null &&
    !Object.getOwnPropertyDescriptor(response.data, 'errorMessage')
  ) {
    return {
      status: response.status,
      data: response.data,
    }
  } else {
    console.log(response)
  }
}

export const deleteDataFromAPI = async (path) => {
  const response = await axios
    .delete(path)
    .then(response => {
      return response
    })
    .catch(function (error) {
      return error.response
    })

  if (
    response.status === 200 && response.data !== null &&
    !Object.getOwnPropertyDescriptor(response.data, 'errorMessage')
  ) {
    return {
      status: response.status,
      data: response.data,
    }
  } else {
    console.log(response)
  }
}
