import React, { useState, useEffect, useContext } from 'react'
import { usePartnerContext } from 'providers/PartnerProvider'
import { AppContext } from 'providers/AppProvider'
import { toast } from 'react-toastify'
import PageHeader from 'components/common/PageHeader'
import DatePicker from 'react-datepicker'
import {
  Button,
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Modal,
  Form,
  Row,
  Col,
  Card,
} from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { dateTimeOptions, currencyOptions, dateFormatOptions } from 'helpers/formater'
import { isIterableArray, getDayDiff } from 'helpers/utils'
// import EditRedemption from './EditRedeem'
import ViewReservation from './ViewReservation'
// import EditDispute from './EditDispute'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccountContext } from 'providers/AccountProvider'
import { permissions } from 'config'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import { minCellFormater } from 'components/common/advance-table/AdvanceTableHelper'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter'
import useAdvanceTable from 'hooks/useAdvanceTable'
import AdvanceTableProvider from 'providers/AdvanceTableProvider'
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox'
import IconButton from 'components/common/IconButton'
import CustomDateInput from 'components/common/CustomDateInput'
import { useForm } from 'react-hook-form'

const DFSRedemptionsPage = () => {
  const {
    config: { isDark }
  } = useContext(AppContext)
  const { checkAccess } = useAccountContext()
  const { partnerState, getReservations } = usePartnerContext()
  const [activeData, setActiveData] = useState(null)
  const [ready, setReady] = useState(false)
  const [reportData, setReportData] = useState([])
  const today = new Date()
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1)
  const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0)
  const defaultStartDate = new Date(firstDay)
  const defaultEndDate = new Date(lastDay)
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const [viewRes, setViewResRow] = useState({})
  const [redemptionEditDisputeRow, setRedemptionEditDistputeRow] = useState({})
  const [showViewResModal, setShowViewResModal] = useState(false)

  const [bLoading, setLoading] = useState(false)

  const columns = [
    // {
    //   header: '',
    //   accessorKey: 'dispute',
    //   enableSorting: false,
    //   cell: (rowData) => {
    //     const row = rowData.row.original
    //     if (row.dispute_status === 0) {
    //       return (<FontAwesomeIcon icon='siren' />)
    //     }
    //     return ('')
    //   },
    //   exportable: false,
    //   meta: {
    //     cellProps: {
    //       width: '50px',
    //     }
    //   },
    // },
    {
      header: 'Code',
      accessorKey: 'code',
      sortable: true,
      cell: minCellFormater,
    },
    // {
    //   header: 'Order ID',
    //   accessorKey: 'external_order_id',
    //   sortable: true,
    //   cell: minCellFormater,
    // },
    {
      header: 'Driver ID',
      accessorKey: 'external_driver_id',
      sortable: true,
      cell: minCellFormater,
    },
    {
      header: 'Location Name',
      accessorKey: 'location_name',
      sortable: true,
      cell: minCellFormater,
    },
    {
      header: 'Pump',
      accessorKey: 'pump',
      sortable: true,
      cell: minCellFormater,
      width: '5rem',
      center: true,
      meta: {
        cellProps: {
          className: 'text-end'
        }
      },
    },
    {
      header: 'Gallon Limit',
      accessorKey: 'gallon_limit',
      sortable: true,
      cell: minCellFormater,
      width: '5rem',
      center: true,
      meta: {
        cellProps: {
          className: 'text-end'
        }
      },
    },
    {
      header: 'Reserved On',
      accessorKey: 'reserved_on',
      sortable: true,
      cell: (rowData) => {
        const row = rowData.row.original
        return <h6 className="mb-0">{new Intl.DateTimeFormat('en-US', dateTimeOptions).format(Date.parse(row.reserved_on))}</h6>
      },
    },
    {
      header: 'Retail',
      accessorKey: 'retail_price',
      sortable: true,
      width: '5rem',
      cell: (rowData) => {
        const row = rowData.row.original
        return <h6 className="mb-0">{new Intl.NumberFormat('en-US', currencyOptions).format(row.retail_price)}</h6>
      },
      meta: {
        cellProps: {
          className: 'text-end'
        }
      },
    },
    {
      header: 'DFS Price',
      accessorKey: 'base_price',
      sortable: true,
      width: '7rem',
      cell: (rowData) => {
        const row = rowData.row.original
        return <h6 className="mb-0">{new Intl.NumberFormat('en-US', currencyOptions).format(row.base_price)}</h6>
      },
      meta: {
        cellProps: {
          className: 'text-end'
        }
      },
    },
    {
      header: '',
      accessorKey: 'reservation_id',
      exportable: false,
      enableSorting: false,
      // width: '3rem',
      // cellProps: {
      //   style: {
      //     textAlign: 'right'
      //   }
      // },
      cell: (rowData) => {
        const row = rowData.row.original
        return (
          <>
            <Button
              variant='primary'
              type='button'
              size="sm"
              className='me-1 px-2'
              onClick={() => setViewResRow(row)}
              title='View Data'
            >
              <FontAwesomeIcon icon='eye' />
            </Button>
          </>
        )
      },
      meta: {
        cellProps: {
          className: 'text-end',
          width: '105px',
        }
      },
    },
  ]

  const table = useAdvanceTable({
    data: reportData,
    columns,
    sortable: true,
    pagination: true,
    perPage: 10,
  })

  useEffect(() => {
    if (partnerState.dfsReservationData !== null) {
      setReportData(partnerState.dfsReservationData)
      setReady(true)
    }
  }, [partnerState.dfsReservationData])

  // useEffect(() => {
  //   if (editRedemption !== null
  //     && Object.prototype.hasOwnProperty.call(editRedemption, 'reservation_id')
  //     && Object.prototype.hasOwnProperty.call(editRedemption, 'gallons')
  //     && Object.prototype.hasOwnProperty.call(editRedemption, 'pump')
  //   ) {
  //     setShowEditRedeemModal(true);
  //   } else {
  //     setShowEditRedeemModal(false);
  //   }
  // }, [editRedemption])

  useEffect(() => {
    // console.log(viewRes)
    if (viewRes !== null
      && Object.prototype.hasOwnProperty.call(viewRes, 'reservation_id')
    ) {
      setShowViewResModal(true)
    } else {
      setShowViewResModal(false)
    }
  }, [viewRes])

  useEffect(() => {
    if (!showViewResModal) {
      setViewResRow({})
    }
  }, [showViewResModal])

  useEffect(() => {
    setLoading(false)
  }, [reportData])

  useEffect(() => {
    if (ready) {
      // setLoading(true)
      getReservations(startDate, endDate)
    }
  }, [startDate, endDate])

  /**
   * Converts an array of objects to CSV
   */
  function convertArrayOfObjectsToCSV (array, headers = [], columnDelimiter = ',', lineDelimiter = '\n') {
    let result
    const keys = Object.keys(array[0])

    result = ''

    if (isIterableArray(headers)) {
      result += headers.join(columnDelimiter)
    } else {
      result += keys.join(columnDelimiter)
    }

    result += lineDelimiter

    array.forEach(item => {
      let ctr = 0
      keys.forEach(key => {
        if (ctr > 0) {
          result += columnDelimiter
        }
        result += item[key]
        ctr++
      })
      result += lineDelimiter
    })
    return result
  }

  function exportCSV () {
    if (reportData && isIterableArray(reportData)) {
      const link = document.createElement('a')

      const exportableData = reportData.map(row =>
        columns.reduce((acc, cur) => {
          if (cur.exportable !== false) {
            acc[cur.accessorKey] = row[cur.accessorKey]
          }
          return acc
        }, {})
      )

      const headerNames = columns.flatMap((c) => {
        if (c.exportable !== false) {
          return c.header
        }
        return []
      })

      // Generate our csv.
      const csv = convertArrayOfObjectsToCSV(exportableData, headerNames)

      if (csv) {
        const filename = 'redemptions_export.csv'

        // Encode the entire CSV content
        const encodedCSV = encodeURIComponent(csv)

        // Create a data URI with the encoded CSV
        const dataURI = `data:text/csv;charset=utf-8,${encodedCSV}`
        link.setAttribute('href', dataURI)
        link.setAttribute('download', filename)
        link.click()
      } else {
        toast.error(
          'There was an error generating your report.  Please contact support for further assistance.',
          {
            autoClose: false,
          }
        )
      }
    } else {
      toast.error(
        'There is no data in this report, try modifying your report parameters.',
        {
          autoClose: false,
        }
      )
    }
  }

  const { register, handleSubmit, setValue, reset } = useForm({
    startDate,
    endDate
  })

  return (
    <>
      <ViewReservation show={showViewResModal} hide={() => setShowViewResModal(false)} redemption={viewRes} />

      <PageHeader
        title='DFS Reservations'
        description=""
        className='mb-3'
      />

      <AdvanceTableProvider {...table}>
        <Card className="mb-3">
          <Nav className='m-2'>
            <Nav.Item style={{ marginRight: '.4rem' }}>
              <Form.Group controlId="startDate" style={{ display: 'flex' }}>
                <Form.Label style={{ whiteSpace: 'nowrap', marginTop: '.5rem', marginRight: '0.3rem' }}>Start Date</Form.Label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date)
                    setValue('startDate', new Intl.DateTimeFormat('en-US', dateFormatOptions).format(date))
                  }}
                  customInput={
                    <CustomDateInput
                      formControlProps={{
                        ...register('startDate'),
                        style: { fontSize: '.71rem' }
                      }}
                    />
                  }
                />
              </Form.Group>
            </Nav.Item>
            <Nav.Item style={{ marginRight: '.4rem' }}>
              <Form.Group controlId="endDate" style={{ display: 'flex' }}>
                <Form.Label style={{ whiteSpace: 'nowrap', marginTop: '.5rem', marginRight: '0.3rem' }}>End Date</Form.Label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date)
                    setValue('endDate', new Intl.DateTimeFormat('en-US', dateFormatOptions).format(date))
                  }}
                  customInput={
                    <CustomDateInput
                      formControlProps={{
                        ...register('endDate'),
                        style: { fontSize: '.71rem' }
                      }}
                    />
                  }
                />
              </Form.Group>
            </Nav.Item>
            <Nav.Item style={{ flexGrow: 1, marginRight: '.4rem' }}>
              <AdvanceTableSearchBox table />
            </Nav.Item>
            {/* <Nav.Item>
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-3"
                onClick={exportCSV}
              >
                <span className="d-none d-sm-inline-block ms-1">Export</span>
              </IconButton>
            </Nav.Item> */}
          </Nav>
          <Card.Body className="p-0">
            <AdvanceTable
              headerClassName="bg-200 text-nowrap align-middle"
              // rowClassName={row =>
              //   `align-middle white-space-nowrap ${row.original.dispute_status === 0
              //     ? 'bg-dispute'
              //     : ''
              //   }`
              // }
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs-10 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </Card.Footer>
        </Card>
      </AdvanceTableProvider>
    </>
  )
}

export default DFSRedemptionsPage
