import React, { useEffect } from 'react'
import PageHeader from 'components/common/PageHeader'
import { RedocStandalone } from 'redoc'
import JSON from './out.json'
import { useNavigate } from 'react-router-dom'
import { useAccountContext } from 'providers/AccountProvider'

const ApiDocs = () => {
  const navigate = useNavigate()
  const { checkAccess } = useAccountContext()

  useEffect(() => {
    if (!checkAccess(4)) {
      navigate('/')
    }
  }, [])

  return (
    <>
      <PageHeader
        title='API Documentation'
        description=""
        className='mb-3'
        icon={['regular', 'book']}
      />

      <div style={{ backgroundColor: 'white' }}>
        <RedocStandalone
          spec={JSON}
          options={{
            hideLoading: true,
            hideHostname: true,
            hideDownloadButton: true,
            disableSearch: true,
            pathInMiddlePanel: false,
            hideSchemaTitles: true,
            // hideSecuritySection: true,
            expandResponses: '200',
            theme: {
              sidebar: {
                width: '0px',
              },
              rightPanel: {
                // width: '30%'
              }
              // fab: {
              //   backgroundColor: '#000000',
              //   color: '#ffffff'
              // }
              //   colors: {
              //     primary: {
              //       main: '#dd5522'
              //     }
              //   },
              //   textColor: '#ffffff',
              //   menu: {
              //     backgroundColor: '#ffffff',
              //   },
            },
          }}
        />
      </div>
      {/* <SwaggerUI
        spec={JSON}
        docExpansion='full'
      /> */}

      {/* <RedocStandalone specUrl='https://redocly.github.io/redoc/openapi.yaml' /> */}

    </>
  )
}

export default ApiDocs
