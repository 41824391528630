import FalconCardHeader from 'components/common/FalconCardHeader'
import TooltipBadge from 'components/common/TooltipBadge'
import React, { useState } from 'react'
import { Card, Form } from 'react-bootstrap'
import { themeVariants } from 'config'
import { useAppContext } from 'providers/AppProvider'
import { capitalize } from 'helpers/utils'

const AccountSettings = () => {
  const {
    config: { theme },
    changeTheme
  } = useAppContext()

  const handleThemeChange = e => {
    changeTheme(e.target.value)
  }

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Account Settings" />
      <Card.Body className="bg-body-tertiary">

        <div>
          <h6 className="fw-bold">
            Appearance
            {/* <TooltipBadge
              tooltip="Only The group of selected people can tag your"
              icon="question-circle"
            /> */}
          </h6>
          <div className="ps-2">
            {themeVariants.map(colorMode => (
              <Form.Check
                key={colorMode}
                type="radio"
                id="tag-everyone"
                label={capitalize(colorMode)}
                className="form-label-nogutter"
                value={colorMode}
                name="tagSettings"
                onChange={handleThemeChange}
                checked={theme === colorMode}
              />
            ))}

            {/* <Form.Check
              type="radio"
              id="tag-everyone"
              label="Light"
              className="form-label-nogutter"
              value="everyone"
              name="tagSettings"
              onChange={handleThemeChange}
              checked={formData.tagSettings === 'everyone'}
            />
            <Form.Check
              type="radio"
              id="tag-members"
              label="Dark"
              className="form-label-nogutter"
              value="group-members"
              name="tagSettings"
              onChange={handleThemeChange}
              checked={formData.tagSettings === 'group-members'}
            />
            <Form.Check
              type="radio"
              id="tag-members"
              label="Auto"
              className="form-label-nogutter"
              value="group-members"
              name="tagSettings"
              onChange={handleThemeChange}
              checked={formData.tagSettings === 'group-members'}
            /> */}
          </div>
        </div>

      </Card.Body>
    </Card>
  )
}

export default AccountSettings
