import React, { createContext, useContext, useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { initialState, ProfileReducer } from 'reducers/profileReducer'
import { useAccountContext } from 'providers/AccountProvider'
import { useNotificationContext } from 'providers/NotificationProvider'
import { useSystemAdminContext, } from 'providers/SystemAdminProvider'
import { usePartnerContext } from 'providers/PartnerProvider'
import { Amplify, API, graphqlOperation, Analytics } from 'aws-amplify'
import awsconfig from 'aws-exports'

import * as queries from 'graphql/queries'
import * as subscriptions from 'graphql/subscriptions'
import * as mutations from 'graphql/mutations'
Amplify.configure(awsconfig)

export const ProfileContext = createContext()

const ProfileProvider = ({ children }) => {
  const { appState } = useAccountContext()
  const { initNotifications } = useNotificationContext()
  const { initData } = usePartnerContext()
  const { initUsers, setPartnerName } = useSystemAdminContext()

  const [profileState, dispatch] = useReducer(ProfileReducer, initialState)

  const locationRadius = 200
  const [readyState, setReadyState] = useState(false)
  const [cardNumber, setCardNumber] = useState(null)
  const [token, setToken] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [cardDetails, setCardDetails] = useState(null)
  // const [cardHolder, setCardHolder] = useState(null)
  const [userGeoLocation, setUserGeoLocation] = useState(null)
  // const [cardHistory, setCardHistory] = useState(null)
  // const [cardBalance, setCardBalance] = useState(null)
  const [closestRoadysLocation, setClosestRoadysLocation] = useState(null)
  const [lastUpdate, setLastUpdate] = useState(0)
  const [user, setUser] = useState(null)

  useEffect(() => {
    // console.log(appState)
    // console.log('appState.ready: ' + appState.ready)
    setReadyState(appState.ready)
    // setCardNumber(appState.card_number)
    setToken(appState.token)
    setPhoneNumber(appState.phone_number)
  }, [appState.ready])

  useEffect(() => {
    if (
      appState.ready
      && appState.authenticated
      // && appState.attributes !== null
      // && Object.prototype.hasOwnProperty.call(appState.attributes, 'custom:companyid')
    ) {
      // setReadyState(appState.ready)
      setUser(appState.user)
    }
  }, [appState.ready, appState.user])

  useEffect(() => {
    // console.log('readyState: ' + readyState)
    if (readyState === true) {
      getUser()
      // getCard()
      // getPaymentMethods()
      // getHolder()
      // getBalance()
      // getHistory()
      // getUserGeo()
      // dispatch({
      //   type: 'UPDATE_PROFILE',
      //   payload: JSON.parse(localStorage.getItem('profile_data')),
      // })
      // setLastUpdate(localStorage.getItem('last_profile_data_update'))
      connectSubs()
      // getUserPaymentMethods()
    }
  }, [readyState])

  useEffect(() => {
    if (profileState !== initialState) {
      localStorage.setItem('profile_data', JSON.stringify(profileState))
      localStorage.setItem('last_profile_data_update', Math.floor(Date.now() / 1000))
    }
  }, [profileState])

  useEffect(() => {
    if (
      readyState === true &&
      cardNumber !== null &&
      token !== null &&
      phoneNumber !== null
    ) {
      // getCard()
      // getHolder()
    }
  }, [readyState, cardNumber, token, phoneNumber])

  const getUser = async () => {
    const input = {
      id: user
    }
    // console.log(input)
    const data = await API.graphql(graphqlOperation(queries.getUser, input))
    // console.log(data)
    if (data.data.getUser !== null) {
      const userData = data.data.getUser
      const notifications = userData.notifications.items
      delete userData.notifications
      const users = userData.users.items
      delete userData.users
      const partnerData = userData.partnerData
      delete userData.partnerData

      dispatch({
        type: 'UPDATE_USER_PROFILE',
        payload: {
          user_profile: userData,
        },
      })

      // console.log(notifications)
      initNotifications(notifications)
      initUsers(users)
      initData({
        id: userData.partnerId,
        name: userData.partnerName
      })
      setPartnerName(userData.partnerName)
      // console.log(dfsData)
      // dispatch1({
      //   type: 'INIT_NOTIFICATIONS',
      //   payload: {
      //     notifications: notifications,
      //   },
      // })
    }
  }

  const updateUser = async (data) => {
    const input = {
      ...data,
      id: profileState.user_profile.id
    }
    // console.log(input)
    const user = await API.graphql(graphqlOperation(mutations.updateUser, input))
    // console.log(user.data.updateUser)
    if (user.data.updateUser !== null) {
      dispatch({
        type: 'UPDATE_USER_PROFILE',
        payload: {
          user_profile: user.data.updateUser,
        },
      })
    }
  }

  async function connectSubs () {
    API.graphql(
      graphqlOperation(subscriptions.onUpdateUser)
    ).subscribe({
      next: ({ provider, value }) => {
        if (value !== undefined && value !== null) {
          dispatch({
            type: 'UPDATE_USER_PROFILE',
            payload: {
              user_profile: value.data.onUpdateUser,
            },
          })
        }
      },
      error: (error) => console.warn(error)
    })
  }

  const sharedState = {
    profileState,
    updateUser,
  }

  return (
    <ProfileContext.Provider value={sharedState}>
      {children}
    </ProfileContext.Provider>
  )
}

ProfileProvider.propTypes = { children: PropTypes.node }

export const useProfileContext = () => useContext(ProfileContext)

export default ProfileProvider
