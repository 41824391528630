import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  ListGroup,
} from 'react-bootstrap'
import PageHeader from 'components/common/PageHeader'
import TokenModal from './TokenModal'
import TokenDeleteModal from './TokenDeleteModal'
import { usePartnerContext } from 'providers/PartnerProvider'
import IconButton from 'components/common/IconButton'
import { dateTimeOptions } from 'helpers/formater'
import { useNavigate } from 'react-router-dom'
import { useAccountContext } from 'providers/AccountProvider'

const ApiKeys = () => {
  const navigate = useNavigate()
  const { checkAccess } = useAccountContext()
  const { partnerState } = usePartnerContext()
  const [tokens, setTokens] = useState([])

  useEffect(() => {
    // console.log(partnerState.tokens)
    setTokens(partnerState.tokens)
  }, [partnerState.tokens])

  const [activeData, setActiveData] = useState(null)
  const [showNewTokenModal, setShowNewTokenModal] = useState(false)
  const [showDeleteTokenModal, setShowDeleteTokenModal] = useState(false)
  const [currentToken, setCurrentToken] = useState({})

  useEffect(() => {
    if (!checkAccess(4)) {
      navigate('/')
    }
  }, [])

  const handleDelete = row => {
    // console.log(row.id)
    setCurrentToken(row)
    setShowDeleteTokenModal(true)
  }

  return (
    <>
      <TokenModal show={showNewTokenModal} hide={() => setShowNewTokenModal(false)} />
      <TokenDeleteModal token={currentToken} show={showDeleteTokenModal} hide={() => setShowDeleteTokenModal(false)} />

      <PageHeader
        title='API Keys'
        description=""
        className='mb-3'
        icon={['regular', 'key']}
      />

      <Row className="mb-3">
        <Col xs="auto" sm={6}>
          <IconButton
            variant="falcon-default"
            size="sm"
            icon="plus"
            transform="shrink-3"
            onClick={() => setShowNewTokenModal(true)}
          >
            <span className="d-none d-sm-inline-block ms-1">
              New Token
            </span>
          </IconButton>
        </Col>
      </Row>

      <ListGroup>
        {
          tokens.map((item, i) => (
            <ListGroup.Item key={i}>
              <Row>
                <Col>
                  <h6 className="mb-0">{item.name}</h6>
                </Col>
                <Col className='d-flex justify-content-end fs-11'>
                  {
                    item.last_access_date
                      ? `Last used on ${new Intl.DateTimeFormat('en-US', dateTimeOptions).format(Date.parse(item.last_access_date))}`
                      : 'Never Used'
                  }
                </Col>
                <Col md='2' className='d-flex justify-content-end'>
                  <IconButton
                    variant="falcon-danger"
                    size="sm"
                    icon="minus"
                    transform="shrink-3"
                    onClick={() => handleDelete(item)}
                  >
                    <span className="d-none d-sm-inline-block ms-1">
                      Delete
                    </span>
                  </IconButton>
                </Col>
              </Row>
              <Row>
                <Col className='fs-11 p-0 ps-4'>
                  {
                    item.expiration_date
                      ? `Expires on ${new Intl.DateTimeFormat('en-US', dateTimeOptions).format(Date.parse(item.expiration_date))}`
                      : ''
                  }
                </Col>
              </Row>
            </ListGroup.Item>
          ))
        }
      </ListGroup>

    </>
  )
}

export default ApiKeys
