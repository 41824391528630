import gql from 'graphql-tag'

export const listUnreadNotifications = gql(`
  {
    listNotifications(filter: {read: {eq: false}}) {
      items {
        createdAt
        type
        id
        message
        read
        title
        updatedAt
      }
    }
  }`
)

export const listAllNotifications = gql(`
  {
    listNotifications {
      items {
        createdAt
        type
        id
        message
        read
        title
        updatedAt
      }
    }
  }`
)

export const getUser = gql(`
  query ($id: ID!) {
    getUser(id: $id) {
        id
        phone_number
        name
        email
        updatedAt
        createdAt
        permissions
        partnerId
        partnerName
        notifications {
          items {
            id
            title
            message
            read
            updatedAt
            createdAt
            type
            userNotificationsId
            ttl
          }
        }
        users {
          items {
            id
            phone_number
            name
            email
            updatedAt
            createdAt
            permissions
          }
        }
    }
  }`
)
export const getDFSGallonsPerDayData = gql(`
  query ($id: ID!, $start: AWSDate, $end: AWSDate) {
    getUser(id: $id) {
      id
      partnerDFSGallonsPerDayData(input: {start: $start, end: $end}) {
        date
        gallons
        redemptions
      }
    }
  }`
)
