import React from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  Tooltip,
  OverlayTrigger
} from 'react-bootstrap'
import classNames from 'classnames'
import Background from 'components/common/Background'
import SubtleBadge from 'components/common/SubtleBadge'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CountUp from 'react-countup'
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png'
import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png'
import bg3 from 'assets/img/icons/spot-illustrations/corner-3.png'

const StatisticsCard = ({
  badgeText,
  badgeBg,
  valueClassName,
  title,
  value,
  className,
  rightImage,
  tipText
}) => {
  let image = bg1
  switch (rightImage) {
    case 'bg1':
      image = bg1
      break
    case 'bg2':
      image = bg2
      break
    case 'bg3':
      image = bg3
      break
  }
  return (
    <Card className={classNames(className, 'overflow-hidden')}>
      <Background image={image} className="bg-card" />
      <Card.Body className="position-relative">
        <h6>
          {title}
          {badgeText && (
            <SubtleBadge bg={badgeBg} pill className="ms-2" tip={tipText}>
              {/* <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip>test </Tooltip>
                }
              > */}
              <FontAwesomeIcon icon="caret-up" /> {badgeText}
              {/* </OverlayTrigger> */}
            </SubtleBadge>
          )}
        </h6>
        <div
          className={classNames(
            valueClassName,
            'display-4 fs-5 mb-2 fw-normal font-sans-serif'
          )}
        >
          <CountUp
            start={0}
            end={value}
            duration={2.75}
          />
        </div>
        {/* <Link to={link} className="fw-semibold fs-10 text-nowrap">
          {linkText}
          <FontAwesomeIcon
            icon="angle-right"
            className="ms-1"
            transform="down-1"
          />
        </Link> */}
      </Card.Body>
    </Card>
  )
}

StatisticsCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  valueClassName: PropTypes.string,
  badgeText: PropTypes.string,
  badgeBg: PropTypes.string,
  rightImage: PropTypes.string,
  className: PropTypes.string,
  tipText: PropTypes.string
}

export default StatisticsCard
