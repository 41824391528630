import axios from 'axios'
import { Auth } from 'aws-amplify'

const instance = axios.create();

// instance.defaults.headers.common['Content-Type'] = 'application/json'
// instance.defaults.baseURL = 'https://partner-portal.dfs.cx/uploads'

// instance.interceptors.request.use(function (config) {
//   return new Promise((resolve, reject) => {
//     Auth.currentSession()
//       .then((session) => {
//         const idTokenExpire = session.getAccessToken().getExpiration()
//         const refreshToken = session.getRefreshToken()
//         const currentTimeSeconds = Math.round(+new Date() / 1000)
//         /**
//          * Leaving for possible future DEBUG needs
//          * console.log(idTokenExpire < currentTimeSeconds ? 'EXPIRED' : `SESSION OK (TIME LEFT: ${idTokenExpire - currentTimeSeconds} seconds)`)
//          */

//         if (idTokenExpire < currentTimeSeconds) {
//           Auth.currentAuthenticatedUser()
//             .then((res) => {
//               res.refreshSession(refreshToken, (err, data) => {
//                 if (err) {
//                   Auth.signOut()
//                 } else {
//                   config.headers.Authorization = data.getAccessToken().getJwtToken()
//                   resolve(config)
//                 }
//               })
//             })
//         } else {
//           config.headers.Authorization = session.getAccessToken().getJwtToken()
//           resolve(config)
//         }
//       })
//       .catch(() => {
//         // No logged-in user: don't set auth header
//         resolve(config)
//       })
//   })
// })

export const putDataToAPI = async (path, data, headers = {}) => {
  const response = await instance
    .put(path, data, {
      headers: headers
    })
    .then(response => {
      return response
    })
    .catch(function (error) {
      return error.response
    })

  if (
    response.status === 200 && response.data !== null &&
    !Object.getOwnPropertyDescriptor(response.data, 'errorMessage')
  ) {
    return {
      status: response.status,
      data: response.data,
    }
  } else {
    console.log(response)
  }
}
