import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Flex from 'components/common/Flex'
import { GaugeChart } from 'echarts/charts'
import * as echarts from 'echarts/core'
import ReactEchart from 'components/common/ReactEchart'
import classNames from 'classnames'
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import { usePartnerContext } from 'providers/PartnerProvider'
import CountUp from 'react-countup'

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  GaugeChart,
  CanvasRenderer,
  LegendComponent
])

const colors = {
  Roadys: 'primary',
  PTP: 'success',
  'DFS Only': 'secondary'
}

export const getColor = name => {
  const dom = document.documentElement
  return getComputedStyle(dom).getPropertyValue(`--falcon-${name}`).trim()
}

const getThemeColor = (name) => getColor(name)

const getOptions = (getThemeColor, data) => ({
  series: [
    {
      name: 'Active vs Inactive',
      type: 'gauge',
      startAngle: 90,
      endAngle: -270,
      radius: '90%',
      pointer: {
        show: false
      },
      label: {
        show: true,
        rotate: 90,
        align: 'left',
        verticalAlign: 'middle',
        position: 'insideBottom',
        distance: 15,
        formatter: '{c}', //  {name|{a}}',
        fontSize: 14,
        color: getThemeColor('gray-1100'),
        rich: {
          name: {}
        }
      },
      progress: {
        show: true,
        overlap: false,
        roundCap: true,
        clip: false,
        itemStyle: {
          color: getThemeColor('primary')
        }
      },
      axisLine: {
        lineStyle: {
          width: 8,
          color: [[1, getThemeColor('gray-200')]]
        }
      },
      splitLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false
      },
      data: [
        {
          value: data,
          detail: {
            offsetCenter: ['7%', '4%']
          }
        }
      ],
      detail: {
        width: 50,
        height: 14,
        fontSize: 20,
        fontWeight: 500,
        fontFamily: 'poppins',
        color: getThemeColor('gray-500'),
        formatter: '{value}%'
      }
    }
  ],
  tooltip: {
    padding: [7, 10],
    transitionDuration: 0,
    backgroundColor: getThemeColor('gray-100'),
    borderColor: getThemeColor('gray-300'),
    textStyle: { color: getThemeColor('gray-1100') },
    borderWidth: 1,
  },
})

const DfsLocations = ({ bodyClassName }) => {
  const { partnerState } = usePartnerContext()
  const [data, setData] = useState({})
  const [options, setChartOptions] = useState({})
  const [activeLocs, setActiveLocs] = useState(0)

  useEffect(() => {
    // const dfsLocData = partnerState.locationData.dfs_location_summary.map(l => {
    //   return {
    //     ...l,
    //     // color: colors[l.name],
    //     name: `${l.name} (${l.value})`
    //   }
    // })
    // setData(dfsLocData)
    // console.log(data)
    setData(partnerState.locationData.dfs_location_summary)
  }, [partnerState.locationData.dfs_location_summary])

  useEffect(() => {
    if (typeof data === 'object' && data !== undefined && data !== null && Object.keys(data).length > 0) {
      setActiveLocs(data.total - data.inactive)
      const percentActive = Math.floor(((data.total - data.inactive) / data.total) * 100)

      setChartOptions(getOptions(getThemeColor, percentActive))
    }
  }, [data])

  // const total = data.reduce((acc, val) => val.value + acc, 0)
  return (
    <Card className="h-100">
      <Card.Header className="pb-0">
        <h6 className="mb-0 mt-2">DFS Participating Locations</h6>
      </Card.Header>

      <Card.Body
        className={classNames(bodyClassName, 'h-100')}
        as={Flex}
        direction="column"
        alignItems="between"
        style={{ alignItems: 'center' }}
      >
        <ReactEchart
          echarts={echarts}
          option={options}
          style={{ height: '7rem', width: '7rem' }}
        />
        <div className="text-center mt-3">
          <h6 className="fs-9 mb-1">
            <FontAwesomeIcon
              icon="check"
              transform="shrink-2"
              className="text-success me-1"
            />
            <CountUp
              start={0}
              end={activeLocs}
              duration={2.75}
            /> Active
          </h6>
        </div>

      </Card.Body>
    </Card>
  )
}

DfsLocations.propTypes = {
  bodyClassName: PropTypes.string
}

export default DfsLocations
