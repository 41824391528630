import React, { useState, useEffect, useContext } from 'react'
import { usePartnerContext } from 'providers/PartnerProvider'
import PageHeader from 'components/common/PageHeader'
import { AppContext } from 'providers/AppProvider'
import { dateTimeOptions, currencyOptions, dateFormatOptions } from 'helpers/formater'
import {
  Button,
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Modal,
  Form,
  Row,
  Col,
  Card,
  InputGroup
} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import { minCellFormater } from 'components/common/advance-table/AdvanceTableHelper'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter'
import useAdvanceTable from 'hooks/useAdvanceTable'
import AdvanceTableProvider from 'providers/AdvanceTableProvider'
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox'
import IconButton from 'components/common/IconButton'
import CustomDateInput from 'components/common/CustomDateInput'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useAccountContext } from 'providers/AccountProvider'

const DFSInvoicePage = () => {
  const navigate = useNavigate()
  const { checkAccess } = useAccountContext()
  const { partnerState, getBillingReports } = usePartnerContext()
  const { config: { isDark } } = useContext(AppContext)
  const [loading, setLoading] = useState(true)
  const [reportData, setReportData] = useState([])
  const [ready, setReady] = useState(false)

  const today = new Date()
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1)
  const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0)
  const defaultStartDate = new Date(firstDay)
  const defaultEndDate = new Date(lastDay)
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)

  useEffect(() => {
    if (!checkAccess(8)) {
      navigate('/')
    }
  }, [])

  useEffect(() => {
    if (partnerState.partnerBillingData !== null) {
      if (Array.isArray(partnerState.partnerBillingData)) {
        setReportData(partnerState.partnerBillingData)
        setLoading(false)
        setReady(true)
      }
    }
  }, [partnerState.partnerBillingData])

  const columns = [
    {
      header: 'Report Id',
      accessorKey: 'id',
      sortable: true,
      // width: '7rem',
      visible: false,
    },
    {
      header: 'Date',
      accessorKey: 'date',
      sortable: true,
      cell: (rowData) => {
        const row = rowData.row.original
        return <h6 className="mb-0">{new Intl.DateTimeFormat('en-US', dateTimeOptions).format(Date.parse(row.date))}</h6>
      },
    },
    {
      header: 'Total',
      accessorKey: 'amount',
      sortable: true,
      cell: (rowData) => {
        const row = rowData.row.original
        return <h6 className="mb-0">{new Intl.NumberFormat('default', currencyOptions).format(row.amount)}</h6>
      },
      meta: {
        cellProps: {
          className: 'text-end',
          width: '105px',
        }
      },
    },
    {
      header: '',
      accessorKey: 'reservation_id',
      exportable: false,
      enableSorting: false,
      // width: '3rem',
      size: 10,
      meta: {
        cellProps: {
          className: 'text-end',
          width: '105px',
        }
      },
      cell: (rowData) => {
        const row = rowData.row.original
        return <div className='ps-0'>
          <Button
            variant='primary'
            type='button'
            size="sm"
            href={`/billing/dfs_invoices/${row.id}`}
          >
            <FontAwesomeIcon icon='eye' />
          </Button>
        </div>
      }
    }
  ]

  function GetInVisibleColumn (finalColumnDef) {
    const inVisibleColumns = finalColumnDef.filter(
      (col) => col.visible === false
    )

    const removedColumn = {}
    for (const item of inVisibleColumns) {
      removedColumn[item.accessorKey] = false
    }

    return removedColumn
  }

  const table = useAdvanceTable({
    data: reportData,
    columns,
    sortable: true,
    pagination: true,
    perPage: 10,
    initialState: {
      columnVisibility: GetInVisibleColumn(columns),
    }
  })

  useEffect(() => {
    if (ready) {
      // setLoading(true)
      getBillingReports(startDate, endDate)
    }
  }, [startDate, endDate])

  const { register, handleSubmit, setValue, reset } = useForm({
    startDate,
    endDate
  })

  return (
    <>
      <PageHeader
        title='DFS Billing Invoices'
        description=""
        className='mb-3'
      />

      <AdvanceTableProvider {...table}>
        <Card className="mb-3">
          <Nav className='m-2'>
            <Nav.Item style={{ marginRight: '.4rem' }}>
              <Form.Group controlId="startDate" style={{ display: 'flex' }}>
                <Form.Label style={{ whiteSpace: 'nowrap', marginTop: '.3rem', marginRight: '0.3rem' }}>Start Date</Form.Label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date)
                    setValue('startDate', new Intl.DateTimeFormat('en-US', dateFormatOptions).format(date))
                  }}
                  customInput={
                    <CustomDateInput
                      formControlProps={{
                        ...register('startDate'),
                        style: { fontSize: '.71rem' }
                      }}
                    />
                  }
                />
              </Form.Group>
            </Nav.Item>
            <Nav.Item style={{ marginRight: '.4rem' }}>
              <Form.Group controlId="endDate" style={{ display: 'flex' }}>
                <Form.Label style={{ whiteSpace: 'nowrap', marginTop: '.3rem', marginRight: '0.3rem' }}>End Date</Form.Label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date)
                    setValue('endDate', new Intl.DateTimeFormat('en-US', dateFormatOptions).format(date))
                  }}
                  customInput={
                    <CustomDateInput
                      formControlProps={{
                        ...register('endDate'),
                        style: { fontSize: '.71rem' }
                      }}
                    />
                  }
                />
              </Form.Group>
            </Nav.Item>
            <Nav.Item style={{ flexGrow: 1 }}>
              <AdvanceTableSearchBox table />
            </Nav.Item>
          </Nav>
          <Card.Body className="p-0">
            <AdvanceTable
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs-10 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </Card.Footer>
        </Card>
      </AdvanceTableProvider>
    </>
  )
}

export default DFSInvoicePage
