import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
} from 'react-bootstrap'
import { useSystemAdminContext } from 'providers/SystemAdminProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const UserDeleteModal = ({ show, hide, user }) => {
  const { deleteUser } = useSystemAdminContext()
  // console.log(user.email)
  const [isSaving, setSaving] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSaving(true)
    await deleteUser(user.id)
    setSaving(false)
    hide()
  }

  return (
    <Modal centered show={show} onHide={hide}>
      <Modal.Header closeButton>
        <Modal.Title>Delete User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you certain you want to delete the following user?
        <ul>
          <li>{user.email}</li>
          <li>{user.name}</li>
        </ul>
        This action is limited to the above user account alone.  It will not delete any data related to the locations you can view.
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={handleSubmit}
        >
          {isSaving
            ? <FontAwesomeIcon
              icon='circle-notch'
              className='me-1 fa-spinner fa-spin'
            />
            : ''
          }
          {' '}
          Yes
        </Button>
        <Button variant="secondary" onClick={hide}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UserDeleteModal

UserDeleteModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  user: PropTypes.object,
}
