import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  Tooltip,
  OverlayTrigger,
  Badge
} from 'react-bootstrap'

const SubtleBadge = ({ bg = 'primary', pill, children, className, tip }) => {
  return (
    <Badge bg={bg} pill={pill}
      className={classNames(className,
      )}
    >
      <OverlayTrigger
        placement='right-start'
        overlay={
          <Tooltip>{tip}</Tooltip>
        }
      >
        <div>
          {children}
        </div>
      </OverlayTrigger>
    </Badge>
  )
}

SubtleBadge.propTypes = {
  bg: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
    'light',
    'dark'
  ]),
  pill: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  tip: PropTypes.string
}

export default SubtleBadge
