import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Form, Row, Modal, Card, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { formatDateTime, formatDollars } from 'helpers/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { usePartnerContext } from 'providers/PartnerProvider'
import Flex from 'components/common/Flex'
import IconButton from 'components/common/IconButton'
import Message from 'components/chat/Message'
import MessageTextArea from 'components/chat/MessageTextArea'
import ScrollToBottom from 'react-scroll-to-bottom'

const getIcon = type => {
  const icon = ['far']
  if (type.includes('image')) {
    icon.push('file-image')
  }
  if (type.includes('video')) {
    icon.push('file-video')
  }
  if (type.includes('audio')) {
    icon.push('file-audio')
  }
  if (type.includes('zip')) {
    icon.push('file-archive')
  }
  if (type.includes('pdf')) {
    icon.push('file-pdf')
  }
  if (
    type.includes('html') ||
    type.includes('css') ||
    type.includes('json') ||
    type.includes('javascript')
  ) {
    icon.push('file-code')
  }
  if (icon.length === 1) {
    icon.push('file')
  }
  return icon
}

const getName = name => {
  const [fileName, extension] = name.split('.')
  return `${fileName.slice(0, 24)}.${extension}`
}

const ComposeAttachment = ({ handleDetachAttachment, file }) => {
  return (
    <Flex
      alignItems="center"
      className="border px-2 rounded-3 bg-white dark__bg-1000 my-1 fs-10"
    >
      <FontAwesomeIcon icon={getIcon(file.type)} className="fs-2" />
      <span className="mx-2 flex-1">
        {getName(file.name)} ({(file.size / 1024).toFixed(2)}kb)
      </span>
      <span
        className="text-300 p-1 ml-auto cursor-pointer"
        // id={`attachmentTooltip${key}`}
        onClick={() => handleDetachAttachment(file.name)}
      >
        <FontAwesomeIcon icon="times" />
      </span>
    </Flex>
  )
}

ComposeAttachment.propTypes = {
  handleDetachAttachment: PropTypes.func.isRequired,
  file: PropTypes.array.isRequired
}

const EditDispute = ({ activeRedemption = {}, show, hide, hasLabel = true }) => {
  const { partnerState, createNewDispute } = usePartnerContext()
  const [attachments, setAttachments] = useState([])
  const [isProcessing, setIsProcessing] = useState(false)
  const [isSubmitable, setIsSubmitable] = useState(false)
  const [messages, setMessages] = useState([])
  const [redemption, setRedemption] = useState({})
  const [formData, setFormData] = useState({
    id: 0,
    message: '',
    files: []
  })
  const messagesEndRef = useRef(null)

  useEffect(() => {
    if (
      activeRedemption !== null
      && Object.prototype.hasOwnProperty.call(activeRedemption, 'reservation_id')
    ) {
      setRedemption(activeRedemption)
    }
  }, [activeRedemption])

  useEffect(() => {
    if (partnerState.dfsTransactionData !== null && Object.prototype.hasOwnProperty.call(activeRedemption, 'reservation_id')) {
      const red = partnerState.dfsTransactionData.filter(r => r.reservation_id === activeRedemption.reservation_id)
      setRedemption(red[0])
    }
  }, [partnerState.dfsTransactionData])

  useEffect(() => {
    if (
      redemption !== null
      && redemption !== undefined
      && Object.prototype.hasOwnProperty.call(redemption, 'reservation_id')
    ) {
      setFormData({
        ...formData,
        id: redemption.reservation_id,
      })
      setMessages(redemption.dispute_messages !== null ? redemption.dispute_messages : [])
    }
  }, [redemption])

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  useEffect(() => {
    if (formData.message) {
      setIsSubmitable(true)
    } else {
      setIsSubmitable(false)
    }
  }, [formData])

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsProcessing(true)
    // await submitDispute(formData)
    await createNewDispute(formData)
    setIsProcessing(false)
    // hide()
    setFormData({
      ...formData,
      message: '',
      files: []
    })
  }

  const handleAddAttachment = files => {
    if (files.length === 0) return
    console.log(files)
    setFormData({
      ...formData,
      files: [
        ...formData.files,
        ...files
      ]
    })
    console.log(formData)
  }

  const handleDetachAttachment = fileToRemove => {
    const files = formData.files.filter(file => file.name !== fileToRemove)
    setFormData({
      ...formData,
      files: [
        ...files
      ]
    })
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      <Modal
        centered
        show={show}
        onHide={hide}
        size={'xl'}
      >
        <Modal.Header className='modal-header-success'>
          <Modal.Title>Dispute Transaction</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <Form.Group as={Col} lg={12} controlId='viewRedeem'>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Reservation ID</Form.Label>
                    <Form.Control
                      size="sm"
                      type='text'
                      value={redemption.reservation_id}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Order ID</Form.Label>
                    <Form.Control
                      size="sm"
                      type='text'
                      value={redemption.external_order_id}
                      disabled
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Fleet</Form.Label>
                    <Form.Control
                      size="sm"
                      type='text'
                      value={redemption.fleet_co}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Location</Form.Label>
                    <Form.Control
                      size="sm"
                      type='text'
                      value={redemption.location_name}
                      disabled
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Pump</Form.Label>
                    <Form.Control
                      size="sm"
                      type='text'
                      value={redemption.pump}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Gallons</Form.Label>
                    <Form.Control
                      size="sm"
                      type='text'
                      value={redemption.gallons}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                      size="sm"
                      type='text'
                      value={redemption.transaction_status}
                      disabled
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Retail Limit</Form.Label>
                    <Form.Control
                      size="sm"
                      type='text'
                      value={formatDollars(redemption.retail_limit)}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Gallon Limit</Form.Label>
                    <Form.Control
                      size="sm"
                      type='text'
                      value={redemption.gallon_limit}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Exceeded Gallons</Form.Label>
                    <Form.Control
                      size="sm"
                      type='text'
                      value={redemption.gallon_exceeded}
                      disabled
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Retail</Form.Label>
                    <Form.Control
                      size="sm"
                      type='text'
                      value={formatDollars(redemption.retail_price)}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Retail Date</Form.Label>
                    <Form.Control
                      size="sm"
                      type='text'
                      value={formatDateTime(redemption.retail_date)}
                      disabled
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Reserved On</Form.Label>
                    <Form.Control
                      size="sm"
                      type='text'
                      value={formatDateTime(redemption.reserved_on)}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Redemption Started</Form.Label>
                    <Form.Control
                      size="sm"
                      type='text'
                      value={formatDateTime(redemption.redeem_started)}
                      disabled
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Completed On</Form.Label>
                    <Form.Control
                      size="sm"
                      type='text'
                      value={formatDateTime(redemption.completed_on)}
                      disabled
                      readOnly
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Card>
                <Card.Body>
                  <Card.Title>Messages</Card.Title>
                  {/* <div className="chat-content-body" style={{ display: 'inherit' }}> */}
                  <div className="chat-content-scroll-area" style={{ height: '425px' }}>
                    {messages.map((message, index) => (
                      <div key={index}>
                        <Message
                          message={message}
                        />
                      </div>
                    ))}
                    <div ref={messagesEndRef} />
                  </div>
                  {/* </div> */}
                  <MessageTextArea
                    formData={formData}
                    handleSubmit={handleSubmit}
                    handleFieldChange={handleFieldChange}
                    isProcessing={isProcessing}
                    setIsProcessing={setIsProcessing}
                    isSubmitable={isSubmitable}
                    setIsSubmitable={setIsSubmitable}
                    handleAddAttachment={handleAddAttachment}
                    handleDetachAttachment={handleDetachAttachment}
                  />
                  {formData.files.length > 0 && (
                    <div className="bg-body-tertiary px-x1 py-1">
                      <Flex direction="column" inline>
                        {formData.files.map((attachment, i) => {
                          return (
                            <ComposeAttachment
                              file={attachment}
                              key={i}
                              handleDetachAttachment={handleDetachAttachment}
                            />
                          )
                        }
                        )}
                      </Flex>
                    </div>
                  )}
                  {/* <Card.Text>
                      Please complete the folowing form to log a dispute about this transaction.  Be as thorough as possible.
                    </Card.Text> */}
                  {/* <Form.Group className='mb-3'>
                      {hasLabel && <Form.Label>Note</Form.Label>}
                      <Form.Control
                        // placeholder={!hasLabel ? 'Note' : ''}
                        name='note'
                        onChange={handleFieldChange}
                        as='textarea'
                        rows={3}
                        value={formData.note}
                      />
                    </Form.Group> */}
                  {/* <Form.Group className="mb-3">
                    {hasLabel && <Form.Label>Evidence</Form.Label>}
                      {formData.files.length > 0 && (
                        <div className="bg-body-tertiary px-x1 py-1">
                          <Flex direction="column" inline>
                            {formData.files.map((attachment, i) => {
                              return (
                              <ComposeAttachment
                                file={attachment}
                                key={i}
                                handleDetachAttachment={handleDetachAttachment}
                              />
                              )
                            }
                            )}
                          </Flex>
                        </div>
                      )}
                      <Form.Group controlId="email-attachment">
                        <Form.Control
                          type="file"
                          multiple
                          className="d-none"
                          onChange={({ target }) => handleAddAttachment(target.files)}
                        />
                        <OverlayTrigger
                          overlay={
                            <Tooltip style={{ position: 'fixed' }} id="previousTooltip">
                              Attach files
                            </Tooltip>
                          }
                        >
                          <div>
                            <IconButton
                              as={Form.Label}
                              className="me-2 mb-0"
                              variant="falcon-default"
                              size="sm"
                              icon="plus"
                              transform="shrink-3"
                            >
                              <span className="d-none d-sm-inline-block ms-1">Add File</span>
                            </IconButton>
                          </div>
                        </OverlayTrigger>
                      </Form.Group>
                    </Form.Group> */}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant='secondary'
            type='button'
            onClick={hide}
          >
            Close
          </Button>
          {/* <Button
              variant='primary'
              onClick={handleSubmit}
              type='submit'
              disabled={!isSubmitable}
            >
              {isProcessing
                ? (
                  <FontAwesomeIcon
                    icon='circle-notch'
                    className='me-1 fa-spinner fa-spin'
                  />
                  )
                : ''
              }
              Save
            </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  )
}

EditDispute.propTypes = {
  activeRedemption: PropTypes.object,
  show: PropTypes.bool,
  hide: PropTypes.func,
  hasLabel: PropTypes.bool,
}

export default EditDispute
