import React, { createContext, useContext, useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { useAccountContext } from 'providers/AccountProvider'
import { initialState, NotificationReducer } from 'reducers/notificationReducer'
import { Amplify, API, graphqlOperation } from 'aws-amplify'
import awsconfig from 'aws-exports'
import * as queries from 'graphql/queries'
import * as subscriptions from 'graphql/subscriptions'
import * as mutations from 'graphql/mutations'
Amplify.configure(awsconfig)

export const NotificationContext = createContext()

const NotificationProvider = ({ children }) => {
  const { appState } = useAccountContext()
  // eslint-disable-next-line
  const [notificationState, dispatch] = useReducer(NotificationReducer, initialState)
  // eslint-disable-next-line
  const [readyState, setReadyState] = useState(false)
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (
      appState.ready
      && appState.authenticated) {
      setReadyState(appState.ready)
      setUser(appState.user)
    }
  }, [appState])

  useEffect(() => {
    if (readyState === true) {
      // getListNotifications()
      connectSubs()
    }
  }, [readyState])

  async function connectSubs () {
    API.graphql(
      graphqlOperation(subscriptions.onCreateNotification, { user })
    ).subscribe({
      next: ({ provider, value }) => {
        if (value !== undefined && value !== null) {
          dispatch({
            type: 'ADD_NOTIFICATION',
            payload: {
              notification: value.data.onCreateNotification,
            },
          })
        }
      },
      error: (error) => console.warn(error)
    })

    API.graphql(
      graphqlOperation(subscriptions.onUpdateNotification)
    ).subscribe({
      next: ({ provider, value }) => {
        if (value !== undefined && value !== null) {
          dispatch({
            type: 'UPDATE_NOTIFICATION',
            payload: {
              notification: value.data.onUpdateNotification,
            },
          })
        }
      },
      error: (error) => console.warn(error)
    })

    API.graphql(
      graphqlOperation(subscriptions.onDeleteNotification)
    ).subscribe({
      next: ({ provider, value }) => {
        if (value !== undefined && value !== null) {
          dispatch({
            type: 'DELETE_NOTIFICATION',
            payload: {
              notification: value.data.onDeleteNotification,
            },
          })
        }
      },
      error: (error) => console.warn(error)
    })
  }

  async function getListNotifications () {
    const notifications = await API.graphql(graphqlOperation(queries.listAllNotifications))
    if (notifications.data.listUserNotifications !== null) {
      dispatch({
        type: 'INIT_NOTIFICATIONS',
        payload: {
          notifications: notifications.data.listUserNotifications.items,
        },
      })
    }
  }

  async function updateNotificationToRead (id) {
    const notifications = await API.graphql(graphqlOperation(mutations.updateNotificationToRead, { id }))
  }

  const initNotifications = async (data) => {
    dispatch({
      type: 'INIT_NOTIFICATIONS',
      payload: {
        notifications: data,
      },
    })
  }

  const sharedState = {
    notificationState,
    updateNotificationToRead,
    initNotifications
  }

  return (
    <NotificationContext.Provider value={sharedState}>
      {children}
    </NotificationContext.Provider>
  )
}

NotificationProvider.propTypes = { children: PropTypes.node }

export const useNotificationContext = () => useContext(NotificationContext)

export default NotificationProvider
