import React, { useEffect, useState, useContext } from 'react'
import PageHeader from 'components/common/PageHeader'
import { useNotificationContext } from 'providers/NotificationProvider'
import { useAccountContext } from 'providers/AccountProvider'
import { Form, Button, Card } from 'react-bootstrap'
import createMarkup from 'helpers/createMarkup'
import { useParams } from 'react-router-dom'
import { isIterableArray } from 'helpers/utils'
import Skeleton from 'react-loading-skeleton'
import Markdown from 'react-markdown'

const Notification = () => {
  const { noteId } = useParams()
  const {
    config: { isDark }
  } = useAccountContext()

  const { notificationState, updateNotificationToRead } = useNotificationContext()
  const [notification, setNotification] = useState(null)
  const [isRead, setIsRead] = useState(false)

  useEffect(() => {
    if (isIterableArray(notificationState.notifications)) {
      const notification = notificationState.notifications.filter(i => {
        if (i.id === noteId) {
          return true
        }
        return false
      })[0]

      setNotification(notification)

      if (!notification.read) {
        setTimeout(function () {
          updateNotificationToRead(notification.id)
        }, 5000)
      }
    }
  }, [notificationState.notifications, noteId])

  return (
    <>
      {notification === null
        ? <Card>
          <Card.Body>
            <Skeleton count={4} />
          </Card.Body>
        </Card>
        : <>
          <PageHeader
            title={notification.title}
            className='mb-3'
            date={notification.createdAt}
            type={notification.type}
          />
          <Card>
            <Card.Body>
              <Markdown>
                {
                  notification.message
                    ? notification.message
                    : ''
                }
              </Markdown>
            </Card.Body>
          </Card>
        </>
      }

    </>
  )
}

export default Notification
