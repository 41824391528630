import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, Col, Form, Row, Modal, InputGroup, Tooltip, OverlayTrigger, Badge } from 'react-bootstrap'
import FalconCardHeader from 'components/common/FalconCardHeader'
// import validator from 'validator'
import { IMask, IMaskInput } from 'react-imask'
import parsePhoneNumber from 'libphonenumber-js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ChangePassword from './ChangePassword'
import ChangeUsername from './ChangeUsername'
import { toast } from 'react-toastify'

import { useAccountContext } from 'providers/AccountProvider'
import { useProfileContext } from 'providers/ProfileProvider'

const ProfileSettings = ({ hasLabel = true }) => {
  const mask = '(000) 000-0000'
  const { appState, deleteProfileLink, authenticateFed } = useAccountContext()
  const { profileState, updateUser } = useProfileContext()
  const [showStatusModal, setShowStatusModal] = useState(false)
  const [showPassModal, setShowPassModal] = useState(false)
  const [showUserModal, setShowUserModal] = useState(false)
  const [userAttribs, setUserAttribs] = useState(null)
  const [userProfileAttribs, setUserProfileAttribs] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isSubmitable, setIsSubmitable] = useState(true)
  const [email, setEmail] = useState('')
  const [emailVerified, setEmailVerified] = useState(false)
  const [hasGoogleFederatedAuth, setHasGoogleFederatedAuth] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
  })

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(appState, 'attributes')) {
      setUserAttribs(appState.attributes)
    }
  }, [appState])

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(profileState, 'user_profile')) {
      setUserProfileAttribs(profileState.user_profile)
    }
  }, [profileState])

  useEffect(() => {
    async function populateData (data) {
      const tmpData = {
        // name: data.name
      }

      // if (data.phone_number) {
      //   const phoneNumber = parsePhoneNumber(data.phone_number)
      //   tmpData.phone = phoneNumber.formatNational()
      // }

      if (data.identities) {
        const identities = JSON.parse(data.identities)

        if (identities.length < 1) {
          setHasGoogleFederatedAuth(false)
        }

        identities.forEach(i => {
          if (i.providerType === 'Google') {
            setHasGoogleFederatedAuth(true)
          }
        })
        // const phoneNumber = parsePhoneNumber(data.phone_number)
        // tmpData.phone = phoneNumber.formatNational()
      }

      setFormData({
        ...formData,
        ...tmpData
      })
      setEmail(data.email)
      setEmailVerified(data.email_verified)
    }

    if (
      typeof userAttribs === 'object' &&
      userAttribs !== null
    ) {
      populateData(userAttribs)
    }
  }, [userAttribs])

  useEffect(() => {
    async function populateData (data) {
      const tmpData = {
        name: data.name,
        email: data.email
      }

      if (data.phone_number) {
        const phoneNumber = parsePhoneNumber(data.phone_number)
        tmpData.phone = phoneNumber.formatNational()
      }

      setFormData({
        ...formData,
        ...tmpData
      })
    }

    if (
      typeof userProfileAttribs === 'object' &&
      userProfileAttribs !== null
    ) {
      populateData(userProfileAttribs)
    }
  }, [userProfileAttribs])

  // useEffect(() => {
  //   if (userAttribs !== null) {
  //     const phoneNumber = parsePhoneNumber(formData.phone, 'US')
  //     if (formData.name !== userAttribs.name || phoneNumber.number !== userAttribs.phone_number) {
  //       setIsSubmitable(true)
  //     } else {
  //       setIsSubmitable(false)
  //     }
  //   }
  // }, [formData])

  const handleChange = e => {
    let fieldValue = e.target.value

    if (e.target.name === 'phone') {
      const mask = IMask(e.target, { mask: '(000) 000-0000' })
      fieldValue = mask.unmaskedValue
    }

    setFormData({
      ...formData,
      [e.target.name]: fieldValue,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsProcessing(true)
    const phoneNumber = parsePhoneNumber(formData.phone, 'US')
    if (!phoneNumber.isValid()) {
      toast.error(
        'Phone number entered is not valid',
        {
          autoClose: false,
        }
      )
      return
    }

    const data = {
      name: formData.name,
      phone_number: phoneNumber.number,
      email: formData.email,
    }
    console.log(data)

    try {
      await updateUser(data)
      setIsProcessing(false)
      toast.success('Your profile has been successfully updated')
    } catch (error) {
      setIsProcessing(false)

      toast.error(
        error.message,
        {
          autoClose: false,
        }
      )
    }
  }

  return (
    <>
      <Modal centered show={showStatusModal} onHide={() => setShowStatusModal(false)}>
        <Modal.Header className='modal-header-success'>
          <Modal.Title>Update Successfull</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your profile changes were saved successfully.</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowStatusModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <ChangePassword show={showPassModal} hide={() => setShowPassModal(false)} />
      <ChangeUsername {...userAttribs} show={showUserModal} hide={() => setShowUserModal(false)} />

      <Card className='mb-3'>
        <FalconCardHeader title='Account Settings' titleTag='h6' className='d-block d-lg-none py-2' light endEl='' />
        <FalconCardHeader title='Account Settings' className='d-none d-lg-block' />
        <Card.Body className='bg-body-tertiary'>
          <Row className='mb-3 g-3'>
            <Form.Group as={Col} lg={12} controlId='email'>
              {hasLabel && <Form.Label>Email/Username</Form.Label>}
              <InputGroup className='mb-3'>
                <Form.Control
                  type='text'
                  placeholder={!hasLabel ? 'Email/Username' : ''}
                  defaultValue={email}
                  name='email'
                  readOnly
                />
                <InputGroup.Text>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip>
                        {emailVerified ? 'Verified Email Address' : 'Email Address Not Verified'}
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon={emailVerified ? 'circle-check' : 'triangle-exclamation'}
                      className='me-1'
                      color={emailVerified ? 'springgreen' : 'orange'}
                    />
                  </OverlayTrigger>
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className='g-3'>
            {/* <Col>
                <Button
                  variant='primary'
                  type='button'
                  onClick={() => setShowPassModal(true)}
                  size='sm'
                >
                  Change Password
                </Button>
              </Col> */}
            <Col className='text-end'>
              <Button
                variant='primary'
                type='button'
                size='sm'
                onClick={() => setShowUserModal(true)}
              >
                Change Username
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className='mb-3'>
        <FalconCardHeader title='Authentication' titleTag='h6' className='d-block d-lg-none py-2' light endEl='' />
        <FalconCardHeader title='Authentication' className='d-none d-lg-block' />
        <Card.Body className='bg-body-tertiary'>

          <Form.Group as={Row} className='mb-3' controlId='formHorizontalEmail'>
            <Form.Label column sm={3}>
              Password
            </Form.Label>
            <Col sm={6}>
              <Form.Text>Password authentication is enabled for all accounts.  To update click the Change Password button.</Form.Text>
            </Col>
            <Col sm={3} className='text-end'>
              <Button
                variant='primary'
                type='button'
                onClick={() => setShowPassModal(true)}
                size='sm'
              >
                Change Password
              </Button>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3' controlId='formHorizontalEmail'>
            <Form.Label column sm={3}>
              Google Auth
              <div className='text-center'>
                <Badge
                  bg={hasGoogleFederatedAuth ? 'success' : 'warning'}
                >
                  {hasGoogleFederatedAuth ? 'Active' : 'Deactive'}
                </Badge>
              </div>
            </Form.Label>
            <Col sm={6}>
              <Form.Text>
                {hasGoogleFederatedAuth
                  ? 'Google Sign-in is enabled.  Disabling will require you to enter a password at login.'
                  : 'Google Sign-in is disabled.  Enabling will link your ' + process.env.REACT_APP_PRODUCT_NAME + ' account to your Google account for easy sign-in.'}
              </Form.Text>
            </Col>
            <Col sm={3} className='text-end'>
              {hasGoogleFederatedAuth
                ? <Button
                  variant='primary'
                  type='button'
                  size='sm'
                  onClick={() => deleteProfileLink()}
                >
                  Disable
                </Button>
                : <Button
                  variant='primary'
                  type='button'
                  size='sm'
                  onClick={() => authenticateFed()}
                >
                  Enable
                </Button>
              }
            </Col>
          </Form.Group>

        </Card.Body>
      </Card>

      <Card>
        <FalconCardHeader title='Profile Settings' titleTag='h6' className='d-block d-lg-none py-2' light endEl='' />
        <FalconCardHeader title='Profile Settings' className='d-none d-lg-block' />
        <Card.Body className='bg-body-tertiary'>
          <Form onSubmit={handleSubmit}>
            <Row className='mb-3 g-3'>
              <Form.Group as={Col} lg={12} controlId='name'>
                {hasLabel && <Form.Label>Name</Form.Label>}
                <Form.Control
                  type='text'
                  placeholder={!hasLabel ? 'Name' : ''}
                  value={formData.name}
                  name='name'
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Row className='mb-3 g-3'>
              <Form.Group as={Col} lg={6} controlId='phone'>
                {hasLabel && <Form.Label>Phone</Form.Label>}
                <IMaskInput
                  placeholder={!hasLabel ? 'Phone Number' : ''}
                  value={formData.phone}
                  name='phone'
                  onChange={handleChange}
                  onInput={handleChange}
                  mask={mask}
                  className='form-control'
                />
              </Form.Group>
            </Row>

            <Row className='g-3'>
              <Col className='text-end'>
                <Button
                  variant='primary'
                  type='submit'
                  size='sm'
                  disabled={!isSubmitable}
                >
                  {isProcessing
                    ? <FontAwesomeIcon
                      icon='circle-notch'
                      className='me-1 fa-spinner fa-spin'
                    />
                    : ''
                  }
                  Update
                </Button>
              </Col>
            </Row>

          </Form>
        </Card.Body>
      </Card>
    </>
  )
}

ProfileSettings.propTypes = {
  hasLabel: PropTypes.bool
}

export default ProfileSettings
