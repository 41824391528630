import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react'
import PropTypes from 'prop-types'
import { getDataFromAPI, postDataToAPI, deleteDataFromAPI, patchDataToAPI } from 'helpers/api'
import { putDataToAPI } from 'helpers/cf'
import { useAccountContext } from 'providers/AccountProvider'
import { initialState, PartnerReducer } from '../reducers/partnerReducer'
import { contactTypes, webhookEvents, webhookEnvironments } from 'config'

export const PartnerContext = createContext()

const PartnerProvider = ({ children }) => {
  const { appState } = useAccountContext()
  // const { profileState } = useProfileContext()
  const [partnerState, dispatch] = useReducer(PartnerReducer, initialState)
  const [readyState, setReadyState] = useState(false)
  // const [locations, setLocations] = useState(null)
  // const [company, setCompany] = useState(null)
  const [userId, setUserId] = useState(null)

  useEffect(() => {
    if (
      appState.ready
      && appState.authenticated
      && appState.attributes !== null
      // && Object.prototype.hasOwnProperty.call(appState.attributes, 'custom:companyid')
    ) {
      setReadyState(appState.ready)
      // console.log(appState)
      setUserId(appState.user)
    }

    if (process.env.REACT_APP_ENV === 'demo') {
      setReadyState(true)
    }
  }, [appState])

  useEffect(() => {
    if (readyState === true) {
      // getCompanyInfo()
      // getKiosksForLocations()
      getContacts()
      getTokens()
      getGallonsPerDay()
      getGallonsPerMonth()
      getTransactions()
      getBillingReports()
      getDfsDailyStatus()
      getWebhooks()
      getReservations()
      getDfsLocationStatus()
    }
  }, [readyState])

  async function getContacts () {
    const details = await getDataFromAPI('/contacts')
    if (details !== undefined && details.status === 200) {
      details.data = details.data.map(d => {
        return {
          ...d,
          type: contactTypes.find((o) => o.value === d.type)
        }
      })
      dispatch({
        type: 'INIT_CONTACTS',
        payload: {
          partnerContacts: details.data,
        },
      })
    }
  }

  async function getTokens () {
    const details = await getDataFromAPI('/tokens')
    if (details !== undefined && details.status === 200) {
      dispatch({
        type: 'INIT_TOKENS',
        payload: {
          tokens: details.data,
        },
      })
    }
  }

  async function getGallonsPerDay (month = null, year = null) {
    let input = {}
    if (month != null && year != null) {
      const start = new Date(year, month, 1).toISOString().split('T')[0]
      const end = new Date(year, month + 1, 0).toISOString().split('T')[0]

      input = {
        start: start,
        end: end
      }
    }
    const details = await getDataFromAPI('/dfs/gallons/daily', input)
    if (details !== undefined && details.status === 200) {
      dispatch({
        type: 'INIT_DFS_PER_DAY',
        payload: {
          dfsPerDayData: details.data,
        },
      })
    }
  }

  async function getGallonsPerMonth () {
    const details = await getDataFromAPI('/dfs/gallons/monthly')
    if (details !== undefined && details.status === 200) {
      dispatch({
        type: 'INIT_DFS_PER_MONTH',
        payload: {
          dfsPerMonthData: details.data,
        },
      })
    }
  }

  async function getTransactions (start = null, end = null) {
    let input = {}
    if (start != null && end != null) {
      input = {
        start: start.toISOString().split('T')[0],
        end: end.toISOString().split('T')[0]
      }
    }
    const details = await getDataFromAPI('/dfs/transactions', input)
    if (details !== undefined && details.status === 200) {
      dispatch({
        type: 'INIT_DFS_TRANSACTIONS',
        payload: {
          dfsTransactionData: details.data,
        },
      })
    }
  }

  async function getReservations (start = null, end = null) {
    let input = {}
    if (start != null && end != null) {
      input = {
        start: start.toISOString().split('T')[0],
        end: end.toISOString().split('T')[0]
      }
    }
    const details = await getDataFromAPI('/dfs/reservations', input)
    if (details !== undefined && details.status === 200) {
      dispatch({
        type: 'INIT_DFS_RESERVATIONS',
        payload: {
          dfsReservationData: details.data,
        },
      })
    }
  }

  async function getBillingReports (start = null, end = null) {
    let input = {}
    if (start != null && end != null) {
      input = {
        start: start.toISOString().split('T')[0],
        end: end.toISOString().split('T')[0]
      }
    }
    const details = await getDataFromAPI('/dfs/bills', input)
    if (details !== undefined && details.status === 200) {
      dispatch({
        type: 'INIT_PARTER_BILLING',
        payload: {
          partnerBillingData: details.data,
        },
      })
    }
  }

  async function getDfsDailyStatus () {
    const details = await getDataFromAPI('/dfs/status/daily')
    if (details !== undefined && details.status === 200) {
      dispatch({
        type: 'INIT_DFS_DAILY_STATUS',
        payload: {
          dfs_status: details.data.status,
          dfs_location_summary: details.data.locations,
        },
      })
    }
  }

  async function getDfsLocationStatus () {
    const details = await getDataFromAPI('/dfs/status/locations')
    if (details !== undefined && details.status === 200) {
      dispatch({
        type: 'INIT_DFS_LOCATION_STATUS',
        payload: {
          dfs_locations: details.data,
        },
      })
    }
  }

  async function getWebhooks () {
    const details = await getDataFromAPI('/webhooks')
    if (details !== undefined && details.status === 200) {
      details.data = details.data.map(d => {
        return {
          ...d,
          event: webhookEvents.find((o) => o.value === d.event),
          environment: webhookEnvironments.find((o) => o.value === d.environment)
        }
      })
      // console.log(details.data)
      dispatch({
        type: 'INIT_WEBHOOKS',
        payload: {
          webhooks: details.data,
        },
      })
    }
  }

  const initData = async (data) => {
    dispatch({
      type: 'INIT_PARTER',
      payload: {
        partnerData: data,
      },
    })
  }

  const deleteContact = async (contactId) => {
    // console.log(contactId)

    const details = await deleteDataFromAPI('/contact/' + contactId)
    // console.log(details)
    if (details !== undefined && details.status === 200) {
      dispatch({
        type: 'DELETE_CONTACT',
        payload: {
          contact: contactId
        },
      })
    }
  }

  const createNewContact = async (data) => {
    // console.log(data)

    const input = {
      ...data,
      type: data.type.value
    }

    // console.log(input)
    const details = await postDataToAPI('/contact', input)
    // console.log(details)
    if (details !== undefined && details.status === 200) {
      // console.log(details.data)
      details.data = {
        ...details.data,
        type: contactTypes.find((o) => o.value === details.data.type)
      }
      dispatch({
        type: 'ADD_CONTACT',
        payload: {
          contact: details.data,
        },
      })
    }
  }

  const updateContact = async (data) => {
    data = {
      ...data,
      type: data.type.value
    }
    const details = await patchDataToAPI('/contact/' + data.id, data)
    // console.log(details)
    if (details !== undefined && details.status === 200) {
      // console.log(details.data)
      details.data = {
        ...details.data,
        type: contactTypes.find((o) => o.value === details.data.type)
      }
      // console.log(details.data)

      dispatch({
        type: 'UPDATE_CONTACT',
        payload: {
          contact: details.data,
        },
      })
    }
  }

  const createNewToken = async (data) => {
    // console.log(data)

    const details = await postDataToAPI('/token', data)
    // console.log(details)
    if (details !== undefined && details.status === 200) {
      // console.log(details.data)
      dispatch({
        type: 'ADD_TOKEN',
        payload: {
          token: details.data,
        },
      })
    }
  }

  const deleteToken = async (tokenId) => {
    // console.log(tokenId)

    const details = await deleteDataFromAPI('/token/' + tokenId)
    // console.log(details)
    if (details !== undefined && details.status === 200) {
      dispatch({
        type: 'DELETE_TOKEN',
        payload: {
          token: tokenId
        },
      })
    }
  }

  const createNewWebhook = async (data) => {
    // console.log(data)
    data = {
      ...data,
      event: data.event.value,
      environment: data.environment.value
    }
    // console.log(data)

    const details = await postDataToAPI('/webhook', data)
    // console.log(details)
    if (details !== undefined && details.status === 200) {
      // console.log(details.data)
      details.data = {
        ...details.data,
        event: webhookEvents.find((o) => o.value === details.data.event),
        environment: webhookEnvironments.find((o) => o.value === details.data.environment)
      }
      dispatch({
        type: 'ADD_WEBHOOK',
        payload: {
          webhook: details.data,
        },
      })
    }
  }

  const deleteWebhook = async (webhookId) => {
    // console.log(tokenId)

    const details = await deleteDataFromAPI('/webhook/' + webhookId)
    // console.log(details)
    if (details !== undefined && details.status === 200) {
      dispatch({
        type: 'DELETE_WEBHOOK',
        payload: {
          webhook: webhookId
        },
      })
    }
  }

  const updateWebhook = async (data) => {
    // console.log(data)
    data = {
      ...data,
      event: data.event.value,
      environment: data.environment.value
    }
    // console.log(data)
    const details = await patchDataToAPI('/webhook/' + data.id, data)
    // console.log(details)
    if (details !== undefined && details.status === 200) {
      // console.log(details.data)
      details.data = {
        ...details.data,
        event: webhookEvents.find((o) => o.value === details.data.event),
        environment: webhookEnvironments.find((o) => o.value === details.data.environment)
      }
      dispatch({
        type: 'UPDATE_WEBHOOK',
        payload: {
          webhook: details.data,
        },
      })
    }
  }

  const submitDispute = async (data) => {
    // console.log(data)
    const headers = {
      'content-type': 'multipart/form-data',
    }
    const formData = new FormData()
    formData.append('id', data.id)
    formData.append('note', data.note)
    formData.append('files', data.files.map(f => {
      return f.name
    }))

    // data.files.forEach(async f => {
    //   console.log(f)
    //   // console.log(Buffer.from(f))

    //   // const formData = new FormData()
    //   formData.append('file', f.name)
    //   // formData.append('fileName', f.name)
    //   // await putDataToAPI(`/${f.name}`, formData, {
    //   //   'content-type': f.type,
    //   // })
    // })

    const details = await postDataToAPI(`/dfs/transaction/${data.id}/dispute`, data, headers)
    // console.log(details)
    if (details !== undefined && details.status === 200) {
      // console.log(details.data)
      const transaction = details.data
      const evidence = transaction.evidence
      delete transaction.evidence

      // dispatch({
      //   type: 'UPDATE_TRANSACTION',
      //   payload: {
      //     transaction
      //   },
      // })
      // data.files.map(f => {
      //   return f.name
      // })

      for (const file of data.files) {
        console.log({ file })
        // Pirate%20Jack%20-%20laser.lbrn2
        const url = evidence.filter(u => u.includes(file.name.replaceAll(' ', '%20')))
        console.log(file.name + ' : ' + url)

        // const binary = atob(file.split(',')[1])
        // console.log({ binary })
        // let blobData = new Blob([new Uint8Array(array)], {type: 'image/jpeg'})

        // const result = await fetch(url, {
        //   method: 'PUT',
        //   body: file,
        //   headers: {
        //     'content-type': file.type
        //   }
        // })
        await putDataToAPI(url, file, {
          'content-type': file.type,
        })
      }
    }
  }

  const createNewDispute = async (data) => {
    // console.log(data)

    const input = {
      message: data.message
    }

    input.files = data.files.map(f => {
      return {
        name: f.name,
        type: f.type
      }
    })
    // console.log(input)

    const details = await postDataToAPI(`/dfs/transaction/${data.id}/dispute`, input)
    // console.log(details)
    if (details !== undefined && details.status === 200) {
      // console.log(details.data)
      const transaction = details.data
      const evidence = transaction.evidence
      delete transaction.evidence

      dispatch({
        type: 'UPDATE_TRANSACTION',
        payload: {
          transaction
        },
      })
      // for (const file of data.files) {
      //   console.log({ file })
      //   // Pirate%20Jack%20-%20laser.lbrn2
      //   const url = evidence.filter(u => u.includes(file.name.replaceAll(' ', '%20')))
      //   console.log(file.name + ' : ' + url)

      //   // const binary = atob(file.split(',')[1])
      //   // console.log({ binary })
      //   // let blobData = new Blob([new Uint8Array(array)], {type: 'image/jpeg'})

      //   // const result = await fetch(url, {
      //   //   method: 'PUT',
      //   //   body: file,
      //   //   headers: {
      //   //     'content-type': file.type
      //   //   }
      //   // })
      //   await putDataToAPI(url, file, {
      //     'content-type': file.type,
      //   })
      // }

      for (const e of evidence) {
        console.log(e)
        console.log(e.file)

        const file = data.files.filter(f => f.name === e.file)
        console.log(file)

        // const formData = new FormData()
        // formData.append('file', file)
        // console.log(formData)

        await putDataToAPI(e.url, file)

        // console.log(e.url.url)
        // console.log(e.url.fields)
        // const form = new FormData()
        // Object.entries(e.url.fields).forEach(([field, value]) => {
        //   form.append(field, value);
        // })
        // form.append('file', file)
        // // form.submit(e.url.url, (err, res) => {
        // //   console.log(res)
        // //   console.log(err)
        // // })
        // await putDataToAPI(e.url.url, form)
      }
    }
  }

  const sharedState = {
    partnerState,
    getGallonsPerDay,
    initData,
    deleteContact,
    getTransactions,
    getBillingReports,
    createNewContact,
    updateContact,
    createNewToken,
    deleteToken,
    updateWebhook,
    createNewWebhook,
    deleteWebhook,
    submitDispute,
    createNewDispute,
    getReservations
  }

  return (
    <PartnerContext.Provider
      value={sharedState}
    >
      {children}
    </PartnerContext.Provider>
  )
}

PartnerProvider.propTypes = { children: PropTypes.node }

export const usePartnerContext = () => useContext(PartnerContext)

export default PartnerProvider
