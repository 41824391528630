import React from 'react'
import {
  Col,
  Row
} from 'react-bootstrap'

// Commenting out for use at a later date
// import Subscriptions from './Subscriptions'
// import DangerZone from './DangerZone'
import ProfileSettings from './ProfileSettings'
import AccountSettings from './AccountSettings'

const Profile = () => {
  return (
    <>
      {/* <ProfileBanner /> */}
      <Row className="g-3">
        <Col lg={8}>
          <ProfileSettings />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            {/* // Commenting out for use at a later date */}
            {/* <Subscriptions /> */}
            <AccountSettings />
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Profile
