export const initialState = {
  notifications: [
    // {
    //   id: 1,
    //   avatar: {
    //     src: team1,
    //     size: '2xl'
    //   },
    //   children: 'Retail Price confirmation',
    //   time: '2022-10-24T18:53:19.042Z',
    //   className: 'rounded-0 border-x-0 border-300 border-bottom-0',
    //   to: '#!',
    //   unread: true
    // },
    // {
    //   id: 2,
    //   avatar: {
    //     name: 'Albert Brooks',
    //     size: '2xl'
    //   },
    //   children:
    //     "<strong>Albert Brooks</strong> reacted to <strong>Mia Khalifa's</strong> status",
    //   time: '2011-10-05T14:48:00.000Z',
    //   className: 'rounded-0 border-x-0 border-300 border-bottom-0',
    //   to: '#!',
    //   unread: true
    // }
  ],
  unreadNotifications: [],
}

export const NotificationReducer = (initialState, action) => {
  const { type, payload } = action
  // Leaving for future Debug
  // console.log(type)
  // console.log(payload)
  let updated

  switch (type) {
    case 'INIT_NOTIFICATIONS':
      return {
        ...initialState,
        notifications: payload.notifications.sort(sortCreatedAtDesc),
        unreadNotifications: payload.notifications.filter(filterUnread).sort(sortCreatedAtDesc)
      }
    case 'UPDATE_NOTIFICATION':
      updated = initialState.notifications.map(n => {
        if (n.id === payload.notification.id) {
          return payload.notification
        }
        return n
      }).sort(sortCreatedAtDesc)

      return {
        ...initialState,
        notifications: updated,
        unreadNotifications: updated.filter(filterUnread).sort(sortCreatedAtDesc)
      }
    case 'ADD_NOTIFICATION':
      return {
        ...initialState,
        notifications: initialState.notifications.concat([payload.notification]).sort(sortCreatedAtDesc),
        unreadNotifications: initialState.notifications.concat([payload.notification]).filter(filterUnread).sort(sortCreatedAtDesc)
      }
    case 'DELETE_NOTIFICATION':
      updated = initialState.notifications.filter(n => {
        if (n.id !== payload.notification.id) {
          return true
        }
        return false
      }).sort(sortCreatedAtDesc)

      return {
        ...initialState,
        notifications: updated,
        unreadNotifications: updated.filter(filterUnread).sort(sortCreatedAtDesc)
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const sortCreatedAtDesc = (a, b) => {
  if (a.createdAt > b.createdAt) {
    return -1;
  }
  if (a.createdAt < b.createdAt) {
    return 1;
  }

  // names must be equal
  return 0;
}

const filterUnread = n => {
  if (!n.read) {
    return true
  }
  return false
}
