import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Card, Col, Row, ProgressBar } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Flex from 'components/common/Flex'

import { usePartnerContext } from 'providers/PartnerProvider';

const colors = {
  Completed: 'gradient',
  Pending: 'warning',
  Canceled: 'info',
  Expired: 'danger',
  Voided: 'secondary',
  Active: 'success'
}

const DfsDailySummary = () => {
  const { partnerState } = usePartnerContext()
  const [data, setData] = useState([])
  const [total, setTotal] = useState({})
  const [free, setFree] = useState({})

  useEffect(() => {
    const dfsLocData = partnerState.dfs_status
      // .sort((a, b) => a.name.localeCompare(b.name))
      .map((l, i) => {
        return {
          ...l,
          color: colors[l.name],
        }
      })
    // console.log(dfsLocData)
    setData(dfsLocData)
  }, [partnerState.dfs_status])

  useEffect(() => {
    if (typeof data === 'object' && data !== undefined && data !== null && Object.keys(data).length > 0) {
      const total = data
        .map(d => d.value)
        .reduce((total, currentValue) => total + currentValue, 0)
      setTotal(total)

      // const free = data.find(d => d.name === 'Incomplete').value
      // setFree(free)
    }
  }, [data])

  return (
    <Card className='h-lg-100'>
      <Card.Body as={Flex} alignItems="center">
        <div className="w-100">
          <h6 className="mb-3 text-800">
            Daily DFS Transaction Status
            {/* <strong className="text-1100">
              {totalStorage - freeStorage} {' '}
            </strong>
            of {Math.round(totalStorage)} */}
          </h6>
          <ProgressBar
            style={{ height: 10 }}
            className="shadow-none rounded-4 mb-3"
          >
            {data.map((status, index) => (
              <ProgressBar
                // variant={status.color}
                variant={`${status.color?.split('-')[1] || status.color}`}
                now={(status.value * 100) / total}
                key={status.name}
                className={classNames('overflow-visible position-relative', {
                  'rounded-end rounded-pill': index === 0,
                  'rounded-start rounded-pill': index === data.length - 1,
                  'border-end border-100 border-2': index !== data.length - 1,
                  'rounded-0': index !== data.length - 1 && index !== 0
                })}
              />
            ))}
          </ProgressBar>
          <Row className="fs-10 fw-semibold text-500">
            {data.map(status => (
              <Col
                xs={6}
                sm="auto"
                as={Flex}
                alignItems="center"
                className="pe-2"
                key={status.name}
              >
                <span
                  className={`dot bg-${status.color === 'gradient' ? 'primary' : status.color
                    }`}
                ></span>

                <span>{status.name}</span>
                <span className="d-none d-md-inline-block d-xxl-inline-block ms-1">
                  ({status.value})
                </span>
              </Col>
            ))}
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
}

export default DfsDailySummary
