import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  ListGroup,
  ButtonGroup,
  Button,
} from 'react-bootstrap'
import PageHeader from 'components/common/PageHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import WebhookModal from './WebhookModal'
import WebhookDeleteModal from './WebhookDeleteModal'
import { usePartnerContext } from 'providers/PartnerProvider'
import IconButton from 'components/common/IconButton'
import { capitalize } from 'helpers/utils'
import { useNavigate } from 'react-router-dom'
import { useAccountContext } from 'providers/AccountProvider'

const Webhooks = () => {
  const navigate = useNavigate()
  const { checkAccess } = useAccountContext()
  const { partnerState } = usePartnerContext()
  const [webhooks, setWebhooks] = useState([])

  useEffect(() => {
    setWebhooks(partnerState.webhooks)
  }, [partnerState.webhooks])

  const [activeData, setActiveData] = useState(null)
  const [showNewWebhookModal, setShowNewWebhookModal] = useState(false)
  const [showEditWebhookModal, setShowEditWebhookModal] = useState(false)
  const [showDeleteWebhookModal, setShowDeleteWebhookModal] = useState(false)
  const [currentWebhook, setCurrentWebhook] = useState({})

  useEffect(() => {
    if (!checkAccess(4)) {
      navigate('/')
    }
  }, [])

  useEffect(() => {
    if (!showEditWebhookModal) {
      setCurrentWebhook({})
    }
  }, [showEditWebhookModal])

  const handleEdit = row => {
    setCurrentWebhook(row)
    setShowEditWebhookModal(true)
  }

  const handleDelete = row => {
    // console.log(row.id)
    setCurrentWebhook(row)
    setShowDeleteWebhookModal(true)
  }

  const getEnvVariant = env => {
    switch (env) {
      case 'dev':
      case 'test':
        return 'warning'
      case 'prod':
        return 'info'
    }
  }

  return (
    <>
      <WebhookModal show={showNewWebhookModal} hide={() => setShowNewWebhookModal(false)} />
      <WebhookModal type='edit' webhook={currentWebhook} show={showEditWebhookModal} hide={() => setShowEditWebhookModal(false)} />
      <WebhookDeleteModal webhook={currentWebhook} show={showDeleteWebhookModal} hide={() => setShowDeleteWebhookModal(false)} />

      <PageHeader
        title='Webhooks'
        description=""
        className='mb-3'
        icon={['regular', 'webhook']}
      />
      <Row className="mb-3">
        <Col xs="auto" sm={6}>
          <IconButton
            variant="falcon-default"
            size="sm"
            icon="plus"
            transform="shrink-3"
            onClick={() => setShowNewWebhookModal(true)}
          >
            <span className="d-none d-sm-inline-block ms-1">
              New Webhook
            </span>
          </IconButton>
        </Col>
      </Row>

      <ListGroup>
        {
          webhooks.map((item, i) => {
            return (
              <ListGroup.Item key={i}>
                <Row>
                  <Col xs={9}>
                    <FontAwesomeIcon
                      icon={item.icon}
                      size={item.iconSize}
                      // className={`pe-2 ${item.iconColor}`}
                      className={classNames('pe-2',
                        { 'text-warning': item.last_status?.code !== 200 },
                        { 'text-success': item.last_status?.code === 200 },
                      )}
                    />
                    <a href={item.address}>{item.address}</a>
                  </Col>
                  <Col className='d-flex justify-content-end'>

                    {/* <ButtonGroup> */}
                    <IconButton
                      variant="falcon-primary"
                      size="sm"
                      icon="pencil"
                      transform="shrink-3"
                      onClick={() => handleEdit(item)}
                    >
                      <span className="d-none d-sm-inline-block ms-1">
                        Edit
                      </span>
                    </IconButton>

                    <IconButton
                      variant="falcon-danger"
                      size="sm"
                      icon="minus"
                      transform="shrink-3"
                      onClick={() => handleDelete(item)}
                    >
                      <span className="d-none d-sm-inline-block ms-1">
                        Delete
                      </span>
                    </IconButton>
                    {/* </ButtonGroup> */}
                  </Col>
                </Row>
                <Row>
                  <Col className='fs--2 p-0 ps-4'>
                    {item.statusText}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ListGroup horizontal className='fs-10'>
                      <ListGroup.Item variant='secondary' className='fs--1 mt-2'>Subscription:</ListGroup.Item>
                      {
                        item.event && item.environment
                          ? <ListGroup.Item
                            key={i}
                            className='fs--2 mt-2'
                            variant={getEnvVariant(item.environment.value)}
                          >
                            {capitalize(item.environment.label)}: {item.event.label}
                          </ListGroup.Item>
                          : ''
                      }
                    </ListGroup>
                  </Col>
                </Row>
              </ListGroup.Item>
            )
          })
        }
      </ListGroup>

    </>
  )
}

export default Webhooks
