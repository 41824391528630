import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
  Row,
  Col,
  Card,
  ListGroup,
} from 'react-bootstrap'
import { usePartnerContext } from 'providers/PartnerProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { webhookEvents, webhookEnvironments } from 'config'
import * as yup from 'yup'
import { TextField, SelectField } from 'components/common/FormHelper'
import { Formik, Field, Form } from 'formik'

const WebhookModal = ({ show, hide, hasLabel = true, type = 'new', webhook }) => {
  const { updateWebhook, createNewWebhook } = usePartnerContext()

  const defaultData = {
    address: '',
    event: '',
    environment: '',
    id: '',
  }

  const handleSubmit = async (values, bag) => {
    console.log(values)
    if (type === 'new') {
      await createNewWebhook(values)
    } else {
      await updateWebhook(values)
    }
    hide()
  }

  const schema = yup.object().shape({
    address: yup.string().required('This is a required field.').url('Must be a valid URL address.'),
    event: yup.object().required('This is a required field.'),
    environment: yup.object().required('This is a required field.'),
  })

  return (
    <Modal centered show={show} onHide={hide} size={'lg'}>
      <Modal.Header closeButton>
        <Modal.Title>{type === 'new' ? 'Add New Webhook' : 'Edit Webhook'}</Modal.Title>
      </Modal.Header>

      <Formik
        initialValues={webhook !== undefined ? webhook : defaultData}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ isValid, isSubmitting, dirty }) => {
          return (
            <Form>
              <Modal.Body>
                <Row>
                  <Col>
                    <TextField
                      hasLabel
                      title='Webhook Address'
                      name='address'
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <SelectField
                      hasLabel
                      title='Event'
                      name='event'
                      options={webhookEvents}
                    />
                    <Card>
                      <Card.Body>
                        <Card.Title>Event Availability</Card.Title>
                        <ListGroup variant='flush'>
                          {webhookEvents.map((w, index) => (
                            <ListGroup.Item key={index}
                              as='li'
                              className='d-flex justify-content-between align-items-start'
                            >
                              <div className='ms-2 me-auto fs-10'>
                                <div className='fw-bold'>{w.label}</div>
                                {w.help}
                              </div>
                            </ListGroup.Item>

                          ))}
                        </ListGroup>

                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={6}>
                    <SelectField
                      hasLabel
                      title='Environment'
                      name='environment'
                      options={webhookEnvironments}
                    />
                    <Card>
                      <Card.Body>
                        <Card.Title>Environment Information</Card.Title>
                        <ListGroup variant='flush'>
                          {webhookEnvironments.map((w, index) => (
                            <ListGroup.Item key={index}
                              as='li'
                              className='d-flex justify-content-between align-items-start'
                            >
                              <div className='ms-2 me-auto fs-10'>
                                <div className='fw-bold'>{w.name}</div>
                                {w.help}
                              </div>
                            </ListGroup.Item>

                          ))}
                        </ListGroup>

                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={hide}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type='submit'
                  disabled={!isValid || !dirty}
                >
                  {isSubmitting
                    ? <FontAwesomeIcon
                      icon='circle-notch'
                      className='me-1 fa-spinner fa-spin'
                    />
                    : ''
                  }
                  {' '}
                  {type === 'new' ? 'Save New Webhook' : 'Update Webhook'}
                </Button>
              </Modal.Footer>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default WebhookModal

WebhookModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  hasLabel: PropTypes.bool,
  type: PropTypes.string,
  webhook: PropTypes.object,
}
