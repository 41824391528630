import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Col, Form, Row, Modal } from 'react-bootstrap'
import { formatDateTime, formatDollars } from 'helpers/utils'
import { dateTimeOptions } from 'helpers/formater'
import { getDataFromAPI } from 'helpers/api'
import PageHeader from 'components/common/PageHeader'
import FalconComponentCard from 'components/common/FalconComponentCard'
import parse, { attributesToProps } from 'html-react-parser'

const DFSBillPage = () => {
  const { id } = useParams()
  const [reportId, setReportId] = useState(id)
  const [reportDate, setReportDate] = useState(false)
  const [loading, setLoading] = useState(true)
  const [reportData, setReportData] = useState([])

  const fetchBill = async () => {
    const data = await getDataFromAPI('/report/' + reportId)
    if (data !== undefined && data.status === 200) {
      const parsed = parse(data.data.body, {
        replace (domNode) {
          if (domNode.attribs && domNode.name === 'tr') {
            domNode.attribs.style = {}
            return domNode
          }
          if (domNode.attribs && domNode.name === 'th') {
            const attribs = attributesToProps(domNode.attribs)
            attribs.style.border = ''
            attribs.style['background-color'] = ''
            domNode.attribs.style = attribs.style
            return domNode
          }
          if (domNode.attribs && domNode.name === 'table' && domNode.attribs.class === 'dfs_data_table') {
            domNode.attribs.class = 'table table-bordered table-striped'
            return domNode
          }
          if (domNode.attribs && domNode.name === 'thead') {
            domNode.attribs.class = 'bg-200'
            return domNode
          }
        },
      })

      setReportData(parsed)
      setReportDate(formatDateTime(data.data.date))
    }
  }

  useEffect(() => {
    if (reportId !== null && reportId !== undefined) {
      setLoading(true)
      fetchBill()
    }
  }, [reportId])

  return (
    <>
      <PageHeader
        title='DFS Billing Invoice'
        description=""
        className='mb-3'
      />
      <FalconComponentCard>
        <FalconComponentCard.Header noPreview>
          The following report was sent on: {reportDate}
        </FalconComponentCard.Header>
        <FalconComponentCard.Body>
          <div>{reportData}</div>
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  )
}

export default DFSBillPage
