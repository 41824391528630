import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
} from 'react-bootstrap'
import { useSystemAdminContext } from 'providers/SystemAdminProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { permissionOptions } from 'config'
import * as yup from 'yup'
import { TextField, PhoneTextField, MultiSelectField } from 'components/common/FormHelper'
import { Formik, Field, Form } from 'formik'

const UserModal = ({ show, hide, hasLabel = true, type = 'new', user }) => {
  const { updateUser, createNewUser } = useSystemAdminContext()
  const [assignablePermissions, setAssignablePermissions] = useState([])

  useEffect(() => {
    setAssignablePermissions(permissionOptions.filter(p => !p.restricted))
  }, [])

  const defaultData = {
    email: '',
    name: '',
    phone_number: '',
    permissions: [],
    id: '',
  }

  const handleSubmit = async (values, bag) => {
    // console.log(values)
    if (type === 'new') {
      await createNewUser(values)
    } else {
      await updateUser(values)
    }
    hide()
  }

  const schema = yup.object().shape({
    email: yup.string().email('Please provide a valid email address.').required('This is a required field.'),
    name: yup.string().required('This is a required field.'),
    phone_number: yup.string().nullable(true), // .phone('US', 'Please provide a valid phone number'),
    permissions: yup.array()
  })

  return (
    <Modal centered show={show} onHide={hide} size={'xl'}>
      <Modal.Header closeButton>
        <Modal.Title>{type === 'new' ? 'Add New User' : 'Edit User'}</Modal.Title>
      </Modal.Header>

      <Formik
        initialValues={user !== undefined ? user : defaultData}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ isValid, isSubmitting, dirty }) => {
          return (
            <Form>
              <Modal.Body>
                <TextField
                  hasLabel
                  title='Email Address'
                  name='email'
                />
                <TextField
                  hasLabel
                  title='Name'
                  name='name'
                />
                <PhoneTextField
                  hasLabel
                  title='Phone Number'
                  name='phone_number'
                />
                <MultiSelectField
                  hasLabel
                  title='Permissions'
                  name='permissions'
                  options={assignablePermissions}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={hide}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type='submit'
                  disabled={!isValid || !dirty}
                >
                  {isSubmitting
                    ? <FontAwesomeIcon
                      icon='circle-notch'
                      className='me-1 fa-spinner fa-spin'
                    />
                    : ''
                  }
                  {' '}
                  {type === 'new' ? 'Save New User' : 'Update User'}
                </Button>
              </Modal.Footer>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default UserModal

UserModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  hasLabel: PropTypes.bool,
  type: PropTypes.string,
  user: PropTypes.object,
}
