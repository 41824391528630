import React, { useState } from 'react'
import PropTypes from 'prop-types'
import LoginForm from './LoginForm'
import Flex from 'components/common/Flex'

import AuthCardLayout from 'layouts/AuthCardLayout'
import PasswordResetForm from './PasswordResetForm'

const Login = () => {
  const [passwordRequired, setPasswordRequired] = useState(false)
  const [user, setUser] = useState(null)
  return (
    <AuthCardLayout
    // leftSideContent={
    //   <p className="text-white">
    //     Don't have an account?
    //     <br />
    //     <Link
    //       className="text-white text-decoration-underline"
    //       to={paths.cardRegister}
    //     >
    //       Get started!
    //     </Link>
    //   </p>
    // }
    >
      <h3>Account Login</h3>
      {
        !passwordRequired
          ? <LoginForm
            layout="card"
            hasLabel
            chgPass={
              user => {
                setPasswordRequired(true)
                setUser(user)
              }
            }
          />
          : <PasswordReset user={user} />
      }
    </AuthCardLayout>
  )
}

const PasswordReset = ({ hasLabel = false, user }) => (
  <>
    <Flex justifyContent='between' alignItems='center' className='mt-2'>
      <h6>Your account has been put into administrator reset.  Please update your password below:</h6>
    </Flex>
    <PasswordResetForm user={user} />
  </>
)

PasswordReset.propTypes = {
  user: PropTypes.object,
  hasLabel: PropTypes.bool
}

export default Login
