import React from 'react'
import PageHeader from 'components/common/PageHeader'
import { Card } from 'react-bootstrap'
// import { getConfluenceSlugContent } from 'helpers/confluenceContent'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

const TermsOfServicePage = () => {
  const content = `
  ##### Introduction
The purpose of these terms of service are to ensure all participating parties understand the intent and permissions of the product in discussion. Please read these terms of service (“Terms”, “Terms of Service”) carefully before using the Partner Portal (“Product”) available at partner-portal.dfs.cx, operated by {SKR3L, LLC DBA Roady's Truck Stops, Miss LLC DBA Professional Transportation Partners, LLC (PTP)} ( “We”, “Our”).

Product users (“User”, “you”) are clients invited, by either by SKR3L, LLC DBA Roady's Truck Stops, MISS LLC DBA Professional Transportation Partners, LLC (PTP), another user, or an Operator affiliate, to create an account.

This latest draft is effective 1 November 2024.
##### Conditions of Use
SKR3L, LLC ; MISS, LLC will provide access to the product, which is subject to the conditions stated below in this document. As a user, you accept the following conditions. This is why we urge you to read them carefully.
##### User Account
In order to use certain features of this product, users may be required to create an account. Users must provide accurate and complete information when creating an account.
Users must be of legal age to enter into a binding contract in their jurisdiction in order to create an account.

With regard to the product, as the user, you are solely responsible for maintaining the confidentiality of your private user login details (username and password). You are responsible for all activities that occur under your account or password.
##### Payments
You, as the user, may make payments for items and services through the Partner Portal using the payment methods available within the product.
All payments made through this product are subject to the terms and conditions of the respective payment methods. Users are responsible for any fees or charges associated with their use of payment methods.
##### User Conduct
You, as the user, agree not to use the App for any unlawful or unauthorized purpose by using this product. You also agree not to interfere with or disrupt the product or servers or networks connected to this product. Finally, you agree not to attempt to gain unauthorized access to any portion of our product.
##### Privacy Policy
Before you continue using our product, We advise you to read the [privacy policy](/privacy) regarding its user data collection. This will help you better understand our practices.
##### Copyright
Content published on this product (digital downloads, images, texts, graphics, logos, and transaction data) is the property of SKR3L, LLC ; MISS, LLC and protected by international copyright laws. The entire compilation of the content found on this product is the exclusive property of SKR3L, LLC ; MISS, LLC, with copyright authorship for this compilation by SKR3L, LLC ; MISS, LLC.
##### Communications
The entire communication with SKR3L, LLC ; MISS, LLC is electronic. Every time you send SKR3L, LLC ; MISS, LLC an email or use the product, you are going to be communicating with us. You hereby consent to receive communications from SKR3L, LLC ; MISS, LLC. If you subscribe to the product, you are going to receive communications via the product notifications or emails. We will continue to communicate with you in this manner. You also agree that all notices, disclosures, agreements, and other communications SKR3L, LLC ; MISS, LLC provides to you electronically meet the legal requirements that such communications be in writing.
##### Limitation of Liability
SKR3L, LLC ; MISS, LLC shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from
(a) users' access to or use of or inability to access or use our product;
(b) any conduct or content of any third party on our product.
##### Applicable Law
By using this product, you agree that the laws of the State of Idaho, United States, without regard to principles of conflict laws, will govern these terms of service, or any dispute of any sort that might come between SKR3L, LLC ; MISS, LLC and you, or its business partners and associates.

By using this product, you agree that you are responsible for complying with your local laws when accessing and utilizing this product.
##### Disputes
Any dispute related in any way to these products used from SKR3L, LLC ; MISS, LLC shall be arbitrated by state or federal court in the State of Idaho, United States, and you consent to exclusive jurisdiction and venue of such courts.
##### License and Site Access
As a licensed user you are granted limited access to make appropriate use of the product for which it was intended for by SKR3L, LLC ; MISS, LLC
##### Termination of Accounts
SKR3L, LLC ; MISS, LLC reserves all rights to terminate accounts, edit or remove content at its sole discretion.


  ` // getConfluenceSlugContent('terms-of-service')

  return (
    <>
      <PageHeader
        title='Terms of Service'
        description=""
        className='mb-3'
      />

      <Card>
        <Card.Body>
          <Markdown rehypePlugins={[rehypeRaw]}>
            {content}
          </Markdown>
        </Card.Body>
      </Card>
    </>
  )
}

export default TermsOfServicePage
