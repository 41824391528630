import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
  Row,
  Col,
} from 'react-bootstrap'
import { usePartnerContext } from 'providers/PartnerProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as yup from 'yup'
import { TextField } from 'components/common/FormHelper'
import { Formik, Field, Form } from 'formik'

const WebhookModal = ({ show, hide, hasLabel = true, type = 'new', webhook }) => {
  const { createNewToken } = usePartnerContext()

  const defaultData = {
    name: '',
  }

  const handleSubmit = async (values, bag) => {
    await createNewToken(values)
    hide()
  }

  const schema = yup.object().shape({
    name: yup.string().required('This is a required field.'),
  })

  return (
    <Modal centered show={show} onHide={hide} size={'lg'}>
      <Modal.Header closeButton>
        <Modal.Title>Add New API Token</Modal.Title>
      </Modal.Header>

      <Formik
        initialValues={webhook !== undefined ? webhook : defaultData}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ isValid, isSubmitting, dirty }) => {
          return (
            <Form>
              <Modal.Body>
                <Row>
                  <Col>
                    <TextField
                      hasLabel
                      title='Name'
                      name='name'
                    />
                  </Col>
                </Row>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={hide}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type='submit'
                  disabled={!isValid || !dirty}
                >
                  {isSubmitting
                    ? <FontAwesomeIcon
                      icon='circle-notch'
                      className='me-1 fa-spinner fa-spin'
                    />
                    : ''
                  }
                  {' '}
                  Save New Token
                </Button>
              </Modal.Footer>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default WebhookModal

WebhookModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  hasLabel: PropTypes.bool,
  type: PropTypes.string,
  webhook: PropTypes.object,
}
