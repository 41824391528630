// let cardDetails = localStorage.getItem('card_details')
//   ? JSON.parse(localStorage.getItem('card_details'))
//   : null

// let cardHolder = localStorage.getItem('card_holder')
//   ? JSON.parse(localStorage.getItem('card_holder'))
//   : null

// let cardBalance = localStorage.getItem('card_balance')
//   ? JSON.parse(localStorage.getItem('card_balance'))
//   : null

// let cardHistory = localStorage.getItem('card_history')
//   ? JSON.parse(localStorage.getItem('card_history'))
//   : null

const profileData = localStorage.getItem('profile_data')
  ? JSON.parse(localStorage.getItem('profile_data'))
  : null

// console.log(profile_data.hasOwnProperty('data_failure'))

// const fetchData = (key) => {
//   // if (profile_data === null && key === 'data_failure')
//   // {
//   //   return false
//   // }
//   if (profile_data === null)
//   {
//     return null
//   }

//   if (profile_data.hasOwnProperty('data_failure'))
//   {
//     return profile_data.data_failure
//   }

//   return profile_data.key
// }

export const initialState = {
  data_failure: false,
  card_history: null,
  closest_location_amenities: null,
  user_profile: profileData?.user_profile,
}

export const ProfileReducer = (initialState, action) => {
  const { type, payload } = action
  // console.log(type)
  // console.log(payload)
  let updated

  switch (type) {
    case 'UPDATE_PROFILE':
      return {
        ...initialState,
        ...payload,
      }
    case 'UPDATE_USER_PROFILE':
      return {
        ...initialState,
        user_profile: payload.user_profile,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
