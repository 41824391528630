import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import PageHeader from 'components/common/PageHeader'
import DfsGallonsPerDay from './DfsGallonsPerDay'
import DfsGallonsPerMonth from './DfsGallonsPerMonth'
import DfsLocations from './DfsLocations'
import DfsDailySummary from './DfsDailySummary'

const Dashboard = () => {
  return (
    <>
      <PageHeader
        title='Dashboard'
        description=""
        className='mb-3'
      />
      <Row className={'g3 mb-3'}>
        <Col lg={9}>
          <DfsGallonsPerDay />
        </Col>
        <Col>
          <Row className="g-3">
            <Col md={12}>
              <DfsLocations />
            </Col>
            <Col md={12}>
              <DfsDailySummary />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className={'g3 mb-3'}>
        <Col lg={12}>
          <DfsGallonsPerMonth />
        </Col>
      </Row>

    </>
  )
}

export default Dashboard
