import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
} from 'react-bootstrap'
import { usePartnerContext } from 'providers/PartnerProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const WebhookDeleteModal = ({ show, hide, webhook }) => {
  const { deleteWebhook } = usePartnerContext()
  const [isSaving, setSaving] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSaving(true)
    // console.log(formData)
    await deleteWebhook(webhook.id)
    setSaving(false)
    hide()
  }

  return (
    <Modal centered show={show} onHide={hide} size={'xl'}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Webhook</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        Are you certain you want to delete the following webhook?
        <ul>
          <li>{webhook.address}</li>
        </ul>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="danger"
          onClick={handleSubmit}
        >
          {isSaving
            ? <FontAwesomeIcon
              icon='circle-notch'
              className='me-1 fa-spinner fa-spin'
            />
            : ''
          }
          {' '}
          Yes
        </Button>
        <Button variant="secondary" onClick={hide}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default WebhookDeleteModal

WebhookDeleteModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  webhook: PropTypes.object,
}
