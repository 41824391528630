import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
} from 'react-bootstrap'

import { usePartnerContext } from 'providers/PartnerProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const TokenDeleteModal = ({ show, hide, token }) => {
  const { deleteToken } = usePartnerContext()
  const [isSaving, setSaving] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSaving(true)
    // console.log(token.id)
    await deleteToken(token.id)
    setSaving(false)
    hide()
  }

  return (
    <Modal centered show={show} onHide={hide} size={'xl'}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Token</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        Are you certain you want to delete the following API Key?
        <ul>
          <li>{token.name}</li>
        </ul>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="danger"
          onClick={handleSubmit}
        >
          {isSaving
            ? <FontAwesomeIcon
              icon='circle-notch'
              className='me-1 fa-spinner fa-spin'
            />
            : ''
          }
          {' '}
          Yes
        </Button>
        <Button variant="secondary" onClick={hide}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default TokenDeleteModal

TokenDeleteModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  token: PropTypes.object,
}
