import React, { useContext, useEffect, useState } from 'react'
import PageHeader from 'components/common/PageHeader'
import {
  Button,
  Nav,
  Card,
} from 'react-bootstrap'
import { AppContext } from 'providers/AppProvider'
import { useProfileContext } from 'providers/ProfileProvider'
import { useSystemAdminContext } from 'providers/SystemAdminProvider'
import { useAccountContext } from 'providers/AccountProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UserModal from './UserModal'
import UserDeleteModal from './UserDeleteModal'
import UserPasswordChangeModal from './UserPasswordResetModal'
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import { minCellFormater } from 'components/common/advance-table/AdvanceTableHelper'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter'
import useAdvanceTable from 'hooks/useAdvanceTable'
import AdvanceTableProvider from 'providers/AdvanceTableProvider'
import IconButton from 'components/common/IconButton'
import { useNavigate } from 'react-router-dom'

import parsePhoneNumber from 'libphonenumber-js'

const UserManagementPage = () => {
  const navigate = useNavigate()
  const {
    config: { isDark }
  } = useContext(AppContext)
  const { profileState, updateUser } = useProfileContext()
  const { systemAdminState } = useSystemAdminContext()
  const { checkAccess } = useAccountContext()
  const [users, setUsers] = useState([])
  const [noDataComponent, setNoDataComponent] = useState(<div className='p-4'>There are no records to display</div>)
  const [showNewUserModal, setShowNewUserModal] = useState(false)
  const [showEditUserModal, setShowEditUserModal] = useState(false)
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false)
  const [showUserPasswordChangeModal, setShowUserPasswordChangeModal] = useState(false)
  const [currentUser, setCurrentUser] = useState({})
  const [allLocations, setAllLocations] = useState([])

  useEffect(() => {
    if (!checkAccess(1)) {
      navigate('/')
    }
  }, [])

  useEffect(() => {
    // console.log(profileState)
    if (Object.prototype.hasOwnProperty.call(profileState, 'user_profile')) {
      // setUserProfileAttribs(profileState.user_profile)
    }
  }, [profileState])

  useEffect(() => {
    // console.log(systemAdminState.users)
    setUsers(systemAdminState.users)
  }, [systemAdminState.users])

  useEffect(() => {
    if (!showEditUserModal) {
      setCurrentUser({})
    }
  }, [showEditUserModal])

  const handleEdit = row => {
    // console.log(row)
    setCurrentUser(row)
    setShowEditUserModal(true)
  }

  const handleDelete = row => {
    // console.log(row.id)
    setCurrentUser(row)
    setShowDeleteUserModal(true)
  }

  const handlePasswordReset = row => {
    // console.log(row.id)
    setCurrentUser(row)
    setShowUserPasswordChangeModal(true)
  }

  const columns = [
    {
      header: 'Name',
      accessorKey: 'name',
      cell: minCellFormater,
    },
    {
      header: 'Email',
      accessorKey: 'email',
      cell: minCellFormater,
    },
    {
      header: 'Phone',
      accessorKey: 'phone_number',
      cell: (rowData) => {
        const row = rowData.row.original
        // eslint-disable-next-line
        const { phone_number } = rowData.row.original
        const phoneNumber = parsePhoneNumber(phone_number, 'US')
        return <h6 className="mb-0">{phoneNumber.formatNational()}</h6>
      }
    },
    {
      header: 'Permissions',
      accessorKey: 'permissions',
      // cell: minCellFormater,
      cell: (rowData) => {
        const { permissions } = rowData.row.original
        return permissions.map((p, i) => {
          return <h6 key={i} className="mb-0">{p.label}</h6>
        })

        // if (permissions > 0) {
        //   return <h6 className="mb-0">{
        //     permissionOptions.flatMap((c) => {
        //       if (c.value & permissions) {
        //         return <h6 className="mb-0">{c.label}</h6>
        //       }
        //       return []
        //     })
        //   }</h6>
        // }
        // return ''
      }
    },
    {
      header: '',
      accessorKey: 'actions',
      enableSorting: false,
      meta: {
        cellProps: {
          className: 'text-end',
          width: '105px',
        }
      },
      cell: (rowData) => {
        const row = rowData.row.original
        return (
          <>
            <Button
              type='button'
              variant='warning'
              className='me-1 px-2'
              size='sm'
              onClick={() => handleEdit(row)}
              title='Edit User'
            >
              <FontAwesomeIcon icon='edit' />
            </Button>

            <Button
              type='button'
              variant='success'
              className='me-1 px-2'
              size='sm'
              onClick={() => handlePasswordReset(row)}
              title='Change User Password'
            >
              <FontAwesomeIcon icon='key' />
            </Button>

            <Button
              id={row.id}
              type='button'
              variant='danger'
              className='me-1 px-2'
              size='sm'
              onClick={() => handleDelete(row)}
              title='Delete User'
            >
              <FontAwesomeIcon icon='times-circle' />
            </Button>
          </>
        )
      }
    },
  ]

  const table = useAdvanceTable({
    data: users,
    columns,
    sortable: true,
    pagination: true,
    perPage: 10
  })

  return (
    <>
      <UserModal show={showNewUserModal} hide={() => setShowNewUserModal(false)} />
      <UserModal type='edit' user={currentUser} show={showEditUserModal} hide={() => setShowEditUserModal(false)} />
      <UserDeleteModal user={currentUser} show={showDeleteUserModal} hide={() => setShowDeleteUserModal(false)} />
      <UserPasswordChangeModal user={currentUser} show={showUserPasswordChangeModal} hide={() => setShowUserPasswordChangeModal(false)} />

      <PageHeader
        title='User Management'
        description=""
        className='mb-3'
      />

      <AdvanceTableProvider {...table}>
        <Card className="mb-3">
          <Nav className='m-2' fill>

            <Nav.Item className='text-start'>
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-3"
                onClick={() => setShowNewUserModal(true)}
              >
                <span className="d-none d-sm-inline-block ms-1">
                  New User
                </span>
              </IconButton>
            </Nav.Item>

            <Nav.Item>
              <AdvanceTableSearchBox table />
            </Nav.Item>

          </Nav>
          <Card.Body className="p-0">
            <AdvanceTable
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs-10 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </Card.Footer>
        </Card>
      </AdvanceTableProvider>
    </>
  )
}

export default UserManagementPage
