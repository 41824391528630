import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Button, Form } from 'react-bootstrap'
import TextareaAutosize from 'react-textarea-autosize'
import { usePartnerContext } from 'providers/PartnerProvider'

const formatDate = date => {
  const options = {
    weekday: 'short',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  }

  const now = date
    .toLocaleString('en-US', options)
    .split(',')
    .map(item => item.trim())

  return {
    day: now[0],
    hour: now[3],
    date: now[1] + ', ' + now[2]
  }
}

const MessageTextArea = ({ formData, handleFieldChange, handleSubmit, isProcessing, setIsProcessing, isSubmitable, setIsSubmitable, handleAddAttachment, handleDetachAttachment }) => {
  // const {
  //   messagesDispatch,
  //   messages,
  //   threadsDispatch,
  //   currentThread,
  //   setScrollToBottom,
  //   isOpenThreadInfo
  // } = useChatContext();
  // const [previewEmoji, setPreviewEmoji] = useState(false);
  // const [message, setMessage] = useState('');

  const isDark = true
  // const {
  //   config: { isDark }
  // } = useAppContext();

  // const addEmoji = e => {
  //   let emoji = e.native;
  //   setMessage(message + emoji);
  //   setPreviewEmoji(false);
  // };

  // const handleSubmit = e => {
  //   e.preventDefault();
  //   const date = new Date();
  //   let newMessage = {
  //     senderUserId: 3,
  //     message: `${message.replace(/(?:\r\n|\r|\n)/g, '<br>')}`,
  //     status: 'delivered',
  //     time: formatDate(date)
  //   };

  //   const { content } = messages.find(
  //     ({ id }) => id === currentThread.messagesId
  //   );

  //   if (message) {
  //     messagesDispatch({
  //       type: 'EDIT',
  //       payload: {
  //         id: currentThread.messagesId,
  //         content: [...content, newMessage]
  //       },
  //       id: currentThread.messagesId
  //     });

  //     threadsDispatch({
  //       type: 'EDIT',
  //       payload: currentThread,
  //       id: currentThread.id,
  //       isUpdatedStart: true
  //     });
  //   }
  //   setMessage('');
  //   setScrollToBottom(true);
  // };

  // useEffect(() => {
  //   if (isOpenThreadInfo) {
  //     setPreviewEmoji(false);
  //   }
  // }, [isOpenThreadInfo]);

  // useEffect(() => {
  //   if (formData.message) {
  //     setIsSubmitable(true)
  //   } else {
  //     setIsSubmitable(false)
  //   }
  // }, [formData])

  return (
    <Form className="chat-editor-area" >
      <TextareaAutosize
        minRows={1}
        maxRows={6}
        // value={message}
        value={formData.message}
        placeholder="Type your message"
        // onChange={({ target }) => setMessage(target.value)}
        onChange={handleFieldChange}
        name='message'
        className="form-control outline-none resize-none rounded-0 border-0 emojiarea-editor"
      />

      <Form.Group controlId="chatFileUpload">
        <Form.Label className="chat-file-upload cursor-pointer">
          <FontAwesomeIcon icon="paperclip" />
        </Form.Label>
        <Form.Control
          type="file"
          className="d-none"
          multiple
          onChange={({ target }) => handleAddAttachment(target.files)} />
      </Form.Group>

      {/* <Button
        variant="link"
        className="emoji-icon "
        onClick={() => setPreviewEmoji(!previewEmoji)}
      >
        <FontAwesomeIcon
          icon={['far', 'laugh-beam']}
          onClick={() => setPreviewEmoji(!previewEmoji)}
        />
      </Button> */}

      {/* {previewEmoji && (
        <div className="chat-emoji-picker" dir="ltr">
          <Picker
            set="google"
            // onEmojiSelect={addEmoji}
            theme={isDark ? 'dark' : 'light'}
            previewPosition="none"
            skinTonePosition="none"
          />
        </div>
      )} */}

      <Button
        variant="send"
        size="sm"
        className={classNames('shadow-none', {
          'text-primary': formData.message.length > 0
        })}
        type="submit"
        onClick={handleSubmit}
        disabled={!isSubmitable}
      >
        Send
      </Button>
    </Form>
  )
}

MessageTextArea.propTypes = {
  formData: PropTypes.object,
  handleFieldChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  isProcessing: PropTypes.bool,
  setIsProcessing: PropTypes.func,
  isSubmitable: PropTypes.bool,
  setIsSubmitable: PropTypes.func,
  handleAddAttachment: PropTypes.func,
  handleDetachAttachment: PropTypes.func
}

export default MessageTextArea
