export const initialState = {
  partnerData: {},
  dfsPerDayData: [],
  dfsPerMonthData: [],
  dfsTransactionData: [],
  dfsReservationData: [],
  partnerBillingData: [],
  partnerContacts: [],
  tokens: [],
  webhooks: [],
  locationData: {
    dfs_location_summary: []
  },
  dfs_status: [],
  dfs_locations: []
}

export const PartnerReducer = (initialState, action) => {
  const { type, payload } = action
  // Leaving for future Debug
  // console.log(type)
  // console.log(payload)
  let updated

  switch (type) {
    case 'INIT_DFS_PER_DAY':
      return {
        ...initialState,
        dfsPerDayData: payload.dfsPerDayData,
      }
    case 'INIT_DFS_PER_MONTH':
      return {
        ...initialState,
        dfsPerMonthData: payload.dfsPerMonthData,
      }
    case 'INIT_DFS_TRANSACTIONS':
      return {
        ...initialState,
        dfsTransactionData: payload.dfsTransactionData,
      }
    case 'INIT_DFS_RESERVATIONS':
      return {
        ...initialState,
        dfsReservationData: payload.dfsReservationData,
      }
    case 'INIT_PARTER':
      return {
        ...initialState,
        partnerData: payload.partnerData,
      }
    case 'INIT_PARTER_BILLING':
      return {
        ...initialState,
        partnerBillingData: payload.partnerBillingData,
      }
    case 'INIT_CONTACTS':
      return {
        ...initialState,
        partnerContacts: payload.partnerContacts,
      }
    case 'INIT_TOKENS':
      return {
        ...initialState,
        tokens: payload.tokens,
      }
    case 'ADD_TOKEN':
      return {
        ...initialState,
        tokens: initialState.tokens.concat([payload.token]),
      }
    case 'DELETE_TOKEN':
      updated = initialState.tokens.filter(n => {
        if (n.id !== payload.token) {
          return true
        }
        return false
      })

      return {
        ...initialState,
        tokens: updated,
      }
    case 'ADD_WEBHOOK':
      return {
        ...initialState,
        webhooks: initialState.webhooks.concat([payload.webhook]),
      }
    case 'DELETE_WEBHOOK':
      updated = initialState.webhooks.filter(n => {
        if (n.id !== payload.webhook) {
          return true
        }
        return false
      })

      return {
        ...initialState,
        webhooks: updated,
      }
    case 'INIT_DFS_DAILY_STATUS':
      return {
        ...initialState,
        dfs_status: payload.dfs_status,
        locationData: {
          dfs_location_summary: payload.dfs_location_summary
        },
      }
    case 'INIT_DFS_LOCATIONS':
      return {
        ...initialState,
        locationData: {
          dfs_location_summary: payload.dfs_location_summary
        },
      }
    case 'INIT_WEBHOOKS': {
      return {
        ...initialState,
        webhooks: payload.webhooks.map(w => {
          const status = {}
          if (w.last_status?.code === 200) {
            status.icon = 'circle'
            status.iconSize = '2xs'
          } else {
            status.icon = 'triangle-exclamation'
            status.iconSize = 'sm'
          }
          return {
            ...w,
            ...status
          }
        })
      }
    }
    case 'UPDATE_WEBHOOK':
      // console.log(payload.user)
      updated = initialState.webhooks.map(u => {
        if (u.id === payload.webhook.id) {
          return payload.webhook
        }
        return u
      })

      return {
        ...initialState,
        webhooks: updated,
      }
    case 'ADD_CONTACT':
      return {
        ...initialState,
        partnerContacts: initialState.partnerContacts.concat([payload.contact]),
      }
    case 'DELETE_CONTACT':
      updated = initialState.partnerContacts.filter(n => {
        if (n.id !== payload.contact) {
          return true
        }
        return false
      })

      return {
        ...initialState,
        partnerContacts: updated,
      }
    case 'UPDATE_CONTACT':
      // console.log(payload.user)
      updated = initialState.partnerContacts.map(u => {
        if (u.id === payload.contact.id) {
          return payload.contact
        }
        return u
      })

      return {
        ...initialState,
        partnerContacts: updated,
      }
    case 'UPDATE_TRANSACTION':
      // console.log(payload.user)
      updated = initialState.dfsTransactionData.map(u => {
        if (u.reservation_id === payload.transaction.reservation_id) {
          return payload.transaction
        }
        return u
      })

      return {
        ...initialState,
        dfsTransactionData: updated,
      }
    case 'INIT_DFS_LOCATION_STATUS':
      // const inactive = payload.dfs_locations.filter(l => l.status !== true)
      // console.log(inactive)
      return {
        ...initialState,
        dfs_locations: payload.dfs_locations,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
