import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { usePartnerContext } from 'providers/PartnerProvider'
import PageHeader from 'components/common/PageHeader'
import { useAppContext } from 'providers/AppProvider'
import { dateTimeOptions, currencyOptions } from 'helpers/formater'
import {
  Button,
  ToggleButtonGroup,
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Modal,
  Form,
  Row,
  Col,
  Card,
  InputGroup,
  Badge,
  ToggleButton,
  Tooltip,
  OverlayTrigger
} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import { minCellFormater } from 'components/common/advance-table/AdvanceTableHelper'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter'
import useAdvanceTable from 'hooks/useAdvanceTable'
import AdvanceTableProvider from 'providers/AdvanceTableProvider'
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox'
import StatisticsCard from './StatisticsCard'
import { differenceInMonths } from 'date-fns'
import { formatDollars, formatDateTime } from 'helpers/utils'

import RoadysLogo from "assets/img/Roady's-Logo-60.png"
import DfsLogoLight from 'assets/img/DFS-Logo-Finals-07.png'
import DfsLogoDark from 'assets/img/DFS-Logo-Finals-08.png'
import PtpLogo from 'assets/img/ptp-logo.png'

const CellWithOverlay = ({ value, tipText }) => {
  return (
    <OverlayTrigger
      placement='bottom'
      overlay={
        <Tooltip>{tipText}</Tooltip>
      }
    >
      <h6 className="mb-0">{value}</h6>
    </OverlayTrigger>
  )
}

CellWithOverlay.propTypes = {
  tipText: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

const DFSLocationStatusPage = () => {
  const { partnerState, getBillingReports } = usePartnerContext()

  const {
    config: { theme },
  } = useAppContext()

  const [loading, setLoading] = useState(true)
  const [reportData, setReportData] = useState([])
  const [viewableReportData, setViewableReportData] = useState([])
  const [ready, setReady] = useState(false)
  const [inactive, setInactive] = useState([])
  const [active, setActive] = useState([])
  const [activeThisMonth, setActiveThisMonth] = useState([])
  const [viewValue, setViewValue] = useState(0)

  useEffect(() => {
    if (partnerState.dfs_locations !== null) {
      if (Array.isArray(partnerState.dfs_locations)) {
        setReportData(partnerState.dfs_locations)
        setLoading(false)
        setReady(true)
      }
    }
  }, [partnerState.dfs_locations])

  useEffect(() => {
    if (reportData.length > 0) {
      setViewableReportData(reportData)
      const inactive = reportData.filter(l => l.status !== true)
      setInactive(inactive)

      const active = reportData.filter(l => l.status === true)
      setActive(active)

      const activeThisMonth = active.filter(l => {
        const now = new Date()
        const effectiveDate = new Date(l.effective_date)
        const diff = differenceInMonths(now, effectiveDate)
        if (differenceInMonths(now, effectiveDate) < 1) {
          return true
        }
        return false
      })
      setActiveThisMonth(activeThisMonth)
    }
  }, [reportData])

  useEffect(() => {
    if (reportData.length > 0) {
      switch (viewValue) {
        case 0:
          setViewableReportData(reportData)
          break
        case 1:
          setViewableReportData(active)
          break
        case 2:
          setViewableReportData(inactive)
          break
      }
    }
  }, [viewValue])

  const getLocationGroupImage = (g) => {
    switch (g) {
      case 'D':
        return (theme === 'light' ? DfsLogoLight : DfsLogoDark)
      case 'P':
        return PtpLogo
      case 'R':
      default:
        return RoadysLogo
    }
  }

  const columns = [
    {
      header: 'Status',
      accessorKey: 'status',
      enableSorting: true,
      cell: (rowData) => {
        const row = rowData.row.original
        if (row.status) {
          return <Badge pill bg="success" text="dark">Active</Badge>
        } else {
          return <Badge pill bg="warning" text="dark">Inactive</Badge>
        }
      },
      meta: {
        cellProps: {
          className: 'text-center',
          width: '105px',
        }
      },
    },
    {
      header: 'Id',
      accessorKey: 'id',
      enableSorting: true,
      cell: minCellFormater,
      meta: {
        cellProps: {
          width: '70px',
        }
      },
    },
    {
      header: 'Location',
      accessorKey: 'name',
      enableSorting: true,
      cell: minCellFormater,
    },
    {
      header: 'Group',
      accessorKey: 'group',
      enableSorting: true,
      cell: (rowData) => {
        const row = rowData.row.original
        return <img src={getLocationGroupImage(row.group)} height='20' alt="App logo" />
      },
      meta: {
        cellProps: {
          className: 'text-center',
          width: '100px',
        }
      },
    },
    {
      header: 'Type',
      accessorKey: 'type',
      enableSorting: true,
      cell: (rowData) => {
        const row = rowData.row.original
        switch (row.type) {
          case 'R':
            return <h6 className="mb-0">Truck Stop</h6>
          case 'C':
            return <h6 className="mb-0">C-Store</h6>
          case 'D':
            return <h6 className="mb-0">DFS Only</h6>
          case 'F':
            return <h6 className="mb-0">Fuel Stop</h6>
          case 'V':
            return <h6 className="mb-0">Service Center</h6>
          case 'S':
            return <h6 className="mb-0">Truck Stop / Service Center</h6>
        }
      },
      meta: {
        cellProps: {
          width: '190px',
        }
      },
    },
    {
      header: 'Diesel Base',
      accessorKey: 'diesel_base',
      enableSorting: true,
      cell: (rowData) => {
        const row = rowData.row.original
        if (row.diesel_base) {
          return (
            <CellWithOverlay value={formatDollars(row.diesel_base)} tipText={formatDateTime(row.diesel_base_date)} />
          )
        } else {
          return ('')
        }
      },
      meta: {
        cellProps: {
          width: '170px',
        }
      },
    },
    {
      header: 'Diesel Retail',
      accessorKey: 'diesel_retail_price',
      enableSorting: true,
      cell: (rowData) => {
        const row = rowData.row.original
        if (row.diesel_retail_price) {
          return (
            <>
              <CellWithOverlay value={formatDollars(row.diesel_retail_price)} tipText={formatDateTime(row.diesel_retail_price_date)} />
            </>
          )
        } else {
          return ('')
        }
      },
      meta: {
        cellProps: {
          width: '170px',
        }
      },
    },
    {
      header: 'DEF Retail',
      accessorKey: 'def_retail_price',
      enableSorting: true,
      cell: (rowData) => {
        const row = rowData.row.original
        if (row.def_retail_price) {
          return (
            <>
              <CellWithOverlay value={formatDollars(row.def_retail_price)} tipText={formatDateTime(row.def_retail_price_date)} />
            </>
          )
        } else {
          return ('')
        }
      },
      meta: {
        cellProps: {
          width: '170px',
        }
      },
    },
    {
      header: 'DEF Base',
      accessorKey: 'def_base',
      enableSorting: true,
      cell: (rowData) => {
        const row = rowData.row.original
        if (row.def_base) {
          return (
            <>
              <CellWithOverlay value={formatDollars(row.def_base)} tipText={formatDateTime(row.def_base_date)} />
            </>
          )
        } else {
          return ('')
        }
      },
      meta: {
        cellProps: {
          width: '170px',
        }
      },
    },
  ]

  function GetInVisibleColumn (finalColumnDef) {
    const inVisibleColumns = finalColumnDef.filter(
      (col) => col.visible === false
    )

    const removedColumn = {}
    for (const item of inVisibleColumns) {
      removedColumn[item.accessorKey] = false
    }

    return removedColumn
  }

  const table = useAdvanceTable({
    data: viewableReportData,
    columns,
    sortable: true,
    pagination: true,
    perPage: 10,
    initialState: {
      columnVisibility: GetInVisibleColumn(columns),
    }
  })

  const handleViewValChange = (val) => {
    setViewValue(val)
  }

  return (
    <>
      <PageHeader
        title='DFS Location Status'
        description=""
        className='mb-3'
      />

      <Row className="g-3 mb-3">
        <Col md={{ span: 3 }}>
          <StatisticsCard
            title='Active'
            icon='times'
            value={active.length}
            valueClassName='text-success'
            badgeBg='success'
            badgeText={activeThisMonth.length.toString()}
            rightImage='bg3'
            tipText={`Increase of ${activeThisMonth.length.toString()} within the past month`}
          />
        </Col>
        <Col md={{ span: 3 }}>
          <StatisticsCard
            title='Inactive'
            value={inactive.length}
            valueClassName='text-warning'
            icon='times'
            rightImage='bg1'
          />
        </Col>
      </Row>

      <AdvanceTableProvider {...table}>
        <Card className="mb-3">
          <Nav className='m-2'>
            <Nav.Item>

              <Form.Label style={{ marginRight: '0.3rem' }}>Filter:</Form.Label>
              <ToggleButtonGroup type="radio" value={viewValue} onChange={handleViewValChange} name='filter' defaultValue={1} style={{ marginRight: '0.3rem' }}>
                <ToggleButton size='sm' id="tbg-btn-0" value={0} variant='outline-secondary'>All</ToggleButton>
                <ToggleButton size='sm' id="tbg-btn-1" value={1} variant='outline-secondary'>Active</ToggleButton>
                <ToggleButton size='sm' id="tbg-btn-2" value={2} variant='outline-secondary'>Inative</ToggleButton>
              </ToggleButtonGroup>
            </Nav.Item>
            <Nav.Item style={{ flexGrow: 1 }}>
              <AdvanceTableSearchBox table />
            </Nav.Item>
          </Nav>
          <Card.Body className="p-0">
            <AdvanceTable
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs-10 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </Card.Footer>
        </Card>
      </AdvanceTableProvider>
    </>
  )
}

export default DFSLocationStatusPage
