export const dashboardRoutes = {
  label: 'Dashboard',
  id: 'dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      id: 'dashboard_Dashboard',
      icon: 'chart-pie',
      to: '/',
      active: true
    }
  ]
}

export const dfsRoutes = {
  label: 'DFS',
  id: 'dfs',
  children: [
    {
      name: 'Reservations',
      id: 'dfs_reservations',
      icon: 'tickets',
      active: true,
      to: '/dfs/reservations',
    },
    {
      name: 'Transactions',
      id: 'dfs_transactions',
      icon: 'receipt',
      active: true,
      to: '/dfs/redemptions',
    },
    {
      name: 'Location Status',
      id: 'dfs_locations',
      icon: 'shop',
      active: true,
      to: '/dfs/locations',
    },
  ]
}

export const helpRoutes = {
  label: 'Help',
  id: 'help',
  children: [
    {
      name: 'Terms of Service',
      id: 'help_help_terms_of_service',
      to: '/terms',
      active: true
    },
    {
      name: 'Privacy Policy',
      id: 'help_help_privacy_policy',
      to: '/privacy',
      active: true
    }
  ]
}

export const settingsRoutes = {
  label: 'Settings',
  id: 'settings',
  children: [
    {
      name: 'User Management',
      id: 'settings_user_mgmt',
      active: true,
      icon: 'users',
      to: '/settings/users',
    },

    {
      name: 'Contacts',
      id: 'contacts',
      active: true,
      icon: 'envelope',
      to: '/settings/contacts',
    },
    // {
    //   name: 'Subscription',
    //   id: 'access_keys',
    //   active: true,
    //   icon: 'receipt',
    //   to: '/admin/subscription',
    // },
    // {
    //   name: 'Billing',
    //   id: 'subscription_mgmt',
    //   active: true,
    //   icon: 'credit-card',
    //   to: '/admin/billing',
    // },
    {
      name: 'Developer',
      id: 'developer',
      icon: ['fab', 'dev'],
      active: true,
      children: [
        {
          name: 'Access Keys',
          id: 'access_keys',
          active: true,
          icon: ['regular', 'key'],
          to: '/settings/keys',
        },
        {
          name: 'Webhooks',
          id: 'access_keys',
          active: true,
          icon: ['solid', 'webhook'],
          to: '/settings/webhooks',
          // badge: {
          //   type: 'success',
          //   text: 'New'
          // }
        },
        {
          name: 'API Documentation',
          id: 'documentation',
          active: true,
          icon: ['solid', 'book'],
          to: '/settings/documentation',
          // badge: {
          //   type: 'success',
          //   text: 'New'
          // }
        },
      ]
    }
  ]
}

export const billingRoutes = {
  label: 'Billing',
  id: 'billing',
  children: [
    {
      name: 'DFS Detailed Invoices',
      id: 'dfs_reports',
      active: true,
      icon: 'receipt',
      to: '/billing/dfs_invoices',
    },
    // {
    //   name: 'Bills',
    //   id: 'bills',
    //   active: true,
    //   icon: 'credit-card',
    //   to: '/billing/bills',
    // },
    // {
    //   name: 'Payment Methods',
    //   id: 'payment_methods',
    //   active: true,
    //   icon: 'credit-card',
    //   to: '/billing/methods',
    // },
  ]
}

export default [
  dashboardRoutes,
  dfsRoutes,
  settingsRoutes,
  billingRoutes,
  helpRoutes
]
