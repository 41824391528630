import { usePartnerContext } from 'providers/PartnerProvider'
import FalconCardHeader from 'components/common/FalconCardHeader'
import Flex from 'components/common/Flex'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { BarChart } from 'echarts/charts'
import * as echarts from 'echarts/core'
import React, { useEffect, useState } from 'react'
import { Card, Form } from 'react-bootstrap'
import { months, years } from 'data/common'
import { GridComponent, LegendComponent, TitleComponent, TooltipComponent } from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import Skeleton from 'react-loading-skeleton'
import { isIterableArray } from 'helpers/utils'
import CountUp from 'react-countup'

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
])

export const getColor = name => {
  const dom = document.documentElement
  return getComputedStyle(dom).getPropertyValue(`--falcon-${name}`).trim()
}

const getThemeColor = (name) => getColor(name)

const DfsGallonsPerMonth = () => {
  const { partnerState, getDFSGallonsPerDayData } = usePartnerContext()

  const d = new Date()

  const [average, setAverage] = useState(0)
  const [reportData, setReportData] = useState([])
  const [loading, setLoading] = useState(false)
  const [labelData, setDisplayLabels] = useState(null)
  const [displayData, setDisplayData] = useState(null)

  useEffect(() => {
    if (partnerState.dfsPerMonthData !== null) {
      setReportData(partnerState.dfsPerMonthData)
    }
  }, [partnerState.dfsPerMonthData])

  useEffect(() => {
    if (typeof reportData === 'object'
      && reportData !== null
      && Object.keys(reportData).length > 0) {
      const correctedData = Object.create(null)
      let total = 0

      reportData.forEach((d) => {
        correctedData[d.date] = d.gallons
        total = total + parseFloat(d.gallons)
      })
      // console.log(correctedData)
      setAverage(parseFloat(total / reportData.length).toFixed(2))

      // Set the rest of the report data
      setDisplayLabels(Object.keys(correctedData))
      setDisplayData(Object.values(correctedData))
      setLoading(false)
    }
  }, [reportData])

  const config = {
    tooltip: {
      padding: [7, 10],
      transitionDuration: 0,
      backgroundColor: getThemeColor('gray-100'),
      borderColor: getThemeColor('gray-300'),
      textStyle: { color: getThemeColor('gray-1100') },
      borderWidth: 1,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: labelData,
        axisLabel: {
          show: true,
          interval: 0,
          color: getThemeColor('gray-400'),
          margin: 15
        },
        lineStyle: {
          color: getThemeColor('gray-300'),
          type: 'solid'
        }
      }
    ],
    yAxis: {
      type: 'value',
      axisLabel: {
        show: true,
        color: getThemeColor('gray-400'),
        margin: 15
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: getThemeColor('gray-200')
        }
      },
      axisTick: { show: false },
      axisLine: { show: false },
      min: 600
    },
    series: [
      {
        name: 'Gallons Per Month',
        type: 'bar',
        barWidth: '60%',
        data: displayData,
        label: {
          show: true,
          rotate: 90,
          align: 'left',
          verticalAlign: 'middle',
          position: 'insideBottom',
          distance: 15,
          formatter: '{c}', //  {name|{a}}',
          fontSize: 14,
          color: getThemeColor('gray-1100'),
          rich: {
            name: {}
          }
        },
        itemStyle: {
          color: getThemeColor('primary'),
          borderRadius: [3, 3, 0, 0]
        },
      }
    ]
  }

  return (
    <Card className='h-100'>
      <FalconCardHeader
        title='DFS Gallons Per Month'
        titleTag='h4'
        className='pb-0'
        endEl={<Flex> </Flex>}>
        <div>
          <small>
            Average: <CountUp
              start={0}
              end={average}
              duration={2.75}
            />
          </small>
        </div>
      </FalconCardHeader>
      <Card.Body>
        {
          loading
            ? <Skeleton count={8} />
            : <ReactEChartsCore
              echarts={echarts}
              option={config}
              style={{ height: '18.4375rem' }}
            />
        }
      </Card.Body>
    </Card>
  )
}

export default DfsGallonsPerMonth
