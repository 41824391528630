import React, { createContext, useContext, useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { useAccountContext } from 'providers/AccountProvider'
import { initialState, SystemAdminReducer } from 'reducers/systemAdminReducer'
import { Amplify, API, graphqlOperation } from 'aws-amplify'
import awsconfig from 'aws-exports'
import * as queries from 'graphql/queries'
import * as subscriptions from 'graphql/subscriptions'
import * as mutations from 'graphql/mutations'
import parsePhoneNumber from 'libphonenumber-js'
import { permissionOptions } from 'config'
Amplify.configure(awsconfig)

export const SystemAdminContext = createContext()

const SystemAdminProvider = ({ children }) => {
  const { appState } = useAccountContext()
  const [systemAdminState, dispatch] = useReducer(SystemAdminReducer, initialState)
  const [readyState, setReadyState] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)
  const [partnerId, setPartnerId] = useState(null)

  useEffect(() => {
    if (
      appState.ready
      && appState.authenticated) {
      setReadyState(appState.ready)
      setCurrentUser(appState.user)
      setPartnerId(parseInt(appState.attributes['custom:partnerid']))
    }
  }, [appState])

  useEffect(() => {
    if (readyState === true) {
      // getListNotifications()
      // connectSubs()
    }
  }, [readyState])

  async function connectSubs () {
    API.graphql(
      graphqlOperation(subscriptions.onCreateNotification, { user: currentUser })
    ).subscribe({
      next: ({ provider, value }) => {
        if (value !== undefined && value !== null) {
          dispatch({
            type: 'ADD_NOTIFICATION',
            payload: {
              notification: value.data.onCreateNotification,
            },
          })
        }
      },
      error: (error) => console.warn(error)
    })

    API.graphql(
      graphqlOperation(subscriptions.onUpdateNotification)
    ).subscribe({
      next: ({ provider, value }) => {
        if (value !== undefined && value !== null) {
          dispatch({
            type: 'UPDATE_NOTIFICATION',
            payload: {
              notification: value.data.onUpdateNotification,
            },
          })
        }
      },
      error: (error) => console.warn(error)
    })

    API.graphql(
      graphqlOperation(subscriptions.onDeleteNotification)
    ).subscribe({
      next: ({ provider, value }) => {
        if (value !== undefined && value !== null) {
          dispatch({
            type: 'DELETE_NOTIFICATION',
            payload: {
              notification: value.data.onDeleteNotification,
            },
          })
        }
      },
      error: (error) => console.warn(error)
    })
  }

  async function getListNotifications () {
    const notifications = await API.graphql(graphqlOperation(queries.listAllNotifications))
    if (notifications.data.listUserNotifications !== null) {
      dispatch({
        type: 'INIT_NOTIFICATIONS',
        payload: {
          notifications: notifications.data.listNotifications.items,
        },
      })
    }
  }

  async function updateNotificationToRead (id) {
    const notifications = await API.graphql(graphqlOperation(mutations.updateNotificationToRead, { id }))
  }

  const initUsers = async (data) => {
    // console.log(data)
    data = data.map(d => {
      return {
        ...d,
        permissions: permissionOptions.filter((o) => !!(o.value & d.permissions))
      }
    })
    // console.log(data)
    dispatch({
      type: 'INIT_USERS',
      payload: {
        users: data,
      },
    })
  }

  const updateUser = async (data) => {
    const phoneNumber = parsePhoneNumber(data.phone_number, 'US')
    const input = {
      id: data.id,
      name: data.name,
      email: data.email,
      phone_number: phoneNumber.number,
      permissions: data.permissions.reduce((acc, cur) => acc + cur.value, 0)
    }
    // console.log(input)
    const user = await API.graphql(graphqlOperation(mutations.updateUser, input))
    // console.log(user.data.updateUser)
    if (user.data.updateUser !== null) {
      user.data.updateUser = {
        ...user.data.updateUser,
        permissions: permissionOptions.filter((o) => !!(o.value & user.data.updateUser.permissions))
      }
      dispatch({
        type: 'UPDATE_USER',
        payload: {
          user: user.data.updateUser,
        },
      })
    }
  }

  const deleteUser = async (data) => {
    const input = {
      id: data,
    }
    // console.log(input)
    const user = await API.graphql(graphqlOperation(mutations.deleteUser, input))
    // console.log(user.data.updateUser)
    if (user.data.deleteUser !== null) {
      dispatch({
        type: 'DELETE_USER',
        payload: {
          user: user.data.deleteUser,
        },
      })
    }
  }

  const createNewUser = async (data) => {
    // console.log(data)
    const phoneNumber = parsePhoneNumber(data.phone_number, 'US')

    const input = {
      name: data.name,
      email: data.email,
      phone_number: phoneNumber.number,
      permissions: data.permissions.reduce((acc, cur) => acc + cur.value, 0),
      partnerId,
      partnerName: systemAdminState.partnerName
    }
    // console.log(input)
    const user = await API.graphql(graphqlOperation(mutations.createUser, input))
    // console.log(user.data.createCompleteUser)
    if (user.data.createCompleteUser !== null) {
      user.data.createCompleteUser = {
        ...user.data.createCompleteUser,
        permissions: permissionOptions.filter((o) => !!(o.value & user.data.createCompleteUser.permissions))
      }
      dispatch({
        type: 'ADD_USER',
        payload: {
          user: user.data.createCompleteUser,
        },
      })
    }
  }

  const changeUserPassword = async (data) => {
    const input = {
      id: data.id,
      password: data.password
    }
    const user = await API.graphql(graphqlOperation(mutations.updateUserPassword, input))
    // console.log(user.data.updateUserPassword)
    if (user.data.updateUserPassword !== null) {
      // dispatch({
      //   type: 'DELETE_USER',
      //   payload: {
      //     user: user.data.deleteUser,
      //   },
      // })
    }
  }

  const setPartnerName = (name) => {
    dispatch({
      type: 'SET_PARTNER_NAME',
      payload: {
        partnerName: name,
      },
    })
  }

  const sharedState = {
    systemAdminState,
    initUsers,
    updateUser,
    deleteUser,
    createNewUser,
    changeUserPassword,
    setPartnerName
  }

  return (
    <SystemAdminContext.Provider value={sharedState}>
      {children}
    </SystemAdminContext.Provider>
  )
}

SystemAdminProvider.propTypes = { children: PropTypes.node }

export const useSystemAdminContext = () => useContext(SystemAdminContext)

export default SystemAdminProvider
