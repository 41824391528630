import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
} from 'react-bootstrap'
import { usePartnerContext } from 'providers/PartnerProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ContactDeleteModal = ({ show, hide, contact }) => {
  const { deleteContact } = usePartnerContext()
  // console.log(user.email)
  const [isSaving, setSaving] = useState(false)
  const [isSubmitable, setIsSubmitable] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSaving(true)
    await deleteContact(contact.id)
    setSaving(false)
    hide()
  }

  return (
    <Modal centered show={show} onHide={hide}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Contact</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you certain you want to delete the following contact?
        <ul>
          <li>{contact.email}</li>
          <li>{contact.first_name} {contact.last_name}</li>
        </ul>
        This action is limited to the above contact alone.  All sent data will remain available, but new reports will no longer be delivered to this contact.
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={handleSubmit}
        // disabled={!isSubmitable}
        >
          {isSaving
            ? <FontAwesomeIcon
              icon='circle-notch'
              className='me-1 fa-spinner fa-spin'
            />
            : ''
          }
          {' '}
          Yes
        </Button>
        <Button variant="secondary" onClick={hide}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ContactDeleteModal

ContactDeleteModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  contact: PropTypes.object,
}
