import React, { useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import GitInfo from 'react-git-info/macro'

const VersionCard = () => {
  const gitInfo = GitInfo()
  const versionToCheck = 'prod'

  let version = ''

  if (process.env.REACT_APP_ENV !== versionToCheck) {
    version = `${process.env.REACT_APP_ENV?.toUpperCase()} Environment | `
  }

  if (process.env.REACT_APP_VERSION) {
    version = `${version} ${process.env.REACT_APP_VERSION}`
  }

  if (process.env.REACT_APP_ENV !== versionToCheck) {
    version = `${version} | ${gitInfo.commit.shortHash}`
  }

  return (
    // show && (
    <div className="settings mb-3 mt-auto text-center fs-11">
      {version}
    </div>
    // )
  )
}

export default VersionCard
