import React from 'react'
import App from 'App'
import { Navigate, createBrowserRouter } from 'react-router-dom'

import MainLayout from 'layouts/MainLayout'
import ErrorLayout from 'layouts/ErrorLayout'

import Error404 from 'components/errors/Error404'
import Error500 from 'components/errors/Error500'

import CardLogin from 'components/pages/authentication'

import Dashboard from 'components/pages/dashboard'
import Redemptions from 'components/pages/dfs_redemptions'
import Reservations from 'components/pages/dfs_reservations'
import DFSSettlementsPage from 'components/pages/dfs_billing'
import BillingManagement from 'components/pages/billing'
import ContactsPage from 'components/pages/contacts'
import UserManagementPage from 'components/pages/user_management'
import ApiKeys from 'components/pages/apikeys'
import Webhooks from 'components/pages/webhooks'
import ApiDocs from 'components/pages/apidocs'
import TermsOfServicePage from 'components/pages/terms'
import PrivacyPolicyPage from 'components/pages/privacy'
import Notifications from 'components/pages/notifications'
import Notification from 'components/pages/notifications/notification'
import Profile from 'components/pages/profile'
import DFSBillPage from 'components/pages/dfs_billing/Bill'
import ForgotPage from 'components/pages/forgot'
import DfsLocationStatus from 'components/pages/dfs_locations'

const allroutes = [
  {
    path: 'terms',
    children: [
      {
        index: true,
        element: <TermsOfServicePage />
      }
    ]
  },
  {
    path: 'privacy',
    children: [
      {
        index: true,
        element: <PrivacyPolicyPage />
      }
    ]
  }
]

const routes = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: <ErrorLayout />,
        children: [
          {
            path: '404',
            element: <Error404 />
          },
          {
            path: '500',
            element: <Error500 />
          }
        ]
      },
      {
        path: '/',
        element: <MainLayout />,
        children: [
          {
            index: true,
            element: <Dashboard />
          },
          {
            path: 'dfs',
            children: [
              {
                path: 'reservations',
                element: <Reservations />
              },
              {
                path: 'redemptions',
                element: <Redemptions />
              },
              {
                path: 'billing',
                element: <Redemptions />
              },
              {
                path: 'locations',
                element: <DfsLocationStatus />
              },
            ]
          },
          {
            path: 'settings',
            children: [
              {
                path: 'users',
                element: <UserManagementPage />
              },
              {
                path: 'contacts',
                element: <ContactsPage />
              },
              {
                path: 'keys',
                element: <ApiKeys />
              },
              {
                path: 'webhooks',
                element: <Webhooks />
              },
              {
                path: 'documentation',
                element: <ApiDocs />
              }
            ]
          },
          {
            path: 'billing',
            children: [
              {
                path: 'bills',
                element: <BillingManagement />
              },
              {
                path: 'methods',
                element: <Redemptions />
              },
              {
                path: 'dfs_invoices',
                element: <DFSSettlementsPage />,
              },
              {
                path: 'dfs_invoices/:id',
                element: <DFSBillPage />
              },
            ]
          },
          {
            path: 'notifications',
            children: [
              {
                index: true,
                element: <Notifications />
              },
              {
                path: ':noteId',
                element: <Notification />
              },
            ]
          },
          {
            path: 'user',
            children: [
              {
                path: 'profile',
                element: <Profile />
              },
            ]
          },
          ...allroutes
        ]
      },
      {
        path: '*',
        element: <Navigate to='404' replace />
      }
    ]
  }
]

const authRoutes = [
  {
    element: <App />,
    children: [
      {
        path: 'errors',
        element: <ErrorLayout />,
        children: [
          {
            path: '404',
            element: <Error404 />
          },
          {
            path: '500',
            element: <Error500 />
          }
        ]
      },
      {
        path: '/',
        // element: <MainLayout />,
        children: [
          {
            index: true,
            element: <CardLogin />
          },
          // {
          //   path: 'register',
          //   element: <CardRegistration />
          // },
          {
            path: 'forgot',
            element: <ForgotPage />
          },
          // {
          //   path: 'reset',
          //   element: <CardPasswordReset />
          // },
        ]
      },
      {
        path: '*',
        element: <Navigate to='404' replace />
      }
    ]
  }
]

export const router = createBrowserRouter(routes, {
  basename: process.env.PUBLIC_URL
})

export const authRouter = createBrowserRouter(authRoutes, {
  basename: process.env.PUBLIC_URL
})

export default routes
