import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { IMaskInput } from 'react-imask'
import { Formik, Field, Form } from 'formik'
import {
  Form as bForm,
} from 'react-bootstrap'
import Select from 'react-select'

export const TextField = ({ hasLabel, title, name, helper }) => {
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        return (
          <bForm.Group className='mb-3'>
            {hasLabel && <bForm.Label>{title}</bForm.Label>}
            <bForm.Control
              placeholder={!hasLabel ? title : ''}
              type='text'
              value={meta.value}
              {...field}
              isInvalid={!!meta.error}
            />
            <bForm.Text id="passwordHelpBlock" muted>
              {helper}
            </bForm.Text>
            {
              meta.error && meta.touched
                ? <bForm.Control.Feedback type="invalid">
                  {meta.error}
                </bForm.Control.Feedback>
                : null
            }
          </bForm.Group>
        )
      }}
    </Field>
  )
}

TextField.propTypes = {
  hasLabel: PropTypes.bool,
  title: PropTypes.string,
  name: PropTypes.string,
  helper: PropTypes.string,
}

export const NumericField = ({ hasLabel, title, name }) => {
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        return (
          <bForm.Group className='mb-3'>
            {hasLabel && <bForm.Label>{title}</bForm.Label>}
            <IMaskInput
              placeholder={!hasLabel ? title : ''}
              mask={Number}
              className='form-control'
              {...field}
            />
            {
              meta.error && meta.touched
                ? <bForm.Control.Feedback type="invalid">
                  {meta.error}
                </bForm.Control.Feedback>
                : null
            }
          </bForm.Group>
        )
      }}
    </Field>
  )
}

NumericField.propTypes = {
  hasLabel: PropTypes.bool,
  title: PropTypes.string,
  name: PropTypes.string,
}

export const PhoneTextField = ({ hasLabel, title, name }) => {
  const mask = '(000) 000-0000'
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        return (
          <bForm.Group className='mb-3'>
            {hasLabel && <bForm.Label>{title}</bForm.Label>}
            <IMaskInput
              placeholder={!hasLabel ? title : ''}
              mask={mask}
              className='form-control'
              {...field}
            />
            {
              meta.error && meta.touched
                ? <bForm.Control.Feedback type="invalid">
                  {meta.error}
                </bForm.Control.Feedback>
                : null
            }
          </bForm.Group>
        )
      }}
    </Field>
  )
}

PhoneTextField.propTypes = {
  hasLabel: PropTypes.bool,
  title: PropTypes.string,
  name: PropTypes.string,
}

export const SelectField = ({ hasLabel, title, name, options }) => {
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        // console.log(field)
        const changeHandler = (option) => form.setFieldValue(name, option)
        // const valueHandler = options.find(option => option.value === field.value)
        return (
          <bForm.Group className='mb-3'>
            {hasLabel && <bForm.Label>{title}</bForm.Label>}

            <Select
              options={options}
              isClearable
              // value={options ? valueHandler : ''}
              value={meta.value}
              onChange={changeHandler}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderWidth: 'var(--falcon-border-width)',
                  borderStyle: 'solid',
                  borderColor: 'var(--falcon-gray-300)',
                  backgroundColor: 'var(--falcon-quaternary-bg)',
                  borderRadius: 'var(--falcon-border-radius)',
                  color: 'var(--falcon-gray-900)',
                }),
                menu: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: 'var(--falcon-quaternary-bg)',
                  color: 'var(--falcon-grey-900)',
                }),
                placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                  color: 'var(--falcon-grey-900)',
                  fontFamily: 'Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol'
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  color: state.isFocused ? 'white' : 'var(--falcon-grey-900)',
                  backgroundColor: state.isFocused ? 'cornflowerblue' : '',
                }),
                singleValue: (baseStyles, state) => ({
                  ...baseStyles,
                  fontWeight: 400,
                  color: 'var(--falcon-grey-900)',
                  fontFamily: 'Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol'
                }),
              }} />
            {
              meta.error && meta.touched
                ? <bForm.Control.Feedback type="invalid">
                  {meta.error}
                </bForm.Control.Feedback>
                : null
            }
          </bForm.Group>
        )
      }}
    </Field>
  )
}

SelectField.propTypes = {
  hasLabel: PropTypes.bool,
  title: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  multi: PropTypes.bool,
  changeHandlerOverride: PropTypes.func
}

export const MultiSelectField = ({ hasLabel, title, name, options }) => {
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        const changeHandler = (option) => form.setFieldValue(name, option)
        return (
          <bForm.Group className='mb-3'>
            {hasLabel && <bForm.Label>{title}</bForm.Label>}

            <Select
              options={options}
              isClearable
              isMulti={true}
              value={meta.value}
              onChange={changeHandler}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderWidth: 'var(--falcon-border-width)',
                  borderStyle: 'solid',
                  borderColor: 'var(--falcon-gray-300)',
                  backgroundColor: 'var(--falcon-quaternary-bg)',
                  borderRadius: 'var(--falcon-border-radius)',
                  color: 'var(--falcon-gray-900)',
                }),
                menu: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: 'var(--falcon-quaternary-bg)',
                  color: 'var(--falcon-grey-900)',
                }),
                placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                  color: 'var(--falcon-grey-900)',
                  fontFamily: 'Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol'
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  color: state.isFocused ? 'white' : 'var(--falcon-grey-900)',
                  backgroundColor: state.isFocused ? 'cornflowerblue' : '',
                }),
                singleValue: (baseStyles, state) => ({
                  ...baseStyles,
                  fontWeight: 400,
                  color: 'var(--falcon-grey-900)',
                  fontFamily: 'Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol'
                }),
              }} />
            {
              meta.error && meta.touched
                ? <bForm.Control.Feedback type="invalid">
                  {meta.error}
                </bForm.Control.Feedback>
                : null
            }
          </bForm.Group>
        )
      }}
    </Field>
  )
}

MultiSelectField.propTypes = {
  hasLabel: PropTypes.bool,
  title: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  multi: PropTypes.bool,
  changeHandlerOverride: PropTypes.func
}

export const ToggleField = ({ title, name }) => {
  return (
    <>{title}
      <Field name={name}>
        {({ field, form, meta }) => {
          return (
            <bForm.Check type='checkbox'>
              <bForm.Check
                type='switch'
                name={name}
                checked={field.value}
                {...field}
              />

              {
                meta.error && meta.touched
                  ? <bForm.Control.Feedback type="invalid">
                    {meta.error}
                  </bForm.Control.Feedback>
                  : null
              }
            </bForm.Check>
          )
        }}
      </Field>
    </>
  )
}

ToggleField.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
}

export const PasswordField = ({ hasLabel, title, name, helper }) => {
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        return (
          <bForm.Group className='mb-3'>
            {hasLabel && <bForm.Label>{title}</bForm.Label>}
            <bForm.Control
              placeholder={!hasLabel ? title : ''}
              type='password'
              value={meta.value}
              {...field}
              isInvalid={!!meta.error}
            />
            <bForm.Text id="passwordHelpBlock" muted>
              {helper}
            </bForm.Text>
            {
              meta.error && meta.touched
                ? <bForm.Control.Feedback type="invalid">
                  {meta.error}
                </bForm.Control.Feedback>
                : null
            }
          </bForm.Group>
        )
      }}
    </Field>
  )
}

PasswordField.propTypes = {
  hasLabel: PropTypes.bool,
  title: PropTypes.string,
  name: PropTypes.string,
  helper: PropTypes.string,
}
