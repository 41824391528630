/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useState, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import Flex from '../Flex'
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider'

export const AdvanceTableFooter = ({
  navButtons,
  className,
  rowInfo,
  rowsPerPageSelection,
  rowsPerPageOptions = [5, 10, 15],
  viewAllOnLoad
}) => {
  const {
    setPageSize,
    previousPage,
    nextPage,
    getCanNextPage,
    getCanPreviousPage,
    getState,
    getPrePaginationRowModel,
    getPaginationRowModel
  } = useAdvanceTableContext()

  const {
    pagination: { pageSize, pageIndex }
  } = getState()
  const [perPage] = useState(pageSize)
  const [isAllVisible, setIsAllVisible] = useState(viewAllOnLoad)

  // useEffect(() => {
  //   console.log(viewAllOnLoad)
  //   setPageSize(getPrePaginationRowModel().rows.length)
  // }, [viewAllOnLoad])

  return (
    <Flex
      className={classNames(
        className,
        'align-items-center justify-content-between'
      )}
    >
      {
        isAllVisible
          ? <Flex alignItems="center" className="fs-10">
            {rowInfo && (
              <p className="mb-0">
                <Button
                  variant={getCanPreviousPage() ? 'primary' : 'tertiary'}
                  size="sm"
                  className="me-4"
                  onClick={() => {
                    setIsAllVisible(!isAllVisible)
                    setPageSize(perPage)
                  }}
                >
                  Use Pagination
                </Button>
                <span className="d-none d-sm-inline-block me-2">
                  {getPrePaginationRowModel().rows.length} total
                </span>
              </p>
            )}
          </Flex>
          : <Flex alignItems="center" className="fs-10">
            {rowInfo && (
              <p className="mb-0">
                <Button
                  variant={getCanPreviousPage() ? 'primary' : 'tertiary'}
                  size="sm"
                  className="me-4"
                  onClick={() => {
                    setIsAllVisible(!isAllVisible)
                    setPageSize(getPrePaginationRowModel().rows.length)
                  }}
                >
                  View all
                </Button>
                <span className="d-none d-sm-inline-block me-2">
                  {pageSize * pageIndex + 1} to{' '}
                  {pageSize * pageIndex + getPaginationRowModel().rows.length} of{' '}
                  {getPrePaginationRowModel().rows.length}
                </span>
              </p>
            )}
            {
              rowsPerPageSelection && (
                <>
                  <p className="mb-0 mx-2">Rows per page:</p>
                  <Form.Select
                    size="sm"
                    className="w-auto"
                    onChange={e => setPageSize(Number(e.target.value))}
                    defaultValue={pageSize}
                  >
                    {rowsPerPageOptions.map(value => (
                      <option value={value} key={value}>
                        {value}
                      </option>
                    ))}
                  </Form.Select>
                </>
              )
            }
          </Flex>
      }

      {
        isAllVisible
          ?
          ''
          :
          navButtons && (
            <Flex>
              <Button
                size="sm"
                variant={getCanPreviousPage() ? 'primary' : 'tertiary'}
                onClick={() => previousPage()}
                className={classNames({ disabled: !getCanPreviousPage() })}
              >
                Previous
              </Button>
              <Button
                size="sm"
                variant={getCanNextPage() ? 'primary' : 'tertiary'}
                className={classNames('px-4 ms-2', {
                  disabled: !getCanNextPage()
                })}
                onClick={() => nextPage()}
              >
                Next
              </Button>
            </Flex>
          )
      }
    </Flex >
  )
}

export default AdvanceTableFooter
