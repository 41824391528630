import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Modal,
  Row,
  Col,
  ListGroup,
  Card,
} from 'react-bootstrap'
import { usePartnerContext } from 'providers/PartnerProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { contactTypes } from 'config'
import * as yup from 'yup'
import 'yup-phone-lite'
import { TextField, NumericField, PhoneTextField, SelectField, ToggleField } from 'components/common/FormHelper'
import { Formik, Field, Form } from 'formik'

const ContactModal = ({ show, hide, hasLabel = true, type = 'new', contact }) => {
  const { updateContact, createNewContact } = usePartnerContext()

  const defaultData = {
    first_name: '',
    last_name: '',
    email: '',
    title: '',
    phone_number: '',
    extensions: '',
    mobile_number: '',
    daily_discount_sub: false,
    dfs_billing_report_sub: false,
    type: 0,
  }

  const handleSubmit = async (values, bag) => {
    if (type === 'new') {
      await createNewContact(values)
    } else {
      await updateContact(values)
    }
    hide()
  }

  const schema = yup.object().shape({
    first_name: yup.string().required('This is a required field.'),
    last_name: yup.string().required('This is a required field.'),
    email: yup.string().email('Please provide a valid email address.').required('This is a required field.'),
    title: yup.string().nullable(true),
    type: yup.object().nullable(true),
    phone_number: yup.string().nullable(true), // .phone('US', 'Please provide a valid phone number'),
    extensions: yup.number().nullable(true),
    mobile_number: yup.string().nullable(true), // .phone('Please provide a valid mobile phone number'),
    daily_discount_sub: yup.boolean().nullable(true),
    dfs_billing_report_sub: yup.boolean().nullable(true)
  })

  return (
    <Modal centered show={show} onHide={hide} size={'xl'}>
      <Modal.Header closeButton>
        <Modal.Title>{type === 'new' ? 'Add New Contact' : 'Edit Contact'}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={contact !== undefined ? contact : defaultData}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ isValid, isSubmitting, dirty }) => {
          return (
            <Form>
              <Modal.Body>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <TextField
                          hasLabel
                          title='First Name'
                          name='first_name'
                        />
                      </Col>
                      <Col>
                        <TextField
                          hasLabel
                          title='Last Name'
                          name='last_name'
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextField
                          hasLabel
                          title='Email'
                          name='email'
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <TextField
                          hasLabel
                          title='Title'
                          name='title'
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <SelectField
                          hasLabel
                          title='Type'
                          name='type'
                          options={contactTypes}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <PhoneTextField
                          hasLabel
                          title='Phone Number'
                          name='phone_number'
                        />
                      </Col>
                      <Col>
                        <NumericField
                          hasLabel
                          title='Extension'
                          name='extension'
                        />
                      </Col>
                      <Col>
                        <PhoneTextField
                          hasLabel
                          title='Mobile Number'
                          name='mobile_number'
                        />
                      </Col>
                    </Row>

                  </Col>
                  <Col xs={3}>
                    <Card>
                      <Card.Header>Subscriptions</Card.Header>
                      <ListGroup variant="flush">
                        <ListGroup.Item className="d-flex justify-content-between align-items-start">
                          <ToggleField
                            hasLabel
                            title='Daily Discount Report'
                            name='daily_discount_sub' />
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between align-items-start">
                          <ToggleField
                            hasLabel
                            title='DFS Billing Report'
                            name='dfs_billing_report_sub' />
                        </ListGroup.Item>
                      </ListGroup>
                    </Card>
                  </Col>
                </Row>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={hide}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type='submit'
                  // onClick={handleSubmit}
                  disabled={!isValid || !dirty}
                >
                  {isSubmitting
                    ? <FontAwesomeIcon
                      icon='circle-notch'
                      className='me-1 fa-spinner fa-spin'
                    />
                    : ''
                  }
                  {' '}
                  {type === 'new' ? 'Save New Contact' : 'Update Contact'}
                </Button>
              </Modal.Footer>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default ContactModal

ContactModal.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  hasLabel: PropTypes.bool,
  type: PropTypes.string,
  contact: PropTypes.object,
}
