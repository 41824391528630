import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import AppProvider from 'providers/AppProvider'
import AccountProvider, { useAccountContext } from 'providers/AccountProvider'
import PartnerProvider from 'providers/PartnerProvider'
import ProfileProvider from 'providers/ProfileProvider'
import NotificationProvider from 'providers/NotificationProvider'
import SystemAdminProvider from 'providers/SystemAdminProvider'

import { router, authRouter } from 'routes'
import 'helpers/initFA'
import TagManager from 'react-gtm-module'

/**
 * Change Tag Id before uncommenting the Google Tag Manager lines
 */
// const tagManagerArgs = {
//   gtmId: 'G-TN711NPXMJ'
// }

// TagManager.initialize(tagManagerArgs)

const container = document.getElementById('main')
const root = createRoot(container)

const RouterWrapper = () => {
  const { appState } = useAccountContext()
  // const appState = {
  //   authenticated: false
  // }

  return (
    <>
      {
        appState.authenticated
          ? <SystemAdminProvider>
            <NotificationProvider>
              <PartnerProvider>
                <ProfileProvider>
                  <RouterProvider router={router} />
                </ProfileProvider>
              </PartnerProvider>
            </NotificationProvider>
          </SystemAdminProvider>

          : <RouterProvider router={authRouter} />
      }
    </>
  )
}

root.render(
  <React.StrictMode>
    <AppProvider>
      <AccountProvider>
        <RouterWrapper />
      </AccountProvider>
    </AppProvider>
  </React.StrictMode>
)
